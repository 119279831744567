import BlankLayout from '@/views/layout/BlankLayout'

import Documentation from '@/views/documentation'

export default {
  name: 'Documentation',
  path: '/',
  component: BlankLayout,
  meta: {
    requiresAuth: false
  },
  children: [
    {
      path: '/documentation',
      name: 'Documentation',
      component: Documentation,
      meta: {
        title: 'Documentation'
      }
    }
  ]
}

export const INIT_AGENT_FORM = {
  name: null,
  isActive: true
}

export default {
  agents: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        items: [
          {
            text: 'Esperando Comando',
            value: '0'
          },
          {
            text: 'Enviar Arquivos',
            value: '10'
          }
        ]
      },
      {
        name: 'isActive',
        label: 'Esta Ativo?',
        type: 'select',
        items: [
          {
            text: 'Sim',
            value: '1'
          },
          {
            text: 'Não',
            value: '0'
          }
        ]
      }
    ],
    values: {}
  },
  // # TODO ALterar estrutura
  form: {
    saving: false,
    agentId: null,
    loading: false,
    agent: {
      type: null,
      resourceType: null,
      name: null,
      config: {}
    },
    errors: {
      agent: {}
    }
  },
  table: {
    loading: false,
    pagination: {}
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 0,
    hasNext: false
  },
  resources: [
    {
      name: 'Banco de Dados',
      key: 'database',
      types: [
        {
          key: 'mysql2',
          name: 'MySQL'
        },
        {
          key: 'postgres',
          name: 'Postgres'
        },
        {
          key: 'oracle',
          name: 'Oracle'
        }
      ]
    }
  ]
}

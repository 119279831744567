<template>
  <BeListCardItem leftDivColor="success">
    <template v-slot:leftColoredDiv>
      <v-btn
        v-if="!apiToken.token"
        icon
        @click="$emit('onTokenCopy', apiToken)"
      >
        <BeIcon
          icon="mdi-eye"
          tooltip="Copiar token"
          iconColor="white"
          iconSize="25"
        />
      </v-btn>
      <v-btn v-else icon>
        <BeIcon
          icon="mdi-eye-off"
          tooltip="Token já copiado"
          iconColor="white"
          iconSize="25"
        />
      </v-btn>
    </template>
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="6" class="ma-0 pa-0">
            <BeEllipsis :data="apiToken.name" />
            <strong>
              <BeEllipsis :data="apiToken.description" />
            </strong>
          </v-col>
          <v-col cols="12" md="6" class="ma-0 pa-0">
            <BeTooltip :tooltip="`Criado em ${formatDate(apiToken.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank </v-icon>
              <span class="font-weight-bold">
                {{ apiToken.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Atualizado em ${formatDate(apiToken.updatedAt)}`">
              <v-icon size="20"> mdi-calendar-text </v-icon>
              <span class="font-weight-bold">
                {{ apiToken.updatedAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`${apiToken.usedAt ? ('Utilizado pela última vez em ' + formatDate(apiToken.usedAt)) : 'Token nunca utilizado'}`">
              <v-icon size="20"> mdi-calendar-star </v-icon>
              <span class="font-weight-bold">
                {{ apiToken.usedAt ? formatDate(apiToken.usedAt) : '-' }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn icon color="primary" @click="$emit('onEdit', apiToken)">
            <BeIcon
              icon="mdi-pencil"
              tooltip="Editar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>

          <v-btn icon color="primary" @click="$emit('onDelete', apiToken)">
            <BeIcon
              icon="mdi-delete"
              tooltip="Deletar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>

          <v-btn icon color="primary" @click="$emit('onRevoke', apiToken)" v-if="apiToken.token">
            <BeIcon
              icon="mdi-refresh"
              tooltip="Revogar token"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    apiToken: {
      type: Object,
      required: true
    }
  }
}
</script>

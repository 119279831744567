const INITIAL_COUNTERS = {
  totalScans: 0,
  totalScanned: 0,
  totalSensitive: 0
}

const MOBILE_BREAKPOINT = 1264

export default {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  drawer: window.innerWidth >= MOBILE_BREAKPOINT,
  counters: { ...INITIAL_COUNTERS },
  loadingSystemInfo: false,
  systemInfo: '',
  categories: [],
  loadingCategories: true,
  isLoadingCounters: true,

  loadingCategoryCounts: false,
  categoryCounts: []
}

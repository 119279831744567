import SettingsProvider from '@/core/api/providers/settings'

import {
  ENV_SETTINGS_LOADING,
  SET_ENV_SETTINGS,
  SETTINGS_LOADING
} from './mutations'
import { DateManager } from '@/core/utils/dateManager'

export default {
  async loadEnv({ commit }) {
    commit(ENV_SETTINGS_LOADING, true)
    const data = await SettingsProvider.getEnv()
    commit(ENV_SETTINGS_LOADING, false)
    if (data) {
      commit(SET_ENV_SETTINGS, data)
    }
  },

  async setEnv({ commit }, data) {
    commit(ENV_SETTINGS_LOADING, true)
    await SettingsProvider.postEnv(data)
    commit(ENV_SETTINGS_LOADING, false)
    commit(SET_ENV_SETTINGS, data)
    return data
  },

  async restartServices({ commit }) {
    commit(SETTINGS_LOADING, true)
    await SettingsProvider.restartServices().finally(() => {
      commit(SETTINGS_LOADING, false)
    })
  },

  async getTZ() {
    if (!DateManager.DEFAULT_TZ_UPDATED) {
      const TZ = await SettingsProvider.getTZ()
      if (!TZ) return
      DateManager.DEFAULT_TZ = TZ
      DateManager.DEFAULT_TZ_UPDATED = true
    }
  }
}

<template>
  <BeListCardItem
    :leftDivColor="detector.isActive ? 'success' : 'grey darken-1'"
  >
    <template v-slot:leftColoredDiv>
      <BeIcon
        icon="mdi-regex"
        iconColor="white"
        iconSize="30"
        tooltip="Regex"
      />
    </template>
    <template v-slot:content>
      <v-col class="ma-0 pa-0" cols="10">
        <v-row align="center" class="ma-0 pa-0" no-gutters>
          <v-col class="ma-0 pa-0" cols="12" md="5">
            <BeEllipsis :data="detector.name" />
            <h4>
              <BeEllipsis :data="detector.regexPattern" />
            </h4>
          </v-col>
          <v-col cols="12" md="4">
            <BeIcon
              :icon="`$vuetify.icons.${
                detector.category ? detector.category.slug : ''
              }`"
              :tooltip="detector.category ? detector.category.name : ''"
              iconColor="indigo"
              iconSize="30"
            />
          </v-col>
          <v-col class="ma-0 pa-0" cols="12" md="3">
            <BeTooltip :tooltip="`Criado em ${formatDate(detector.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank</v-icon>
              <span class="font-weight-bold">
                {{ detector.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip
              :tooltip="`Atualizado em ${formatDate(detector.updatedAt)}`"
            >
              <v-icon size="20"> mdi-calendar-text</v-icon>
              <span class="font-weight-bold">
                {{ detector.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0" cols="2">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn
            :disabled="detector.isReadOnly"
            color="primary"
            icon
            @click="$emit('onEdit', detector)"
          >
            <BeIcon
              icon="mdi-pencil"
              iconColor="primary"
              iconSize="25"
              tooltip="Editar"
            />
          </v-btn>

          <v-btn
            :disabled="detector.isReadOnly"
            color="primary"
            icon
            @click="$emit('onDelete', detector)"
          >
            <BeIcon
              icon="mdi-delete"
              iconColor="primary"
              iconSize="25"
              tooltip="Deletar"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    detector: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div class="d-flex flex-direction-row align-center">
    <v-card> </v-card>
    <CardCount
      :value="totalSensitive"
      icon="mdi-eye"
      tooltip="Total encontrado"
    />

    <div class="w-100">
      <component
        class="info-wrapper"
        :is="chipsComponent"
        :scanHistory="scanHistory"
        :aggregate="aggregate()"
        :extraTotals="extraTotals()"
      />

      <v-progress-linear
        :value="power()"
        class="rounded w-max-50"
        color="primary"
        height="15"
      >
        <template v-slot:default="{}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <strong
                style="color: white; font-size: 12px"
                v-bind="attrs"
                v-on="on"
              >
              {{ totalProcessed }}
              /
              {{ totalDiscovered }}
              </strong>
            </template>
            <span>Total processado / Total encontrado</span> <br />
            <span>
              {{ totalProcessed }}
              /
              {{ totalDiscovered }}
            </span>
          </v-tooltip>
        </template>
      </v-progress-linear>
      <!-- <div v-else>
        <strong>
          <BeEllipsis
            :data="totalDiscovered + ' / ' + totalProcessed"
            tooltip="Total descoberto / Total processado"
          />
        </strong>
      </div> -->
    </div>
  </div>
</template>
<script>
import BeEllipsis from '@/components/Ellipsis'
import Doughnut from '@/components/Charts/Doughnut'
import ChipCount from '@/components/ChipCount'
import CardCount from '@/components/CardCount'

import scanTypes from '@/core/api/enums/scanTypes'

const AgentChipsCount = () => import('./AgentChipsCount')
const DatabaseChipsCount = () => import('./DatabaseChipsCount')

export default {
  name: 'ScanHistoryCount',

  components: {
    BeEllipsis,
    Doughnut,
    ChipCount,
    CardCount
  },

  props: {
    scanType: {
      type: String,
      required: true
    },
    scanHistory: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  computed: {
    totalDiscovered() {
      return this.scanHistory &&
        this.scanHistory.aggregateScanHistoryResults &&
        this.scanHistory.aggregateScanHistoryResults.totalDiscovered
        ? this.scanHistory &&
            this.scanHistory.aggregateScanHistoryResults &&
            this.scanHistory.aggregateScanHistoryResults.totalDiscovered
        : 0
    },
    totalProcessed() {
      return this.scanHistory &&
        this.scanHistory.aggregateScanHistoryResults &&
        this.scanHistory.aggregateScanHistoryResults.totalProcessed
        ? this.scanHistory &&
            this.scanHistory.aggregateScanHistoryResults &&
            this.scanHistory.aggregateScanHistoryResults.totalProcessed
        : 0
    },
    totalSensitive() {
      return this.scanHistory &&
        this.scanHistory.aggregateScanHistoryResults &&
        this.scanHistory.aggregateScanHistoryResults.totalSensitive
        ? this.scanHistory &&
            this.scanHistory.aggregateScanHistoryResults &&
            this.scanHistory.aggregateScanHistoryResults.totalSensitive
        : 0
    },

    chipsComponent() {
      switch (this.scanType) {
        case scanTypes.AGENT:
          return AgentChipsCount
        case scanTypes.DATABASE:
          return DatabaseChipsCount
        case scanTypes.CLOUD_STORAGE:
          return AgentChipsCount
        default:
          throw new Error('Componente não encontrado')
      }
    }
  },
  methods: {
    aggregate() {
      const baseResponse = {
        totalDiscovered: 0,
        totalErrored: 0,
        totalProcessed: 0,
        totalSensitive: 0,
        totalTables: 0
      }
      if (!this.scanHistory) return baseResponse

      const { aggregateScanHistoryResults } = this.scanHistory

      if (aggregateScanHistoryResults) {
        const {
          totalDiscovered,
          totalErrored,
          totalProcessed,
          totalSensitive,
          totalTables
        } = aggregateScanHistoryResults

        return {
          totalDiscovered,
          totalErrored,
          totalProcessed,
          totalSensitive,
          totalTables
        }
      }

      return baseResponse
    },

    extraTotals() {
      const baseResponse = {
        totalLines: 0,
        totalColumns: 0,
        totalTables: 0,
        totalFiles: 0
      }

      if (this.scanHistory && this.scanHistory.aggregateScanHistoryResults) {
        let { aggregateScanHistoryResults = {} } = this.scanHistory

        let { extraTotals } = aggregateScanHistoryResults

        return extraTotals
      }

      return baseResponse
    },

    power() {
      if (!this.totalProcessed || !this.totalDiscovered) return 0

      const progress = (this.totalProcessed / this.totalDiscovered) * 100

      if (progress >= 100) return 100
      if (progress <= 0) return 0

      return progress.toFixed(2)
    }
  }
}
</script>
<style scoped>
.info-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
  height: auto;
}

.w-max-50 {
  max-width: 70%;
}
</style>

<template>
  <BeListCardItem leftDivColor="success">
    <template v-slot:leftColoredDiv>
      <div class="d-flex flex-column" style="gap: 5px">
        <BeIcon
          :icon="resourceTypeIcon"
          :tooltip="resourceTypeDescription"
          iconColor="white"
          iconSize="30"
        />
      </div>
    </template>
    <template v-slot:content>
      <v-col class="ma-0 pa-0" cols="10">
        <v-row align="center" class="ma-0 pa-0" no-gutters>
          <v-col class="ma-0 pa-0" cols="12" md="3">
            <BeEllipsis :data="scan.name" />
          </v-col>
          <v-col cols="12" md="2">
            <ScanStatus
              :status="scanHistory.status"
              :statusDescription="scanHistory.statusDescription"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ScanHistoryCount
              :scanHistory="scanHistory"
              :scanType="scan.type"
            />
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeTooltip
              v-if="scanHistory.finishedAt"
              :tooltip="`Finalizado em ${formatDate(scanHistory.finishedAt)}`"
            >
              <v-icon color="primary" size="20"> mdi-calendar-check </v-icon>

              <span class="font-weight-bold primary--text">
                {{ scanHistory.finishedAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip v-else tooltip="Ainda não finalizado">
              <v-icon color="primary" size="20"> mdi-calendar-check </v-icon>
              <span class="font-weight-bold"> Ainda não finalizado </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Disparado em ${scanHistory.createdAt}`">
              <v-icon size="20"> mdi-calendar-clock </v-icon>
              <span class="font-weight-bold">
                {{ scanHistory.createdAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <v-btn
          icon
          color="primary"
          @click="$emit('onForceFinish', scanHistory.scanId)"
          :disabled="
            !(scanHistory.status >= 10 && scanHistory.status < 30) ||
            scanHistory.isForcingFinish
          "
        >
          <BeIcon
            icon="mdi-checkbox-blank"
            tooltip="Forçar finalização"
            iconColor="primary"
            iconSize="25"
          />
        </v-btn>

        <v-btn
          icon
          color="primary"
          @click="$emit('onResultsCheck', scanHistory)"
        >
          <BeIcon
            icon="mdi-card-search"
            tooltip="Ver resultados"
            iconColor="primary"
            iconSize="25"
          />
        </v-btn>

        <v-btn
          icon
          color="primary"
          @click="$emit('onDelete', scanHistory)"
          :disabled="scanHistory.status < 30"
        >
          <BeIcon
            icon="mdi-delete"
            tooltip="Deletar"
            iconColor="primary"
            iconSize="25"
          />
        </v-btn>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'
import ScanHistoryCount from '@/components/ScanHistoryCount'

import ScanStatus from './ScanStatus'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    ScanStatus,
    BeTooltip,
    BeIcon,
    ScanHistoryCount
  },

  props: {
    scanHistory: {
      type: Object,
      required: true
    }
  },

  computed: {
    scan() {
      return this.scanHistory.scan
    },
    power() {
      let { aggregateScanHistoryResults } = this.scanHistory

      if (!aggregateScanHistoryResults) {
        aggregateScanHistoryResults = { totalDiscovered: 0, totalProcessed: 0 }
      }

      const { totalDiscovered, totalProcessed } = aggregateScanHistoryResults

      const result = (totalProcessed / totalDiscovered) * 100

      return isNaN(result) ? 0 : result
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Formatos Encontrados'
          }
        }
      }
    },
    chartData() {
      if (this.scanHistory) {
        const { aggregateScanHistoryResults } = this.scanHistory
        let extraTotals = aggregateScanHistoryResults?.extraTotals
        if (
          this.scanHistory.cachedCounts &&
          Object.keys(this.scanHistory.cachedCounts).length > 0
        )
          extraTotals = this.scanHistory.cachedCounts

        let labels = []

        let data = []

        if (extraTotals) {
          const { totalFiles, ...filesCount } = extraTotals

          for (const label in filesCount) {
            labels.push(label.toLowerCase().replace('count', '').toUpperCase())
            data.push(filesCount[label])
          }

          return {
            totalFiles,
            labels,

            datasets: [
              {
                backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
                data
              }
            ]
          }
        }
      }

      return {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: []
          }
        ]
      }
    },
    aggregate() {
      const baseResponse = {
        totalDiscovered: 0,
        totalErrored: 0,
        totalProcessed: 0,
        totalSensitive: 0,
        totalTables: 0
      }
      if (!this.scanHistory) return baseResponse

      const { aggregateScanHistoryResults } = this.scanHistory

      if (aggregateScanHistoryResults) {
        const {
          totalDiscovered,
          totalErrored,
          totalProcessed,
          totalSensitive,
          totalTables
        } = aggregateScanHistoryResults

        return {
          totalDiscovered,
          totalErrored,
          totalProcessed,
          totalSensitive,
          totalTables
        }
      }

      return baseResponse
    },

    extraTotals() {
      const baseResponse = {
        totalLines: 0,
        totalColumns: 0,
        totalTables: 0,
        totalFiles: 0
      }
      if (this.scanHistory) {
        let { aggregateScanHistoryResults } = this.scanHistory

        if (
          this.scanHistory.cachedCounts &&
          Object.keys(this.scanHistory.cachedCounts).length > 0
        )
          aggregateScanHistoryResults = this.scan.cachedCounts

        let { extraTotals } = aggregateScanHistoryResults || {}
        if (
          this.scanHistory.cachedCounts &&
          Object.keys(this.scanHistory.cachedCounts).length > 0
        )
          extraTotals = this.scanHistory.cachedCounts

        if (extraTotals && Object.keys(extraTotals).length > 0)
          return extraTotals
      }

      return baseResponse
    },

    resourceTypeIcon() {
      if (this.scanHistory.scan.type === 'agent') return `$vuetify.icons.agent`

      return `$vuetify.icons.${this.scanHistory.scan.resourceType}`
    },

    resourceTypeDescription() {
      return `${this.scan.typeDescription} / ${this.scan.resourceTypeDescription}`
    },

    scanTotals() {
      if (!this.scanHistory) return `${0} / ${0} / ${0} / ${0}`

      const { aggregateScanHistoryResults } = this.scanHistory

      if (!aggregateScanHistoryResults) return `${0} / ${0} / ${0} / ${0}`

      const { totalDiscovered, totalErrored, totalProcessed, totalSensitive } =
        aggregateScanHistoryResults

      return `${totalDiscovered} / ${totalProcessed} / ${totalSensitive} / ${totalErrored}`
    }
  }
}
</script>
<style scoped>
.info-wrapper {
  display: block;
  flex-wrap: wrap;
  overflow: hidden;
  height: 30px;
}

.w-max-50 {
  max-width: 70%;
}
</style>

import APIClient from '@/core/http/apiClient'

export class LogProvider {
  constructor(httpClient) {
    this.basePath = '/private/logs'
    this.httpClient = httpClient
  }

  async download() {
    return await this.httpClient.call(
      `${this.basePath}/download`,
      {},
      'get',
      {},
      {
        responseType: 'blob'
      }
    )
  }
}

export default new LogProvider(new APIClient())

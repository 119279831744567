import Vue from 'vue'

import { DateManager } from '@/core/utils/dateManager'

import cronstrue from 'cronstrue/i18n'

Vue.filter('formatDate', (val) => DateManager.format(val))

Vue.filter('yesOrNo', (val) => (val == true ? 'Sim' : 'Não'))

Vue.filter('parseCron', (val) => {
  try {
    return cronstrue.toString(val, { locale: 'pt_BR' })
  } catch {
    return ''
  }
})

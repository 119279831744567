import './fonts'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueEllipsis from '@/plugins/ellipsis'
import router from './router/index'
import store from './store'
import './filters'
import { DateManager } from './core/utils/dateManager'

Vue.config.productionTip = false

Vue.use(VueEllipsis)

Vue.mixin({
  methods: {
    formatDate: (val) => DateManager.format(val),
  }
})

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')

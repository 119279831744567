<template>
  <v-card flat>
    <v-card-text>
      <div v-if="loading">
        <v-row v-for="n in 5" :key="n">
          <v-col cols="12">
            <v-skeleton-loader type="text" />
          </v-col>
        </v-row>
      </div>

      <v-row no-gutters v-if="!loading">
        <v-col
          cols="12"
          md="4"
          sm="4"
          v-for="key in Object.keys(env)"
          v-bind:key="key"
        >
          <v-text-field
            :key="key"
            :label="key"
            :value="env[key]"
            @input="env[key] = $event"
            :rules="rules"
            outlined
            dense
            :disabled="loading"
            class="mr-2"
          />
        </v-col>
      </v-row>

      <v-btn color="primary" class="mr-4" :disabled="loading" @click="saveEnv">
        Salvar
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import validations from '@/core/utils/validations'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('settingsModule', ['env', 'loading']),
    rules() {
      return [(v) => validations.required(v)]
    }
  },
  methods: {
    ...mapActions('settingsModule', ['loadEnv', 'setEnv']),
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    async loadEnv_() {
      let message = {
        type: 'success',
        message: 'Lista de variáveis de ambiente obtida com sucesso!'
      }

      await this.loadEnv().catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao obter lista de variáveis de ambiente!'
        }
      })

      this.showSnackbar(message)
    },

    async saveEnv() {
      let message = {
        type: 'success',
        message: 'Variáveis de ambiente salvas com sucesso!'
      }

      await this.setEnv(this.env).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao salvar variáveis de ambiente!'
        }
      })

      this.showSnackbar(message)
    }
  },

  created() {
    this.loadEnv_()
  }
}
</script>

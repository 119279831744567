<template>
  <v-card flat text color="#dbdbdb" class="mb-5">
    <v-card-text class="d-block">
      <div class="d-flex justify-center flex-column align-center mb-3">
        <v-icon class="mr-1 mb-4" size="50" color="primary"
          >mdi-filter-settings-outline</v-icon
        >
        <span class="text-h6">Nenhum registro encontrado</span>

        <p class="mb-0">Se você está fazendo uma busca, ajuste os filtros.</p>
        <p v-if="!hideActions">De outra forma, adicione um novo registro!</p>
      </div>

      <div v-if="!hideActions" class="d-flex justify-center">
        <v-btn outlined color="primary" @click="handleClick">Adicionar</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'EmptyList',

  props: {
    onClick: {
      default: undefined
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      if (this.onClick) this.onClick({})
    }
  }
}
</script>

<template>
  <v-card
    v-if="totalItems > 0"
    class="ma-0 pa-0 align-self-center"
    style="background-color: #17a2b8"
  >
    <div class="total">
      <span>
        Mostrando {{ pagination.itemCount }} de
        {{ pagination.totalItems }} itens
      </span>
    </div>
    <v-pagination
      class="py-3"
      circle
      :length="pagination.totalPages"
      :total-visible="11"
      v-model="page"
      color="primary"
    />
  </v-card>
</template>
<script>
export default {
  name: 'BePaginator',
  props: {
    value: {
      type: Number,
      default: 1
    },
    pagination: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    if (this.$route.query.page) {
      this.$emit('model', +this.$route.query.page)
    }
  },

  computed: {
    totalItems() {
      const { totalItems } = this.pagination

      if (totalItems) return totalItems
      return 0
    },
    page: {
      get() {
        return this.value
      },
      set(value) {
        this.$router.push({ query: { ...this.$route.query, page: value } })
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style>
.total {
  color: white;
  position: absolute;
  height: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
</style>

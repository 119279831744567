import { postgresSvg } from './custom/postgres'
import { mariadbSvg } from './custom/mariadb'
import { mongodbSvg } from './custom/mongodb'
import { oracleSvg } from './custom/oracle'
import { mysqlSvg } from './custom/mysql'
import { mssqlSvg } from './custom/mssql'
import { googleDriveSvg } from './custom/google_drive'
import { cloudSvg } from './custom/cloud'
import { cloudflare_r2Svg } from './custom/cloudflare_r2'
import alibaba_SVG from './custom/alibaba_object_storage_service.vue'
import wasabi_SVG from './custom/wasabi_object_storage.vue'
import ibm_SVG from './custom/ibm_cloud_object_storage.vue'
import linode_SVG from './custom/linode_object_storage.vue'
import vultr_SVG from './custom/vultr_object_storage.vue'
import amazon_SVG from './custom/amazon_logo.vue'

export default {
  iconfont: 'mdi',
  values: {
    database: 'mdi-database',
    agent: 'mdi-desktop-classic',
    postgres: postgresSvg,
    oracle: oracleSvg,
    mysql2: mysqlSvg,
    mysql: mysqlSvg,
    mariadb: mariadbSvg,
    mongodb: mongodbSvg,
    mssql: mssqlSvg,
    google_drive: googleDriveSvg,
    s3: {
      component: amazon_SVG,
    },
    cloud: cloudSvg,

    alibaba_object_storage_service: {
      component: alibaba_SVG,
    },
    azure_blob_storage: 'mdi-microsoft-azure',
    cloudflare_r2: cloudflare_r2Svg,
    digital_ocean_spaces: 'mdi-digital-ocean',
    ibm_cloud_object_storage: {
      component: ibm_SVG,
    },
    linode_object_storage: {
      component: linode_SVG,
    },
    vultr_object_storage: {
      component: vultr_SVG,
    },
    wasabi_object_storage: {
      component: wasabi_SVG,
    },
    one_drive: 'mdi-microsoft-onedrive',


    sensitive: 'mdi-fingerprint',
    registration: 'mdi-clipboard-account',
    financial: 'mdi-bank',
    behavioral: 'mdi-bike',
    others: 'mdi-dots-horizontal-circle'
  }
}

import store from '@/store'

export default {
  scansHistories: [],
  filters: {
    fields: [
      {
        name: 'scanName',
        label: 'Nome do Scan',
        type: 'text'
      },
      {
        name: 'type',
        label: 'Tipo do Recurso',
        type: 'select',
        onChange: (value) => {
          store.commit('homeModule/APPEND_RESOURCE_TYPE', value)
        },
        items: [
          {
            text: 'Banco de Dados',
            value: 'database'
          },
          {
            text: 'Agente Remoto',
            value: 'agent'
          }
        ]
      }
    ],
    values: {}
  },
  table: {
    loading: false,
    refreshing: false
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 0,
    hasNext: false
  }
}

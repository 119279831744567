import Dialog from '@/views/templates/Dialog'
import Snackbar from '@/views/templates/Snackbar'

export default [
  {
    name: 'DialogTemplate',
    path: '/templates/dialogs',
    component: Dialog,
    meta: {
      title: 'Dialogs'
    }
  },
  {
    name: 'SnackbarTemplate',
    path: '/templates/snackbars',
    component: Snackbar,
    meta: {
      title: 'Snackbars'
    }
  }
]

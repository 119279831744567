<template>
  <div class="w-100 mw-600">
    <v-form ref="globalSearchForm" @submit.prevent="filterContent">
      <v-autocomplete
          :clearable="false"
          :items="items"
          :search-input.sync="search"
          :value="search"
          append-icon="mdi-magnify"
          class="no-rotate"
          dense
          hide-details
          hide-selected
          label="Busca global"
          placeholder="Busca global"
          solo-inverted
          style="max-height: 35px"
      >
        <template #no-data>
          <v-list
              dense
              rounded
              style="max-height: 40px; padding: 0; border-radius: 16px"
          >
            <v-list-item dense>
              <v-list-item-title>
                Digite um valor, e então pressione
                <kbd>Enter</kbd>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {SET_FILTERS} from '@/store/modules/filter/mutations'
import validations from '@/core/utils/validations'

export default {
  name: 'GlobalSearch',
  data() {
    return {
      search: null
    }
  },
  created() {
    this.search = this.$route.query.value
  },
  computed: {
    ...mapGetters({
      filters: 'filterModule/filters'
    }),
    items() {
      return [this.search]
    },
    rules() {
      return {
        search: [validations.required]
      }
    }
  },
  methods: {
    ...mapActions({
      filter: 'scannedDataModule/filter'
    }),
    ...mapMutations('filterModule', [SET_FILTERS]),
    clearSearch() {
      this.search = null
      this.$router.push({name: 'GlobalScannedData'})
      this.filterContent()
    },
    filterContent() {
      if (![null, undefined, ''].includes(this.search.trim())) {
        if (this.$route.name == 'GlobalScannedData') {
          const query = new Object()
          if (this.search) {
            Object.assign(query, {value: this.search})
            this.$router.push({query: {value: this.search}})
          }

          this.filter(query)
        } else {
          this.$router.push({
            name: 'GlobalScannedData',
            query: {value: this.search}
          })
        }

        this[SET_FILTERS]({...this.filters, value: this.search})
      }
    }
  },
  watch: {
    filters: {
      handler(val) {
        const {value} = val
        this.search = value
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.no-rotate ::v-deep .v-input__icon--append .v-icon {
  transform: none !important;
}
</style>

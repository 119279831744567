<template src="./ScanConfig.html"></template>
<script>
const DatabaseConfig = () =>
  import('@/views/scans/config/database/Database.vue')
const StorageConfig = () => import('@/views/scans/config/storage/Storage.vue')
const AgentConfig = () => import('@/views/scans/config/agent/Agent.vue')

export default {
  name: 'ScanConfig',
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    resourceOrigin: {
      type: Number,
      default: 0
    },
    resourceTarget: {
      type: Number,
      default: 0
    },
    errors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      model: this.config
    }
  },

  updated() {
    this.model = this.config
  },

  computed: {
    configComponent() {
      if (this.resourceOrigin == 0) {
        return DatabaseConfig
      } else if (this.resourceOrigin == 1) {
        return AgentConfig
      } else {
        return StorageConfig
      }
    }
  },

  watch: {
    model: {
      handler(value) {
        this.$emit('change', value)
      },
      deep: true
    },
    config() {
      this.model = this.config
    }
  }
}
</script>

<template>
  <v-form ref="detectorForm">
    <v-row>
      <v-col cols="12" md="9">
        <BeTextField
            v-model="detector.name"
            :disabled="loading"
            :error-messages="errors.name"
            :rules="[rules.required]"
            filled
            label="Nome *"
            outlined
        />
      </v-col>

      <v-col class="d-flex align-center">
        <BeCheckbox
            v-model="detector.isActive"
            :disabled="loading"
            :error-messages="errors.isActive"
            :loading="loading"
            label="Esta ativo?"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <RegexInput
            :errorMessages="errors.regexPattern"
            :regex="{
            pattern: detector.regexPattern,
            flags: detector.regexFlags
          }"
            @change="handleRegexChange"
        />
      </v-col>
    </v-row>

    <v-row v-if="categories.length > 0">
      <v-col>
        <fieldset>
          <legend>Categorias</legend>
          <v-radio-group v-model="detector.categoryId"
                         :rules="[rules.required]" class="ma-0 pa-0">
            <v-row>

              <v-col
                  v-for="category in categories"
                  :key="category.id"
                  cols="12"
                  sm="4"
              >

                <BeRadioButton
                    :label="category.name"
                    :value="category.id"
                    style="margin-top: 0"
                />

              </v-col>
            </v-row>

          </v-radio-group>
        </fieldset>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import BeTextField from '@/components/TextField'
import BeCheckbox from '@/components/Checkbox'
import BeRadioButton from '@/components/RadioButton'
import RegexInput from './RegexInput'

import {
  SET_CURRENT_ERRORS,
  SET_CURRENT_MODEL,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_PAGINATION
} from '@/store/modules/detectors/mutations'

import {INIT_DETECTOR_FORM} from '@/store/modules/detectors/state'

import validations from '@/core/utils/validations'

export default {
  name: 'DetectorForm',

  components: {
    BeTextField,
    BeCheckbox,
    RegexInput,
    BeRadioButton
  },
  data() {
    return {
      detector: {...INIT_DETECTOR_FORM},
      categories: []
    }
  },

  computed: {
    ...mapGetters('detectorModule', [
      'detectors',
      'filters',
      'table',
      'loading',
      'pagination',
      'readyToUpdate',
      'errors'
    ]),

    rules() {
      return validations
    },

    dialogTitle() {
      if (this.readyToUpdate) {
        return 'Editar Detector'
      }

      return 'Novo Detector'
    }
  },

  methods: {
    ...mapActions({
      create: 'detectorModule/create',
      update: 'detectorModule/update',
      find: 'detectorModule/find',
      showSnackbar: 'snackbarModule/showSnackbar',
      getCategories: 'sensitiveCategoryModule/getAll'
    }),
    ...mapMutations('detectorModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    ]),

    handleRegexChange(regex) {
      this.detector.regexFlags = regex.flags
      this.detector.regexPattern = regex.pattern
    },

    async prepareToCreate() {
      this.$refs.detectorForm.reset()
      this.detector = {...INIT_DETECTOR_FORM}
      this.categories = await this.getCategories()
      this[SET_CURRENT_ERRORS]([])
      this[SET_CURRENT_MODEL](null)
    },

    async attemptSave() {
      if (this.$refs.detectorForm.validate()) {
        this[SET_MODEL](this.detector)
        if (this.readyToUpdate) await this.update()
        else await this.create()

        this[SET_CURRENT_ERRORS]([])
      } else {
        throw Error()
      }
    },

    async findDetector(detector) {
      try {
        const {detectorId} = detector
        this[SET_CURRENT_MODEL](detectorId)
        this.detector = await this.find(detectorId)
        this.categories = await this.getCategories()
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar o detector.'
        })
      }
    }
  }
}
</script>

<template>
  <BeListCardHeader showRefreshingBar :refreshing="refreshing">
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="3">
            <h2>
              <BeEllipsis data="Nome do scan" />
            </h2>
            <h3>
              <BeEllipsis data="Recurso" />
            </h3>
          </v-col>
          <v-col cols="12" md="2">
            <h2>
              <BeEllipsis data="Status" />
            </h2>
          </v-col>

          <v-col cols="12" md="4">
            <h2>
              <BeEllipsis data="Resultado" />
            </h2>

          </v-col>

          <v-col cols="12" md="3">
            <h2>
              <BeEllipsis data="Finalizado em" />
            </h2>
            <h3>
              <BeEllipsis data="Disparado em" />
            </h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <h2>
          <BeEllipsis data="Ações" />
        </h2>
      </v-col>
    </template>
  </BeListCardHeader>
</template>
<script>
import BeListCardHeader from '@/components/BeListCardHeader'
import BeEllipsis from '@/components/Ellipsis'

export default {
  components: {
    BeListCardHeader,
    BeEllipsis
  },

  props: {
    refreshing: {
      type: Boolean,
      default: false
    }
  }
}
</script>

export const LOADING_ENV_SETTINGS = 'LOADING_ENV_SETTINGS'
export const SET_ENV_SETTINGS = 'SET_ENV_SETTINGS'
export const ENV_SETTINGS_LOADING = 'ENV_SETTINGS_LOADING'
export const SETTINGS_LOADING = 'SETTINGS_LOADING'

export default {
  [SET_ENV_SETTINGS](state, env) {
    state.env.data = env
  },

  [ENV_SETTINGS_LOADING](state, loading = false) {
    state.env.loading = loading
  },

  [SETTINGS_LOADING](state, loading = false) {
    state.loading = loading
  }
}

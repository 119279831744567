import apiConfig from '@/core/config/api.config'
import HttpClient from '@/core/http/httpClient'
import session from '@/core/utils/session'

import router from '@/router'

import store from '@/store'

export default class APIClient extends HttpClient {
  constructor(throwError = false) {
    super(apiConfig.baseUrl)
    this.throwError = throwError
  }

  async call(url, params = {}, method = 'GET', headers = {}, options = {}) {
    const user = session.get('user')

    if (user && headers.Authorization == undefined) {
      Object.assign(headers, {
        Authorization: `Bearer ${user.accessToken}`
      })
    }

    method = method.toLowerCase()

    let callback = null

    if (method == 'post') {
      callback = this.post(url, params, {
        headers
      })
    } else if (method == 'put') {
      callback = this.put(url, params, {
        headers
      })
    } else if (method == 'patch') {
      callback = this.patch(url, params, {
        headers
      })
    } else if (method == 'delete') {
      callback = this.delete(url, params, { headers })
    } else {
      callback = this.get(url, params, { headers, ...options })
    }

    try {
      let res = await callback
      return this._shortResponse(res)
    } catch (err) {
      if (this.throwError) throw err

      if (+err.response.status === 401) {
        try {
          await store.dispatch('authModule/refreshSession')
          return await this.call(url, params, method, {}, options)
        } catch {
          session.destroy()
          return await router.push({ name: 'Login' })
        }
      }

      if (err && err.response && +err.response.status === 500) {
        const { data } = err.response
        store.dispatch(
          'snackbarModule/showSnackbar',
          {
            type: 'danger',
            message: data.message
              ? data.message
              : 'Erro ao comunicar com o servidor'
          },
          { root: true }
        )
      }

      throw this._shortResponse(err)
    }
  }

  _shortResponse(res) {
    return res.response ? res.response.data : res.data
  }
}

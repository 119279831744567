import ReportIndex from '@/views/reports/Index'

export default [
  {
    name: 'ReportIndex',
    path: '/reports',
    component: ReportIndex,
    meta: {
      title: 'Relatórios',
      breadCrumb: [
        {
          to: { name: 'ReportIndex' },
          text: 'Relatórios'
        }
      ]
    }
  }
]

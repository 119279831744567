<template>
  <v-dialog width="600" v-model="model">
    <v-card>
      <v-toolbar color="primary" dark elevation="0">
        <v-toolbar-title class="text--white">{{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="model = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="panel pt-5">
        <APITokenForm v-if="type == 'form'" ref="APITokenForm" />
        <APITokenFormToken v-else ref="APITokenFormToken" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="flex">
        <v-spacer></v-spacer>
        <v-btn width="100" @click="model = false">Fechar</v-btn>

        <v-btn
          v-if="this.type != 'token'"
          color="primary"
          :disabled="loading"
          :loading="loading"
          width="100"
          @click="attemptSave"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import APITokenForm from './APITokenForm.vue'
import APITokenFormToken from './APITokenFormToken.vue'
import Vue from 'vue'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_CURRENT_MODEL
} from '@/store/modules/detectors/mutations'
import validations from '@/core/utils/validations'

export default {
  name: 'APITokenDialog',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    APITokenForm,
    APITokenFormToken
  },

  computed: {
    ...mapGetters('apiTokenModule', [
      'loading',
      'readyToUpdate',
      'errors',
      'detector'
    ]),

    rules() {
      return {
        name: [(v) => validations.required(v)]
      }
    },

    model: {
      get() {
        const { show } = this.value

        if (show) return show
        return false
      },
      set(value) {
        const options = { ...this.value }
        options.show = value
        this.$emit('input', options)
      }
    },

    type() {
      return this.value.type
    },

    dialogTitle() {
      if (this.type == 'token') return 'Token de Acesso'

      if (this.readyToUpdate) {
        return 'Editar API Token'
      }

      return 'Novo API Token'
    }
  },

  methods: {
    ...mapActions({
      create: 'apiTokenModule/create',
      update: 'apiTokenModule/update',
      find: 'apiTokenModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('apiTokenModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL
    ]),

    prepareToCreate() {
      this[SET_CURRENT_MODEL](null)
    },

    async attemptSave() {
      const hasSaved = await this.$refs.APITokenForm.attemptSave()
      if (hasSaved) this.model = false
    }
  },

  watch: {
    value: {
      handler(value) {
        Vue.nextTick(() => {
          const { apiTokenId, show } = value
          if (!show) {
            return
          }
          if (this.type == 'form') {
            if (apiTokenId) {
              this.$refs.APITokenForm.findToken(value)
            } else {
              this.$refs.APITokenForm.prepareToCreate()
            }
          } else {
            this.$refs.APITokenFormToken.showToken(value)
          }
        })
      },
      deep: true
    }
  }
}
</script>

<template src="./Login.html"></template>
<script>
import logo from '../../../public/logo.png'

import BeTextField from '@/components/TextField'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginView',

  components: {
    BeTextField
  },

  data() {
    return {
      logo,
      user: {
        username: null,
        password: null
      },
      loading: false
    }
  },

  created() {
    if (this.userData) this.$router.push({ name: 'Home' })
  },

  computed: {
    rules() {
      return {
        username: [(v) => !!v || 'Campo requerido.'],
        password: [(v) => !!v || 'Campo requerido.']
      }
    },
    ...mapGetters({
      userData: 'authModule/getUser'
    })
  },

  methods: {
    ...mapActions({
      login: 'authModule/login',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    async attemptLogin() {
      if (this.$refs.login.validate()) {
        this.loading = true

        try {
          await this.login(this.user)

          this.$router.push({ name: 'Home' })

          this.showSnackbar({
            type: 'success',
            message: 'Login realizado com sucesso!'
          })
        } catch {
          this.showSnackbar({
            type: 'error',
            message: 'Credenciais inválidas'
          })
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

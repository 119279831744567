import APIClient from '@/core/http/apiClient'
import session from '@/core/utils/session'

class AuthProvider {
  constructor(httpClient) {
    this.basePath = '/auth'
    this.httpClient = httpClient
  }

  async login(data) {
    return await this.httpClient.call(`${this.basePath}/login`, data, 'post')
  }

  async logout() {
    return await this.httpClient.call(`${this.basePath}/logout`, {}, 'post')
  }

  async user() {
    return await this.httpClient.call(`${this.basePath}/user`, {}, 'get')
  }

  async refreshSession() {
    const user = session.get('user')

    return await this.httpClient.call(`${this.basePath}/refresh`, {}, 'get', {
      Authorization: `Bearer ${user.refreshToken}`
    })
  }
}

export default new AuthProvider(new APIClient(true))

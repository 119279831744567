import scanProvider from '@/core/api/providers/scan'

import {
  SET_CURRENT_ERRORS,
  SET_LIST_ITEMS,
  SET_PAGINATION,
  SET_SCAN,
  SET_TABLE_LOADING,
  SET_TABLE_REFRESH
} from '@/store/modules/scans/mutations'

export default {
  async filter({ commit, state }, { isRefresh } = { isRefresh: false }) {
    commit(SET_TABLE_LOADING, !isRefresh)
    commit(SET_TABLE_REFRESH, isRefresh)

    const data = await scanProvider.filter({
      ...state.filters.values
    })

    if (data) {
      commit(SET_LIST_ITEMS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
      commit(SET_TABLE_REFRESH, false)
    }
  },

  async delete({ commit }, scan) {
    commit(SET_TABLE_LOADING, true)

    const res = await scanProvider.delete(scan)

    if (res) {
      commit(SET_TABLE_LOADING, false)
    }
  },

  async start({ commit }, scan) {
    try {
      commit(SET_TABLE_LOADING, true)
      await scanProvider.start(scan)
    } finally {
      commit(SET_TABLE_LOADING, false)
    }
  },

  async find({ commit }, id) {
    // commit("LOADING_SCAN", true);
    const res = await scanProvider.find(id)

    if (res) {
      const data = res
      commit(SET_SCAN, data)
      // commit("LOADING_SCAN", false);
      return data
    }
  },

  async findByOption(_, name) {
    const data = await scanProvider.findByOption({ name })
    if (data) {
      return data
    }
  },

  async testConnection({ dispatch }, id) {
    const res = await scanProvider.testConnection(id)

    if (res) {
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Sua conexão está configurada corretamente..'
        },
        { root: true }
      )
      dispatch('filter')
    } else {
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'error',
          message:
            'Não conseguimos realizar a conexão com o recurso, verifique as configurações ou tente novamente mais tarde'
        },
        { root: true }
      )
    }
  },

  async forceFinish({ dispatch }, id) {
    await scanProvider.forceFinish(id)

    dispatch('filter')
  },

  async update({ state, commit, dispatch }) {
    // commit("SET_FORM_DISABLED", true);

    try {
      const res = await scanProvider.update(state.form.scan.id, state.form.scan)
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Scan Editado com sucesso.'
        },
        { root: true }
      )
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  },

  async create({ state, dispatch, commit }) {
    // commit("SET_FORM_DISABLED", true);
    try {
      const res = await scanProvider.create(state.form.scan)
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Scan criado com sucesso.'
        },
        { root: true }
      )
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  }
}

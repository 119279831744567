<template>
  <component
      :is="selectedComponent"
      v-model="model"
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :clearable="clearable"
      :counter="counter"
      :disabled="disabled"
      :find-method="findMethod"
      :hide-details="hideDetails"
      :hint="hint"
      :item-text="itemText"
      :item-value="itemValue"
      :items="items"
      :label="label"
      :loading="loading"
      :on-change="onChange"
      :prefix="prefix"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :rules="rules"
      :search-method="searchMethod"
      :solo="solo"
      :suffix="suffix"
      :type="type"
      dense
      @search="search"
  ></component>
</template>
<script>
const textField = () => import('@/components/TextField')
const selectField = () => import('@/components/SelectField')
const autoComplete = () => import('@/components/AutoComplete')

export default {
  name: 'GenericField',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    solo: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: String,
      default: 'auto'
    },
    counter: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: undefined
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    appendIcon: {
      type: String,
      default: undefined
    },
    appendOuterIcon: {
      type: String,
      default: undefined
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    searchMethod: {
      default: null
    },
    findMethod: {
      default: null
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    chips: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    onChange: {
      default: undefined
    }
  },

  data() {
    return {
      searchItems: []
    }
  },

  computed: {
    selectedComponent() {
      if (['text', 'email', 'password'].includes(this.type)) {
        return textField
      } else if (['select'].includes(this.type)) {
        return selectField
      } else {
        return autoComplete
      }
    },

    model: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    async search(search) {
      this.$emit('search', search)
    }
  }
}
</script>

<template>
  <div>
    <Header>
      <template v-slot:actions>
        <v-btn icon v-if="showDash" @click="showDash = false">
          <BeIcon
            iconColor="#fff"
            tooltip="Fechar Dashboard"
            icon="mdi-chevron-up"
          />
        </v-btn>
        <v-btn icon v-if="!showDash" @click="showDash = true">
          <BeIcon
            @click="showDash = true"
            iconColor="#fff"
            tooltip="Abrir Dashboard"
            icon="mdi-chevron-down"
          />
        </v-btn>
      </template>
    </Header>
    <v-slide-y-transition>
      <v-row v-if="showDash">
        <v-col cols="12" sm="6" md="3">
          <CardCount
            :loading="loadingSystemInfo"
            color="primary darken-1"
            icon="mdi-application-cog-outline"
            text="Uso médio de CPU"
            :count="
              systemInfo.averageCPUUsage ? systemInfo.averageCPUUsage + '%' : ''
            "
          />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <CardCount
            :loading="isLoadingCounters"
            color="blue darken-1"
            icon="mdi-magnify-scan"
            text="Scans Criados"
            :count="counters.totalScans"
          />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <CardCount
            :loading="isLoadingCounters"
            color="red darken-1"
            icon="mdi-barcode-scan"
            text="Dados Encontrados"
            :count="counters.totalScanned"
          />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <CardCount
            :loading="isLoadingCounters"
            color="green darken-1"
            icon="mdi-tab-search"
            text="Detectores Ativos"
            :count="counters.totalDetectors"
          />
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <v-row>
      <v-col
        v-for="category in categoryCounts"
        :key="category.id"
        sm="4"
        md=""
        lg=""
        xl=""
        cols="12"
      >
        <CategoryCardCount
          :label="category.name"
          :colors="getColorsByCategortSlug(category.slug)"
          :icon="`$vuetify.icons.${category.slug}`"
          :count="category.total"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <ScanHistoryListHeader
              class="mb-3"
              :refreshing="table.refreshing"
            />
            <DisplaySkeleton v-if="table.loading" :quantity="4" />

            <ScanHistoryListCardItem
              v-else
              :data="table.data"
              class="mb-3"
              v-for="(scanHistory, i) in scansHistories"
              :key="i"
              :scanHistory="scanHistory"
              @onDelete="confirmDelete"
              @onResultsCheck="handleRedirect"
              @onForceFinish="() => attemptForceFinish(scanHistory, i)"
            />

            <EmptyList
              v-if="
                !table.loading && scansHistories && scansHistories.length == 0
              "
              hide-actions
            />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import BeIcon from '@/components/BeIcon'
import CardCount from '@/components/Dashboard/CardCount'
import CategoryCardCount from '@/components/Dashboard/CategoryCardCount'
import Paginator from '@/components/Paginator'
import EmptyList from '@/components/EmptyList'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'

import ScanHistoryListHeader from './components/ScanHistoryListHeader.vue'
import ScanHistoryListCardItem from './components/ScanHistoryListCardItem.vue'

import { timeout } from '@/core/utils/timeout'

export default {
  name: 'HomeView',

  components: {
    CategoryCardCount,
    ScanHistoryListHeader,
    ScanHistoryListCardItem,
    DisplaySkeleton,
    Paginator,
    CardCount,
    EmptyList,
    BeFilter,
    Header,
    BeIcon
  },

  data() {
    return {
      showDash: false,
      page: 1,
      panel: false,
      timer: null,
      currentScanHistory: undefined
    }
  },

  computed: {
    ...mapGetters('dashboardModule', [
      'counters',
      'categoryCounts',
      'isLoadingCounters',
      'systemInfo',
      'loadingSystemInfo',
      'isLoadingCategoryCounts'
    ]),
    ...mapGetters('homeModule', [
      'scansHistories',
      'table',
      'pagination',
      'filters'
    ])
  },

  async mounted() {
    this.stopInterval = false

    await Promise.all([
      this.getCounters(),
      this.getCategoryCounters({}),
      this.refreshHistory(),
      this.refreshSysInfo()
    ])
  },

  beforeDestroy() {
    this.stopInterval = true
  },

  methods: {
    ...mapActions({
      getCategoryCounters: 'dashboardModule/getCategoryCounters',
      findLastScanHistory: 'homeModule/findLastScanHistory',
      setScansHistories: 'homeModule/setScansHistories',
      getSystemInfo: 'dashboardModule/getSystemInfo',
      showSnackbar: 'snackbarModule/showSnackbar',
      getCounters: 'dashboardModule/getCounters',
      deleteHistory: 'homeModule/deleteHistory',
      setPagination: 'homeModule/setPagination',
      setFilter: 'homeModule/setFilter'
    }),

    ...mapActions('scanModule', ['forceFinish']),

    ...mapActions('dialogModule', ['displayDialog']),

    async refreshSysInfo() {
      if (this.stopInterval) return

      await this.getSystemInfo()

      await timeout(10 * 1000)

      await this.refreshSysInfo()
    },

    async refreshHistory() {
      await timeout(60 * 1000)

      if (this.stopInterval) return

      await this.findLastScanHistory({ isRefresh: true })

      await this.refreshHistory()
    },

    async attemptForceFinish(scanHistory, i) {
      this.displayDialog({
        color: 'danger',
        title: 'Forçar finalização',
        type: 'confirmation',
        text: `Você tem certeza que deseja forçar a finalização do scan <strong>${scanHistory.scan.name}</strong>?`,
        callback: () => this.forceScanFinish(scanHistory.scan, i)
      })
    },

    async forceScanFinish(scan) {
      try {
        await this.forceFinish(scan.id)
        await this.showSnackbar({
          type: 'success',
          message: 'Scan finalizado forçado com sucesso'
        })
      } catch {
        await this.showSnackbar({
          type: 'error',
          message: 'Erro ao tentar finalizar forçado'
        })
      }
      this.refreshScanHistory()
    },

    getColorsByCategortSlug(slug) {
      switch (slug) {
        case 'sensitive':
          return ['#f44336', '#ba000d']
        case 'registration':
          return ['#03A9F4', '#003270']
        case 'financial':
          return ['#FF5722', '#c41c00']
        case 'behavioral':
          return ['#4527A0', '#000070']
        default:
          return ['#17a2b8', '#007388']
      }
    },

    confirmDelete(scanHistory) {
      this.currentScanHistory = scanHistory
      this.displayDialog({
        color: 'danger',
        title: 'Deletar Scan',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o histórico do Scan <strong>${scanHistory.scan.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let snackMessage = {
        type: 'success',
        message: 'Histórico deletado com sucesso!'
      }

      await this.deleteHistory(this.currentScanHistory).catch(() => {
        snackMessage = {
          type: 'error',
          message: 'Erro ao deletar o scan history, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(snackMessage)

      this.currentScanHistory = null
    },

    handleRedirect(scanHistory) {
      this.$router.push({
        name: 'ScannedData',
        params: { scanId: scanHistory.scanId, scanHistoryId: scanHistory.id }
      })
    },

    async setFilters(values) {
      this.setFilter(values)
      await this.findLastScanHistory()
    }
  },

  watch: {
    async page(value) {
      this.setPagination({ page: value })
      await this.findLastScanHistory()
    }
  }
}
</script>

export const SET_CURRENT_ERRORS = 'SET_CURRENT_ERRORS'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_TABLE_REFRESH = 'SET_TABLE_REFRESH'
export const SET_CURRENT_SCAN = 'SET_CURRENT_SCAN'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_LIST_ITEMS = 'SET_LIST_ITEMS'
export const LOADING_MODEL = 'LOADING_MODEL'
export const SET_MODEL_ID = 'SET_MODEL_ID'
export const SET_MODEL = 'SET_MODEL'
export const SET_SCAN = 'SET_SCAN'

export default {
  // # todo: usar
  [SET_LIST_ITEMS](state, scans) {
    state.scans = scans
  },

  [SET_PAGINATION](state, data) {
    state.pagination = data
    state.filters.values = {
      ...state.filters.values,
      page: data.currentPage || 1
    }
  },

  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },

  [SET_TABLE_REFRESH](state, refreshing = false) {
    state.table.refreshing = refreshing
  },

  [LOADING_MODEL](state, value = true) {
    state.form.loading = value
  },

  [SET_MODEL](state, payload) {
    state.form.scan = payload
  },

  [SET_SCAN](state, payload) {
    state.form.scan = payload
  },

  [SET_CURRENT_SCAN](state, payload) {
    state.form.scan = payload
  },

  [SET_MODEL_ID](state, id) {
    state.form.scanId = id
  },

  [SET_CURRENT_ERRORS](state, errors) {
    state.form.errors = errors
  },

  [SET_FILTER_VALUES](state, values) {
    state.filters.values = values
  }
}

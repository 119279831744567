<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" small @click="showDialog">Adicionar</v-btn>
      </template>
    </Header>

    <v-row>
      <v-col>
        <v-card color="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <DetectorCardHeader class="mb-3" />
            <DisplaySkeleton v-if="table.loading" :quantity="4" />

            <DetectorCardList
              v-else
              class="mb-3"
              v-for="(detector, i) in detectors"
              :key="i"
              :detector="detector"
              @onDelete="confirmDelete"
              @onEdit="showDialog"
            />

            <EmptyList
              :on-click="showDialog"
              v-if="!table.loading && detectors && detectors.length == 0"
            />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <DetectorDialog v-model="dialogOptions" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BeFilter from '@/components/Filter'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_CURRENT_MODEL
} from '@/store/modules/detectors/mutations'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import DetectorCardHeader from './components/DetectorCardHeader.vue'
import DetectorCardList from './components/DetectorCardList.vue'
import DetectorDialog from '@/components/DetectorDialog'
import validations from '@/core/utils/validations'
import Paginator from '@/components/Paginator'
import Header from '@/components/Header'
import EmptyList from '@/components/EmptyList'
import { isEqual } from 'lodash'

export default {
  name: 'DetectorsIndex',
  components: {
    DetectorCardHeader,
    DetectorCardList,
    DetectorDialog,
    Paginator,
    BeFilter,
    Header,
    DisplaySkeleton,
    EmptyList
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        detectorId: null
      },
      page: 1
    }
  },

  computed: {
    ...mapGetters('detectorModule', [
      'detectors',
      'filters',
      'table',
      'loading',
      'pagination',
      'readyToUpdate',
      'errors'
    ]),

    rules() {
      return {
        name: [(v) => validations.required(v)]
      }
    }
  },
  methods: {
    ...mapActions({
      filter: 'detectorModule/filter',
      delete: 'detectorModule/delete',
      create: 'detectorModule/create',
      update: 'detectorModule/update',
      find: 'detectorModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('detectorModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL
    ]),
    ...mapActions('dialogModule', ['displayDialog']),

    showDialog({ id: detectorId }) {
      this.dialogOptions = {
        show: true,
        detectorId
      }
    },

    confirmDelete(detector) {
      this.currentDetector = detector
      this.displayDialog({
        color: 'danger',
        title: 'Deletar Detector',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o detector <strong>${detector.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Detectores deletado com sucesso!'
      }

      await this.delete(this.currentDetector).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o detector, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentDetector = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ currentPage: 1 })
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({ query: { ...values } })
      }
      this.filter()
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({ currentPage: value })
      this.filter()
    }
  }
}
</script>

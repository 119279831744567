<template>
  <div>
    <Header>
      <template #actions>
        <v-btn
          color="primary"
          width="100"
          small
          @click="attemptCreate"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </template>
    </Header>

    <UserForm ref="userForm" />
  </div>
</template>
<script>
import Header from '@/components/Header'
import UserForm from './components/UserForm.vue'

export default {
  name: 'CreateUser',
  components: {
    Header,
    UserForm
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async attemptCreate() {
      this.loading = true

      try {
        await this.$refs.userForm.attemptSave()
      } catch {
        //
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

export const INIT_PERIODICITY_FORM = Object.freeze({
  name: '',
  cronPattern: '0 9 */1 * *',
  isActive: true
})

export default {
  periodicities: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },

      {
        name: 'isActive',
        label: 'Esta ativo?',
        type: 'select',
        items: [
          {
            text: 'Sim',
            value: '1'
          },
          {
            text: 'Não',
            value: '0'
          }
        ]
      }
    ],
    values: {}
  },
  // # TODO ALterar estrutura
  form: {
    loading: false,
    periodicityId: null,
    periodicity: INIT_PERIODICITY_FORM,
    errors: {
      name: [],
      cronPattern: [],
      isActive: []
    }
  },
  table: {
    loading: false,
    pagination: {}
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 0,
    hasNext: false
  }
}

import APIClient from '@/core/http/apiClient'

export class ScanHistoryProvider {
  constructor(httpClient) {
    this.httpClient = httpClient
    this.basePath = '/private/scanHistories'
  }

  async delete(params) {
    return await this.httpClient.call(
      `${this.basePath}/${params.id}`,
      params,
      'delete'
    )
  }

  async findAll(params = {}) {
    return await this.httpClient.call(`${this.basePath}`, params)
  }
}

export default new ScanHistoryProvider(new APIClient())

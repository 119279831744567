<template>
  <div>
    <v-radio
        v-model="model"
        :disabled="disabled"
        :error="error"
        :error-count="errorCount"
        :error-messages="errorMessages"
        :false-value="falseValue"
        :hide-details="hideDetails"
        :rules="rules"
        :value="value"
        
        dense
    >
      <template v-slot:label>
        <Ellipsis v-if="showLabel" :data="label"/>
      </template>
    </v-radio>
  </div>
</template>
<script>
// import Vue from 'vue'
import Ellipsis from '@/components/Ellipsis'

export default {
  name: 'BeRadioButton',

  components: {
    Ellipsis
  },

  props: {
    value: {
      default: false
    },
    label: {
      type: String,
      default: 'Label'
    },
    backgroundColor: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorCount: {
      type: Number || String,
      default: 1
    },
    errorMessages: {
      type: Array,
      default: () => []
    },

    hideDetails: {
      type: String,
      default: 'auto'
    },
    hideSpinButtons: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: undefined
    },
    rules: {
      type: Array,
      default: () => []
    },
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    intermediate: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showLabel: false
    }
  },

  created() {
    setTimeout(() => {
      this.showLabel = true
    }, 200)
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

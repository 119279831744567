<!-- eslint-disable vue/no-unused-components -->
<template>
  <v-card>
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Configurações</v-toolbar-title>
    </v-toolbar>
    <v-tabs>
      <v-tab>
        <v-icon class="mr-1"> mdi-shield-alert-outline </v-icon>
        Variáveis de ambiente
      </v-tab>
      <v-tab>
        <v-icon class="mr-1"> mdi-wrench-outline </v-icon>
        Ações
      </v-tab>

      <v-tab-item>
        <SettingsEnvVariables></SettingsEnvVariables>
      </v-tab-item>

      <v-tab-item>
        <SettingsActions></SettingsActions>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import SettingsActions from './components/SettingsActions.vue'
import SettingsEnvVariables from './components/SettingsEnvVariables.vue'
export default {
  name: 'SettingsIndex',
  components: {
    SettingsEnvVariables,
    SettingsActions
  }
}
</script>

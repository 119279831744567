<template>
  <v-card flat>
    <v-card-text>
      <v-btn color="primary" class="mb-2" @click="restartServices_" :disabled="loading">
        <v-icon>mdi-restart</v-icon>
        Reiniciar serviços
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('settingsModule', ['loading']),
  },
  methods: {
    ...mapActions('settingsModule', ['restartServices']),
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    async restartServices_() {
      let message = {
        type: 'success',
        message: 'Comando enviado com sucesso!'
      }

      await this.restartServices().catch(() => {
        message = {
          type: 'error',
          message: 'Falha ao reiniciar serviços!'
        }
      })

      this.showSnackbar(message)
    },
  },
}
</script>

import userProvider from '@/core/api/providers/user'
import { SET_CURRENT_ERRORS } from '@/store/modules/user/mutations'
import {
  LOADING_USER,
  SET_PAGINATION,
  SET_TABLE_LOADING,
  SET_MODEL,
  SET_USERS
} from '@/store/modules/user/mutations'

export default {
  async filter({ commit, state }) {
    commit('SET_TABLE_LOADING', true)

    const { page = 1 } = state.table.pagination

    const data = await userProvider.filter({
      ...state.filters.values,
      page
    })

    if (data) {
      commit(SET_USERS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async delete({ commit }, user) {
    commit(SET_TABLE_LOADING, true)

    const res = await userProvider.delete(user)
    if (res) {
      commit(SET_TABLE_LOADING, false)
    }
  },

  async find({ commit }, id) {
    commit(LOADING_USER, true)
    const data = await userProvider.find(id)

    if (data) {
      commit(SET_MODEL, data)
      commit(LOADING_USER, false)
      return data
    }
  },

  async update({ state, dispatch, commit }) {
    // commit("SET_FORM_DISABLED", true);

    try {
      const res = await userProvider.update(state.form.userId, state.form.user)
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Usuário atualizado com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  },

  async create({ state, commit, dispatch }) {
    // commit("SET_FORM_DISABLED", true);
    try {
      const res = await userProvider.create(state.form.user)

      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Usuário criado com sucesso.'
        },
        { root: true }
      )
      dispatch('filter')
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  }
}

<template>
  <BeListCardItem :leftDivColor="leftDivColor">
    <template v-slot:leftColoredDiv>
      <div style="gap: 5px" class="d-flex flex-column">
        <BeIcon
          :icon="`$vuetify.icons.${category.slug}`"
          :tooltip="category.name"
          iconColor="white"
          iconSize="30"
        />
      </div>
    </template>

    <template v-slot:content>
      <v-col class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h3>
              <BeEllipsis :data="scannedData.value" />
            </h3>
            <BeEllipsis :data="scannedData.references.fullContext" />
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeEllipsis :data="detector.name" />
          </v-col>

          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeEllipsis :data="scan.name" />
            <small>
              {{ scan.typeDescription }} /
              {{ scan.resourceTypeDescription }}
            </small>
          </v-col>

          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeEllipsis :data="scannedData.fullPathText" />
          </v-col>
        </v-row>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeIcon from '@/components/BeIcon'
import { mapGetters } from 'vuex'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeIcon
  },

  props: {
    scannedData: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      scans: 'scannedDataModule/scans',
      categories: 'scannedDataModule/categories',
      detectors: 'scannedDataModule/detectors'
    }),
    detector() {
      const { detectorId } = this.scannedData

      const [found] = this.detectors.filter(
        (detector) => detector.id == detectorId
      )

      if (found) return found

      return { name: '' }
    },
    scan() {
      const [scan] = this.scans.filter((item) =>
        item.scanHistoriesIds?.includes(this.scannedData.scanHistoryId)
      )

      if (scan) return scan
      return {}
    },
    category() {
      const detector = this.detector

      const [category] = this.categories.filter(
        (category) => category.id == detector.categoryId
      )

      if (category) return category
      return { name: '', slug: '' }
    },
    leftDivColor() {
      const category = this.category

      if (!category) return 'primary'

      switch (category.slug) {
        case 'sensitive':
          return 'error'
        case 'registration':
          return 'info'
        case 'financial':
          return 'deep-orange'
        case 'behavioral':
          return 'deep-purple darken-3'

        default:
          return 'primary'
      }
    }
  }
}
</script>

<template>
  <div>
    <v-card v-if="!isMobile" :class="`card-custom`" color="primary">
      <v-card-text
        :style="{ height }"
        :class="`pa-0 wrap-content ${textColor}--text`"
      >
        <div v-if="leftDiv" :class="`wrap-status ${leftDivColor}`">
          <slot name="leftColoredDiv" />
        </div>
        <div
          :style="`width: calc(100% - ${coloredDivSize})`"
          class="card-content align-self-stretch flex-grow-1 flex-shrink-0 ma-0 pa-0 py-2 ps-3 d-flex align-md-center"
        >
          <slot name="content"></slot>
        </div>
      </v-card-text>
    </v-card>
    <v-progress-linear
      v-if="showRefreshingBar"
      class="mt-1"
      color="primary"
      :indeterminate="refreshing"
      height="5"
    />
  </div>
</template>
<script>
export default {
  name: 'BeListCardItem',

  props: {
    leftDiv: {
      type: Boolean,
      default: true
    },

    leftDivColor: {
      type: String,
      default: 'transparent'
    },

    coloredDivSize: {
      type: String,
      default: '40px'
    },

    cardBackgroundColor: {
      type: String,
      default: 'transparent'
    },

    textColor: {
      type: String,
      default: 'white'
    },

    height: {
      type: String,
      default: '65px'
    },

    showRefreshingBar: {
      type: Boolean,
      default: false
    },

    refreshing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 960
    }
  }
}
</script>
<style scoped lang="css">
.card-custom {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.wrap-status {
  padding: 20px;
  width: 30px;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-content {
  min-height: 50px;
  display: flex;
}
</style>

<template>
  <BeListCardHeader>
    <template v-slot:leftColoredDiv>
      <BeIcon icon="mdi-checkbox-marked" tooltip="Ativo?" iconColor="white" />
    </template>
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="4" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Nome" />
            </h2>
          </v-col>
          <v-col cols="12" md="5">
            <h2>
              <BeEllipsis data="Periodicidade" />
            </h2>
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Datas" />
            </h2>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <h2>
          <BeEllipsis data="Ações" />
        </h2>
      </v-col>
    </template>
  </BeListCardHeader>
</template>
<script>
import BeListCardHeader from '@/components/BeListCardHeader'
import BeEllipsis from '@/components/Ellipsis'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardHeader,
    BeEllipsis,
    BeIcon
  }
}
</script>

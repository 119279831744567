import dashboardProvider from '@/core/api/providers/dashboard'

import {
  SET_COUNTERS,
  SET_DETECTED_CATEGORIES,
  SET_LOADING_COUNTERS,
  SET_LOADING_CATEGORIES,
  SET_LOADING_SYSTEM_INFO,
  SET_SYSTEM_INFO,
  SET_LOADING_CATEGORY_COUNTERS,
  SET_CATEGORY_COUNTERS
} from './mutations'

export default {
  async getCounters({ commit }) {
    commit(SET_LOADING_COUNTERS, true)

    try {
      const data = await dashboardProvider.getCounters()

      commit(SET_COUNTERS, data)
    } finally {
      commit(SET_LOADING_COUNTERS, false)
    }
  },
  async getCategoryCounters({ commit }) {
    commit(SET_LOADING_CATEGORY_COUNTERS, true)

    try {
      const data = await dashboardProvider.getDiscoveredCategories()

      commit(SET_CATEGORY_COUNTERS, data)
    } finally {
      commit(SET_LOADING_CATEGORY_COUNTERS, false)
    }
  },

  async getSystemInfo({ commit }) {
    commit(SET_LOADING_SYSTEM_INFO, true)

    try {
      const data = await dashboardProvider.getSystemInfo()

      commit(SET_SYSTEM_INFO, data)
    } finally {
      commit(SET_LOADING_SYSTEM_INFO, false)
    }
  },

  async getDetectedCategories({ commit }, scanHistoryId) {
    commit(SET_LOADING_CATEGORIES, true)

    try {
      const data = await dashboardProvider.getDiscoveredCategories(
        scanHistoryId
      )

      commit(SET_DETECTED_CATEGORIES, data)
    } finally {
      commit(SET_LOADING_CATEGORIES, false)
    }
  }
}

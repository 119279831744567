<template src="./index.html"></template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'BeDialog',
  computed: {
    ...mapState('dialogModule', ['dialog']),
    ...mapGetters('dialogModule', ['loadingConfirmation'])
  },

  methods: {
    ...mapActions('dialogModule', ['cancelDialog', 'acceptDialog'])
  }
}
</script>

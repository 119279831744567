<template>
  <v-form ref="agentForm">
    <v-row>
      <v-col>
        <v-alert color="red lighten-1" icon="mdi-alert" dark>
          <span>
            <strong> Atenção! </strong> Uma vez que o token foi mostrado não
            será mais possível vê-lo novamente.
          </span>
        </v-alert>
        <TextField
          label="Token"
          v-model="agent.token"
          readonly
          filled
          outlined
          ref="tokenField"
          append-icon="mdi-content-copy"
          item-text="text"
          @click:append="copyContent"
          item-value="value"
          :loading="loading"
          :error-messages="errors.status"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TextField from '@/components/TextField'

import {
  SET_MODEL,
  SET_CURRENT_MODEL,
  SET_CURRENT_ERRORS
} from '@/store/modules/periodicities/mutations'

import { INIT_AGENT_FORM } from '@/store/modules/agents/state'

export default {
  name: 'PeriodicityForm',

  components: {
    TextField
  },
  data() {
    return {
      agent: { ...INIT_AGENT_FORM },
      items: [
        {
          text: 'Esperando Comando',
          value: 0
        },
        {
          text: 'Enviar Arquivos',
          value: 1
        }
      ]
    }
  },

  computed: {
    ...mapGetters('agentModule', [
      'errors',
      'readyToUpdate',
      'loading',
      'saving'
    ])
  },

  methods: {
    ...mapActions({
      loadToken: 'agentModule/loadToken',
      update: 'agentModule/update',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('agentModule', [
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    ]),

    copyContent() {
      this.$refs.tokenField.$el.querySelector('input').select()
      document.execCommand('copy')
    },

    prepareToCreate() {
      this.agent = { ...INIT_AGENT_FORM }

      this[SET_CURRENT_ERRORS]([])
      this[SET_CURRENT_MODEL](null)
    },

    async attemptSave() {
      if (this.$refs.agentForm.validate()) {
        this[SET_MODEL](this.agent)
        if (this.readyToUpdate) {
          await this.update()
        } else {
          await this.create()
        }
      } else {
        throw Error()
      }
    },

    async findAgent(agent) {
      try {
        const { agentId } = agent
        this[SET_CURRENT_MODEL](agentId)
        const data = await this.loadToken(agentId)
        this.agent = { ...data }
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar o agente.'
        })
      }
    }
  }
}
</script>

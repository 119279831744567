<template>
  <div>
    <Sidebar />
    <AppBar />

    <v-main app>
      <v-container class="px-5" style="min-height: 90vh" fluid>
        <v-slide-x-reverse-transition mode="out-in">
          <router-view />
        </v-slide-x-reverse-transition>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import AppBar from '@/components/AppBar'
import Sidebar from '@/components/Sidebar'
import store from '@/store'

export default {
  name: 'DashboardView',

  components: {
    AppBar,
    Sidebar
  },

  data() {
    return {
      drawer: true,
      fullscreen: false,
      reportInterval: null,

      items: [
        {
          action: 'mdi-home',
          title: 'Home',
          route: { name: 'Home' },
          disabled: true
        },
        {
          action: 'mdi-cached',
          route: { name: 'ScanIndex' },
          title: 'Scans'
        },
        {
          action: 'mdi-file-search-outline',
          route: { name: 'DetectorIndex' },
          title: 'Detectores'
        },
        {
          action: 'mdi-calendar-clock',
          route: { name: 'PeriodicityIndex' },
          title: 'Periodicidades'
        },
        {
          action: 'mdi-account-multiple',
          route: { name: 'UserIndex' },
          title: 'Usuários'
        }
      ]
    }
  },
  created() {
    this.reportInterval = setInterval(this.getPendingReports, 10000)
    this.getPendingReports()
    this.getTZ()
  },
  computed: {
    currentPage() {
      return this.$route.meta.title
    }
  },

  beforeDestroy() {
    if (this.reportInterval) clearInterval(this.reportInterval)
  },

  methods: {
    toggleFullscreen() {
      if (this.fullscreen) {
        document.exitFullscreen()
      } else {
        if (document.fullscreenEnabled)
          document.documentElement.requestFullscreen()
      }

      this.fullscreen = !this.fullscreen
    },
    capitalizeWord(word) {
      if (word) return word.charAt(0).toUpperCase() + word.slice(1)
    },

    getPendingReports() {
      store.dispatch('reportModule/getPending')
    },

    getTZ() {
      store.dispatch('settingsModule/getTZ')
    }
  }
}
</script>

<template src="./Snackbar.html"></template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'DialogsView',

  data() {
    return {}
  },

  methods: {
    ...mapActions('snackbarModule', ['showSnackbar']),

    showPrimarySnackbar(hasAction = false) {
      this.showSnackbar({
        type: 'primary',
        message: 'lorem ipsum dolor sit amet?',
        isPersistent: true,
        hasAction,
        callback: this.primaryShow
      })
    },

    showWarningSnackbar(hasAction = false) {
      this.showSnackbar({
        type: 'warning',
        hasAction,
        message: 'lorem ipsum dolor sit amet?'
      })
    },

    showDangerSnackbar(hasAction = false) {
      this.showSnackbar({
        type: 'danger',
        hasAction,

        message: 'lorem ipsum dolor sit amet?'
      })
    },

    primaryShow() {
      alert('You pressed primary dialog')
    }
  }
}
</script>

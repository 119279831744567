import reportProvider from '@/core/api/providers/report'
import session from '@/core/utils/session'
import {
  SET_PAGINATION,
  SET_TABLE_LOADING,
  SET_REPORTS,
  SET_GENERATING,
  SET_DOWNLOADING,
  SET_LOADING_PENDING,
  SET_PENDING
} from '@/store/modules/report/mutations'
import { NotificationTypes } from '../notification'
import store from '@/store'
import { DateManager } from '@/core/utils/dateManager'

export default {
  async filter({ commit, state }) {
    commit('SET_TABLE_LOADING', true)

    const { page = 1 } = state.table.pagination

    const data = await reportProvider.filter({
      ...state.filters.values,
      page
    })

    if (data) {
      commit(SET_REPORTS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async generateScanHistoryReport({ commit, dispatch }, id) {
    commit(SET_GENERATING, true)
    await reportProvider.generateScanHistoryReport(id)
    store.dispatch(
      'snackbarModule/showSnackbar',
      {
        type: 'success',
        message:
          'O relatório está sendo processado. Assim que estiver pronto você será notificado.',
        timeout: 5000
      },
      { root: true }
    )

    dispatch('getPending')
    commit(SET_GENERATING, false)
  },

  async download({ commit }, { id, fileName }) {
    commit(SET_DOWNLOADING, true)
    const data = await reportProvider.download(id)

    const blob = new Blob([data], { encoding: 'UTF-8' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName ? fileName : `report-${new Date().getTime()}.html`
    link.click()
    commit(SET_DOWNLOADING, false)
  },

  async delete({ commit }, id) {
    commit(SET_DOWNLOADING, true)

    await reportProvider.delete(id)

    commit(SET_DOWNLOADING, false)
  },

  async getPending({ commit }) {
    commit(SET_LOADING_PENDING, true)
    const data = await reportProvider.getPending()
    const { pending } = data
    commit(SET_PENDING, pending)

    const old = session.get('reports')

    if (old && old.length > 0) {
      for (const item of old) {
        const inArray = pending.some((report) => report.id == item.id)

        // the report has been finished
        if (!inArray) {
          store.dispatch('notificationModule/notify', {
            title: item.scan,
            description: 'Clique no icone para baixar.',
            date: DateManager.format(new Date()),
            type: NotificationTypes.REPORT_NOTIFICATION,
            data: item
          })

          store.dispatch(
            'snackbarModule/showSnackbar',
            {
              type: '',
              message: item.fileName,
              timeout: 10000,
              action: {
                text: 'Baixar',
                handler: () =>
                  store.dispatch('reportModule/download', {
                    id: item.id,
                    fileName: item.fileName
                  })
              }
            },
            { root: true }
          )
        }
      }
    }

    session.set('reports', pending)
    commit(SET_LOADING_PENDING, false)
  },

  inPendingReports({ state }, scanHistoryId) {
    const pending = state.pending

    const found = pending.some((item) => item.scanHistoryId == scanHistoryId)

    return found
  }
}

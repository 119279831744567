<template>
  <v-app>
    <router-view />

    <DisplayDialog />
    <DisplaySnackbar />
  </v-app>
</template>

<script>
import DisplayDialog from '@/components/DisplayDialog'
import DisplaySnackbar from '@/components/DisplaySnackbar'

export default {
  name: 'App',

  components: {
    DisplayDialog,
    DisplaySnackbar
  }
}
</script>
<style scss>
@import '../node_modules/@fontsource/roboto/index.css'
</style>

<template>
  <BeListCardItem leftDivColor="success">
    <template v-slot:leftColoredDiv>
      <div class="d-flex flex-column" style="gap: 5px">
        <BeIcon
          :icon="resourceTypeIcon"
          :tooltip="resourceTypeDescription"
          iconColor="white"
          iconSize="30"
        />
      </div>
    </template>
    <template v-slot:content>
      <v-col class="ma-0 pa-0" cols="10">
        <v-row align="center" class="ma-0 pa-0" no-gutters>
          <v-col class="ma-0 pa-0" cols="12" md="3">
            <BeEllipsis :data="scan.name" />
          </v-col>
          <v-col cols="12" md="2">
            <ScanStatus
              :status="scan.status"
              :statusDescription="scan.statusDescription"
            />
          </v-col>
          <v-col cols="12" md="4">
            <ScanHistoryCount
              :scanHistory="scan.lastScanHistory"
              :scanType="scan.type"
            />
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeTooltip
              v-if="scan.lastStartAt"
              :tooltip="
                scan.lastStartAt
                  ? `Último scan realizado em ${formatDate(scan.lastStartAt)}`
                  : 'Scan ainda não iniciado'
              "
            >
              <v-icon size="20" class="primary--text">
                mdi-calendar-clock
              </v-icon>
              <span class="font-weight-bold primary--text">
                {{ scan.lastStartAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Criado em ${formatDate(scan.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank </v-icon>
              <span class="font-weight-bold">
                {{ scan.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Atualizado em ${formatDate(scan.updatedAt)}`">
              <v-icon size="20"> mdi-calendar-text </v-icon>
              <span class="font-weight-bold">
                {{ scan.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="ma-0 pa-0" cols="2">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn
            v-if="scan.shouldTest"
            :loading="scan.isTestingConnection"
            color="primary"
            icon
            @click="$emit('onConnectionTest', scan)"
          >
            <BeIcon
              icon="mdi-connection"
              iconColor="primary"
              iconSize="25"
              tooltip="Testar conexão"
            />
          </v-btn>

          <v-btn
            v-else-if="scan.status == 10 || scan.status == 20"
            :disabled="scan.isForcingFinish"
            color="primary"
            icon
            @click="$emit('onForceFinish', scan)"
          >
            <BeIcon
              icon="mdi-checkbox-blank"
              iconColor="primary"
              iconSize="25"
              tooltip="Forçar finalização"
            />
          </v-btn>

          <v-btn
            v-else
            :disabled="scan.isTestingConnection"
            :loading="scan.status == 10 || scan.status == 20"
            color="primary"
            icon
            @click="$emit('onStart', scan)"
          >
            <BeIcon
              icon="mdi-play"
              iconColor="primary"
              iconSize="25"
              tooltip="Iniciar"
            />
          </v-btn>

          <v-btn
            :disabled="!scan.formattedLastStartAt"
            color="primary"
            icon
            @click="$emit('onResultsCheck', scan)"
          >
            <BeIcon
              icon="mdi-card-search"
              iconColor="primary"
              iconSize="25"
              tooltip="Ver resultados"
            />
          </v-btn>

          <v-btn
            :disabled="
              scan.isTestingConnection || scan.status == 10 || scan.status == 20
            "
            color="primary"
            icon
            @click="$emit('onEdit', scan)"
          >
            <BeIcon
              icon="mdi-pencil"
              iconColor="primary"
              iconSize="25"
              tooltip="Editar"
            />
          </v-btn>

          <v-btn
            :disabled="
              scan.isTestingConnection || scan.status == 10 || scan.status == 20
            "
            color="primary"
            icon
            @click="$emit('onDelete', scan)"
          >
            <BeIcon
              icon="mdi-delete"
              iconColor="primary"
              iconSize="25"
              tooltip="Deletar"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'
import ScanHistoryCount from '@/components/ScanHistoryCount'
import ScanStatus from './ScanStatus'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    ScanStatus,
    BeTooltip,
    BeIcon,
    ScanHistoryCount
  },

  props: {
    scan: {
      type: Object,
      required: true
    }
  },

  computed: {
    resourceTypeIcon() {
      if (this.scan.type === 'agent') return `$vuetify.icons.agent`

      return `$vuetify.icons.${this.scan.resourceType}`
    },

    resourceTypeDescription() {
      return `${this.scan.typeDescription} / ${this.scan.resourceTypeDescription}`
    }
  }
}
</script>
<style scoped></style>

import authProvider from '@/core/api/providers/auth'
import session from '@/core/utils/session'

export default {
  async login({ commit }, credentials) {
    const data = await authProvider.login(credentials)

    if (!data) throw new Error('Credenciais inválidas.')

    const { name, accessToken, refreshToken, username } = data

    commit('assignUser', { name, accessToken, refreshToken, username })
  },

  async refreshSession({ commit }) {
    const data = await authProvider.refreshSession()

    if (!data) throw new Error('Credenciais inválidas.')

    const { name, accessToken, refreshToken, username } = data

    commit('assignUser', { name, accessToken, refreshToken, username })
  },

  async logout() {
    try {
      await authProvider.logout()
    } finally {
      session.destroy()
    }
  }
}

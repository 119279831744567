<template>
  <BeListCardItem
    :leftDivColor="periodicity.isActive ? 'success' : 'grey darken-1'"
  >
    <template v-slot:leftColoredDiv>
      <BeIcon icon="" :tooltip="periodicity.isActive ? 'Ativo' : 'Inativo'" />
    </template>
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="4" class="ma-0 pa-0">
            <BeEllipsis :data="periodicity.name" />
          </v-col>
          <v-col cols="12" md="5">
            <BeEllipsis
              :data="periodicity.cronPattern | parseCron"
              :tooltip="periodicity.cronPattern | parseCron"
            />
            <strong>
              <BeEllipsis
                :data="periodicity.cronPattern"
                :tooltip="periodicity.cronPattern"
              />
            </strong>
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeTooltip :tooltip="`Criado em ${formatDate(periodicity.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank </v-icon>
              <span class="font-weight-bold">
                {{ periodicity.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip
              :tooltip="`Atualizado em ${formatDate(periodicity.updatedAt)}`"
            >
              <v-icon size="20"> mdi-calendar-text </v-icon>
              <span class="font-weight-bold">
                {{ periodicity.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn icon color="primary" @click="$emit('onEdit', periodicity)">
            <BeIcon
              icon="mdi-pencil"
              tooltip="Editar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>

          <v-btn icon color="primary" @click="$emit('onDelete', periodicity)">
            <BeIcon
              icon="mdi-delete"
              tooltip="Deletar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    periodicity: {
      type: Object,
      required: true
    }
  }
}
</script>

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" small @click="showDialog">Adicionar</v-btn>
      </template>
    </Header>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters"/>
          </v-card-title>
          <v-card-text class="pb-0">
            <AgentCardHeader class="mb-3"/>

            <DisplaySkeleton v-if="table.loading" :quantity="4"/>
            <AgentCardList
                v-else
                class="mb-3"
                v-for="(agent, i) in agents"
                :key="i"
                :agent="agent"
                @onTokenCopy="showCredentials"
                @onDelete="confirmDelete"
                @onEdit="showDialog"
            />

            <EmptyList
                :on-click="showDialog"
                v-if="!table.loading && agents && agents.length == 0"
            />

            <Paginator
                :pagination="pagination"
                v-if="agents && agents.length > 0"
                v-model="page"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AgentDialog v-model="dialogOptions"/>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import AgentDialog from '@/components/AgentDialog'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'

import AgentCardHeader from './components/AgentCardHeader.vue'
import AgentCardList from './components/AgentCardList.vue'
import EmptyList from '@/components/EmptyList'
import {isEqual} from 'lodash'

export default {
  name: 'AgentIndex',
  components: {
    AgentCardHeader,
    DisplaySkeleton,
    AgentCardList,
    AgentDialog,
    Paginator,
    BeFilter,
    Header,
    EmptyList
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        agentId: null,
        type: 'form'
      },
      page: 1
    }
  },

  computed: {
    ...mapGetters('agentModule', [
      'agents',
      'filters',
      'table',
      'loading',
      'pagination'
    ])
  },

  methods: {
    ...mapActions({
      filter: 'agentModule/filter',
      delete: 'agentModule/delete',
      create: 'agentModule/create',
      update: 'agentModule/update',
      setFilterValues: 'agentModule/setFilterValues',
      setPagination: 'agentModule/setPagination',
      find: 'agentModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    ...mapActions('dialogModule', ['displayDialog']),

    showDialog({id: agentId}, type = 'form') {
      this.dialogOptions = {
        show: true,
        agentId,
        type
      }
    },

    showCredentials(agent) {
      if (agent.hasCopiedToken) {
        this.displayDialog({
          color: 'info',
          title: 'Operação não Permitida',
          type: 'info',
          text: `O token já foi copiado uma vez, não é possível novamente.`,
          callback: this.attemptDelete
        })
      } else {
        this.showDialog(agent, 'token')
      }
    },

    confirmDelete(agent) {
      this.currentAgent = agent
      this.displayDialog({
        color: 'danger',
        title: 'Deletar Agente',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o agente <strong>${agent.name}</strong>? Os arquivos salvos na pasta <kbd>temp</kbd> relacionados a ele seram deletados e ele será desvinculado dos scans.`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Agente deletado com sucesso!'
      }

      await this.delete(this.currentAgent).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o agente, tente novamente mais tarde!'
        }
      })

      await this.showSnackbar(message)

      this.currentAgent = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this.setFilterValues(values)
      this.setPagination({page: this.page})
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({query: {...values}})
      }
      this.filter()
    }
  },

  watch: {
    page(value) {
      this.setPagination({page: value})
      this.filter()
    }
  }
}
</script>

import scannedDataProvider from '@/core/api/providers/scannedData'

export default {
  async filter({ commit, state }, { append = false, ...params }) {
    commit('SET_TABLE_LOADING', true)
    const { pagination = {} } = state

    const { page = 1 } = pagination

    const data = await scannedDataProvider.filter({ page, ...params })

    if (append) commit('SET_SCANNED_DATA', [...state.scannedData, ...data.data])
    else commit('SET_SCANNED_DATA', data.data)

    commit('SET_PAGINATION', data.meta)
    commit('SET_TABLE_LOADING', false)
  },

  async loadInfo({ commit }, scannedDataId) {
    commit('SET_LOADING_INFO', true)

    const data = await scannedDataProvider.loadInfo(scannedDataId)

    const { scans, detectors, categories } = data

    commit('SET_SCANS', scans)
    commit('SET_DETECTORS', detectors)
    commit('SET_CATEGORIES', categories)

    commit('SET_LOADING_INFO', false)
  },

  async delete({ commit }, scannedData) {
    commit('SET_TABLE_LOADING', true)

    const res = await scannedDataProvider.delete(scannedData)

    if (res) {
      commit('SET_TABLE_LOADING', false)
    }
  }
}

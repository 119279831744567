import scanHistoryProvider from '@/core/api/providers/scanHistory'

import {
  SET_SCAN_HISTORIES,
  SET_TABLE_LOADING,
  SET_FILTER_VALUES,
  SET_TABLE_REFRESH,
  SET_PAGINATION
} from './mutations'

export default {
  async findLastScanHistory(
    { commit, state },
    { isRefresh } = { isRefresh: false }
  ) {
    commit(SET_TABLE_LOADING, !isRefresh)
    commit(SET_TABLE_REFRESH, isRefresh)

    const {
      pagination: { page = 1 },
      filters: { values = {} }
    } = state

    try {
      const data = await scanHistoryProvider.findAll({
        ...values,
        page
      })

      if (data) {
        commit(SET_SCAN_HISTORIES, data.data)
        commit(SET_PAGINATION, data.meta)
      }
    } finally {
      commit(SET_TABLE_LOADING, false)
      commit(SET_TABLE_REFRESH, false)
    }
  },

  async deleteHistory({ dispatch }, scanHistory) {
    await scanHistoryProvider.delete(scanHistory)

    dispatch('findLastScanHistory', { isRefresh: true })
  },

  async setPagination({ commit }, pagination) {
    commit(SET_PAGINATION, pagination)
  },

  async setFilter({ commit }, filter) {
    commit(SET_FILTER_VALUES, filter)
  }
}

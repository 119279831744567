export default {
  users: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },
      {
        name: 'username',
        label: 'Username',
        type: 'text'
      }
    ],
    values: {}
  },
  // # TODO ALterar estrutura
  form: {
    saving: true,
    userId: null,
    user: {
      name: null,
      username: null,
      password: null,
      password_confirmation: null
    },
    errors: {}
  },
  table: {
    loading: false,
    pagination: {}
  }
}

export const APPEND_RESOURCE_TYPE = 'APPEND_RESOURCE_TYPE'
export const SET_SCAN_HISTORIES = 'SET_SCANS_HISTORIES'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_TABLE_REFRESH = 'SET_TABLE_REFRESH'
export const SET_PAGINATION = 'SET_PAGINATION'

import store from '@/store'

export default {
  [SET_SCAN_HISTORIES](state, scansHistories) {
    state.scansHistories = scansHistories
  },

  [SET_PAGINATION](state, pagination) {
    state.pagination = { ...state.pagination, ...pagination }
  },

  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },

  [SET_TABLE_REFRESH](state, refreshing = false) {
    state.table.refreshing = refreshing
  },

  [SET_FILTER_VALUES](state, values) {
    Object.assign(state.filters, { values })
  },

  [APPEND_RESOURCE_TYPE](state, inputType) {
    state.filters.fields = state.filters.fields.filter(
      (f) => f.name != 'resourceType'
    )

    if (inputType == 'agent') {
      state.filters.fields.push({
        name: 'resourceType',
        label: 'Recurso de Destino',
        type: 'autocomplete',
        findMethod: async (value) => {
          return await store.dispatch('agentModule/find', value)
        },
        searchMethod: async (value) => {
          return await store.dispatch('agentModule/findByOption', value)
        },
        itemText: 'name',
        itemValue: 'id'
      })
    } else if (inputType == 'database') {
      state.filters.fields.push({
        name: 'resourceType',
        label: 'Recurso de Destino',
        type: 'text'
      })
    }
  }
}

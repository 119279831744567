export const SET_FILTERS = 'SET_FILTERS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'

export default {
  [SET_FILTERS](state, filters) {
    state.filters = filters
  },

  [CLEAR_FILTERS](state) {
    state.filters = {}
  }
}

<template>
  <div class="wrapper" @click="handleRedirect">
    <v-img src="/logo-branco.png" contain />
  </div>
</template>

<script>
export default {
  name: 'BeLogo',

  methods: {
    handleRedirect() {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
.wrapper {
  width: 200px;
  margin-left: 10px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wrapper span {
  font-size: 24px;
}
</style>

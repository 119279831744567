import VueRouter from 'vue-router'
import Vue from 'vue'
import routes from './routes'
import { env } from '@/core/utils/env'
import session from '@/core/utils/session'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

const requiresAuth = (route) =>
  route.matched.some((record) => record.meta.requiresAuth)
const isAuthenticated = () => session.has('user')

router.beforeEach((to, from, next) => {
  let hasMatches = to.matched.some((record) => record != null)

  if (hasMatches) {
    if (requiresAuth(to)) {
      if (isAuthenticated()) {
        next()
      } else {
        next('/login')
      }
    }

    next()
  } else {
    next({ name: 'NotFound' })
  }
})

router.afterEach((to) => {
  store.commit('filterModule/CLEAR_FILTERS')

  Vue.nextTick(() => {
    document.title = `${env('VUE_APP_NAME')} ${
      to.meta.title ? ' | ' + to.meta.title : ''
    }`
  })
})

export default router

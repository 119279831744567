<template>
  <div>
    <Header>

    </Header>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <ReportCardHeader class="mb-3" />

            <DisplaySkeleton v-if="table.loading" :quantity="4" />
            <ReportCardList v-else class="mb-3" v-for="(report, i) in reports" :key="i" :report="report"
              @onDelete="confirmDelete" @onDownload="downloadReport(report)" />

            <EmptyList hide-actions v-if="!table.loading && reports && reports.length == 0" />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES
} from '@/store/modules/user/mutations'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'
import ReportCardHeader from './components/ReportCardHeader.vue'
import ReportCardList from './components/ReportCardList.vue'
import EmptyList from '@/components/EmptyList'
import { isEqual } from 'lodash'

export default {
  name: 'UserIndex',
  components: {
    ReportCardList,
    DisplaySkeleton,
    ReportCardHeader,
    Paginator,
    BeFilter,
    Header,
    EmptyList
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        detectorId: null
      },
      page: 1,
      panel: true
    }
  },

  computed: {
    ...mapGetters('reportModule', ['reports', 'filters', 'table', 'pagination', ' pending']),
  },

  methods: {
    ...mapActions('reportModule', ['filter', 'download', 'generate', 'delete']),
    ...mapMutations('reportModule', [SET_PAGINATION, SET_FILTER_VALUES]),
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar',
      displayDialog: 'dialogModule/displayDialog'
    }),

    downloadReport({ id, filename }) {
      this.download({ id, fileName: filename })
    },

    async loadReports() {
      await this.filter()
    },

    confirmDelete(report) {
      this.currentReport = report
      this.displayDialog({
        color: 'danger',
        title: 'Deletar relatorio',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o relatorio?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Relatorio deletado com sucesso!'
      }

      const { id } = this.currentReport

      await this.delete(id).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o relatorio, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentReport = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ currentPage: this.page })
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({ query: { ...values } })
      }
      this.loadReports()
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({ page: value })
      this.filter()
    }
  }
}
</script>

import Vuex, { Store, createLogger } from 'vuex'
import Vue from 'vue'
import detectorModule from './modules/detectors'
import periodicityModule from './modules/periodicities'
import scannedDataModule from './modules/scannedData'
import dashboardModule from './modules/dashboard'
import snackbarModule from './modules/snackbar'
import dialogModule from './modules/dialog'
import scanModule from './modules/scans'
import userModule from './modules/user'
import authModule from './modules/auth'
import homeModule from './modules/home'
import logModule from './modules/logs'
import agentModule from './modules/agents'
import sensitiveCategoryModule from './modules/sensitiveCategories'
import filterModule from './modules/filter'
import reportModule from './modules/report'
import notificationModule from './modules/notification'
import apiTokenModule from './modules/apiToken'
import settingsModule from './modules/settings'

Vue.use(Vuex)

const debug =
  process.env.VUE_APP_DEBUG &&
  process.env.VUE_APP_DEBUG.toUpperCase() === 'TRUE'

const store = new Store({
  modules: {
    scannedDataModule,
    dashboardModule,
    snackbarModule,
    dialogModule,
    authModule,
    userModule,
    scanModule,
    homeModule,
    periodicityModule,
    detectorModule,
    logModule,
    agentModule,
    sensitiveCategoryModule,
    filterModule,
    reportModule,
    notificationModule,
    apiTokenModule,
    settingsModule,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
})

export default store

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" small @click="showDialog">Adicionar</v-btn>
      </template>
    </Header>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <PeriodicityCardHeader class="mb-3" />
            <DisplaySkeleton v-if="table.loading" :quantity="4" />

            <PeriodicityCardList
              v-else
              class="mb-3"
              v-for="(periodicity, i) in periodicities"
              :key="i"
              :periodicity="periodicity"
              @onDelete="confirmDelete"
              @onEdit="showDialog"
            />

            <EmptyList
              :on-click="showDialog"
              v-if="
                !table.loading && periodicities && periodicities.length == 0
              "
            />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <PeriodicityDialog v-model="dialogOptions" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_CURRENT_MODEL
} from '@/store/modules/periodicities/mutations'

import validations from '@/core/utils/validations'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import PeriodicityDialog from '@/components/PeriodicityDialog'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'

import PeriodicityCardHeader from './components/PeriodicityCardHeader.vue'
import PeriodicityCardList from './components/PeriodicityCardList.vue'
import EmptyList from '@/components/EmptyList'
import { isEqual } from 'lodash'

export default {
  name: 'PeriodicityIndex',
  components: {
    PeriodicityCardHeader,
    PeriodicityCardList,
    PeriodicityDialog,
    Paginator,
    BeFilter,
    Header,
    DisplaySkeleton,
    EmptyList
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        periodicityId: null
      },
      page: 1
    }
  },

  computed: {
    ...mapGetters('periodicityModule', [
      'periodicities',
      'filters',
      'table',
      'loading',
      'pagination',
      'readyToUpdate',
      'errors'
    ]),

    rules() {
      return {
        name: [(v) => validations.required(v)]
      }
    },

    dialogTitle() {
      if (this.readyToUpdate) {
        return 'Editar Periodicidade'
      }

      return 'Nova Periodicidade'
    }
  },

  methods: {
    ...mapActions({
      filter: 'periodicityModule/filter',
      delete: 'periodicityModule/delete',
      create: 'periodicityModule/create',
      update: 'periodicityModule/update',
      find: 'periodicityModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('periodicityModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL
    ]),
    ...mapActions('dialogModule', ['displayDialog']),

    showDialog({ id: periodicityId }) {
      this.dialogOptions = {
        show: true,
        periodicityId
      }
    },

    confirmDelete(scan) {
      this.currentScan = scan
      this.displayDialog({
        color: 'danger',
        title: 'Deletar Periodicidade',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar a periodicidade <strong>${scan.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Periodicidade deletada com sucesso!'
      }

      await this.delete(this.currentScan).catch(() => {
        message = {
          type: 'error',
          message:
            'Erro ao deletar a periodicidade, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentScan = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ currentPage: 1 })
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({ query: { ...values } })
      }
      this.filter()
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({ currentPage: value })
      this.filter()
    }
  }
}
</script>

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn
          color="primary"
          width="100"
          small
          @click="attemptUpdate"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </template>
    </Header>

    <EditForm ref="userForm" />
  </div>
</template>
<script>
import Header from '@/components/Header'
import EditForm from './components/UserForm.vue'

export default {
  name: 'EditUser',
  components: {
    Header,
    EditForm
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async attemptUpdate() {
      this.loading = true
      await this.$refs.userForm.attemptSave()
      this.loading = false
    }
  }
}
</script>

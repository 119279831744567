import Dashboard from '@/views/layout/Dashboard'
import authRoutes from './auth'
import homeRoute from './home'
import templateRoutes from './templates'
import scanRoutes from './scans'
import userRoutes from './users'
import periodicityRoutes from './periodicities'
import agentRoutes from './agents'
import detectorRoutes from './detectors'
import documentationRoutes from './documentation'
import reportRoutes from "@/router/reports";
import APITokensRoutes from "@/router/apiTokens";
import SettingsRoutes from "@/router/settings";

import NotFound from '@/views/error/404'

const routes = [
  authRoutes,
  documentationRoutes,
  {
    name: 'Dashboard',
    path: '/',
    component: Dashboard,
    meta: {
      title: 'be.discovery',
      requiresAuth: true,
      breadCrumb: [
        {
          to: {name: 'Dashboard'},
          text: 'Dashboard'
        }
      ]
    },
    children: [
      homeRoute,
      ...templateRoutes,
      ...scanRoutes,
      ...userRoutes,
      ...periodicityRoutes,
      ...agentRoutes,
      ...detectorRoutes,
      ...reportRoutes,
      ...APITokensRoutes,
      ...SettingsRoutes
    ]
  },
  {
    name: 'NotFound',
    path: '*',
    component: NotFound
  }
]

export default routes

<template>
  <v-form ref="APITokenFormToken">
    <v-row>
      <v-col>
        <v-alert color="red lighten-1" icon="mdi-alert" dark>
          <span>
            <strong> Atenção! </strong> Uma vez que o token foi mostrado não
            será mais possível vê-lo novamente.
          </span>
        </v-alert>
        <BeTextField
          label="Token"
          v-model="apiToken.token"
          readonly
          filled
          outlined
          ref="tokenField"
          append-icon="mdi-content-copy"
          item-text="text"
          @click:append="copyContent"
          item-value="value"
          :loading="loading"
          :error-messages="errors.status"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import BeTextField from '@/components/TextField'
import Vue from 'vue'

import {
  SET_MODEL,
  SET_CURRENT_MODEL,
  SET_CURRENT_ERRORS
} from '@/store/modules/periodicities/mutations'

export default {
  name: 'APITokenFormToken',

  components: {
    BeTextField
  },
  data() {
    return {
      resourceOrigin: 0,
      resourceTarget: 0,
      apiTokenId: null,
      apiToken: {
        id: null,
        name: null,
        description: null
      }
    }
  },

  computed: {
    ...mapGetters('apiTokenModule', [
      'errors',
      'readyToUpdate',
      'loading',
      'saving'
    ])
  },

  async created() {
    Vue.nextTick(async () => {
      this.$refs.APITokenFormToken.reset()
      const { id: apiTokenId } = this.$route.params
      if (apiTokenId) {
        this.apiTokenId = apiTokenId
        this[SET_CURRENT_MODEL](apiTokenId)
        this[SET_CURRENT_ERRORS]([])

        this.loading = true

        try {
          const apiToken = await this.find(apiTokenId)

          if (apiToken) this.apiToken = { ...apiToken }
        } catch {
          //
        } finally {
          this.loading = false
        }
      }
    })
  },

  methods: {
    ...mapActions({
      generateToken: 'apiTokenModule/generateToken',
      showSnackbar: 'snackbarModule/showSnackbar',
      find: 'apiTokenModule/find',
    }),
    ...mapMutations('apiTokenModule', [
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    ]),

    copyContent() {
      this.$refs.tokenField.$el.querySelector('input').select()
      document.execCommand('copy')
    },

    prepareToCreate() {
      this.$refs.APITokenFormToken.reset()
      this[SET_CURRENT_ERRORS]([])
      this[SET_CURRENT_MODEL](null)
    },

    async showToken(token) {
      try {
        let generatedToken = await this.generateToken(token.apiTokenId)
        this.apiToken.token = generatedToken.token
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel gerar o token.'
        })
      }
    },
  }
}
</script>

import SettingsIndex from '@/views/settings/Index'

export default [
  {
    name: 'SettingsIndex',
    path: '/settings',
    component: SettingsIndex,
    meta: {
      title: 'Configurações',
      breadCrumb: [
        {
          to: { name: 'SettingsIndex' },
          text: 'Configurações'
        }
      ]
    }
  },
]

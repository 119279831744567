export const SET_MODEL = 'SET_MODEL'
export const LOADING_USER = 'LOADING_USER'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_USERS = 'SET_USERS'
export const SET_CURRENT_MODEL = 'SET_CURRENT_MODEL'
export const SET_CURRENT_ERRORS = 'SET_CURRENT_ERRORS'

export default {
  [SET_USERS](state, users) {
    state.users = users
  },

  [SET_PAGINATION](state, pagination) {
    state.table.pagination = pagination
  },

  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },

  [LOADING_USER](state, value = true) {
    state.form.loading = value
  },

  [SET_CURRENT_MODEL](state, userId) {
    state.form.userId = userId
  },

  [SET_MODEL](state, payload) {
    state.form.user = payload
  },

  [SET_CURRENT_ERRORS](state, errors) {
    state.form.errors = errors
  },

  [SET_FILTER_VALUES](state, values) {
    state.filters.values = values
  }
}

import APIClient from '@/core/http/apiClient'

export class ScanProvider {
  constructor(httpClient) {
    this.basePath = '/private/scans'
    this.httpClient = httpClient
  }

  async create(data) {
    return await this.httpClient.call(`${this.basePath}`, data, 'post')
  }

  async update(id, data) {
    return await this.httpClient.call(`${this.basePath}/${id}`, data, 'put')
  }

  async find(id) {
    return await this.httpClient.call(`${this.basePath}/${id}`, {}, 'get')
  }

  async filter(params) {
    return await this.httpClient.call(`${this.basePath}`, params, 'get')
  }

  async delete(params) {
    return await this.httpClient.call(
      `${this.basePath}/${params.id}`,
      params,
      'delete'
    )
  }

  async testConnection(id) {
    return await this.httpClient.call(
      `${this.basePath}/testConnection/${id}`,
      {},
      'get'
    )
  }

  async forceFinish(id) {
    return await this.httpClient.call(
      `${this.basePath}/${id}/forceFinish`,
      {},
      'patch'
    )
  }

  async start(params) {
    return await this.httpClient.call(
      `${this.basePath}/${params.id}/start`,
      params,
      'patch'
    )
  }

  async findByOption(data) {
    return await this.httpClient.call(`${this.basePath}/options`, data, 'get')
  }
}

export default new ScanProvider(new APIClient())

import APIClient from '@/core/http/apiClient'

export class ScannedDataProvider {
  constructor(httpClient) {
    this.httpClient = httpClient
    this.basePath = '/private/scannedData'
  }

  async filter(params) {
    const { scanHistoryId } = params
    return await this.httpClient.call(
      `${this.basePath}/${scanHistoryId ? scanHistoryId : ''}`,
      params,
      'GET'
    )
  }

  async loadInfo(scanHistoryId) {
    return await this.httpClient.call(
      `${this.basePath}/loadInfo/${scanHistoryId}`,
      {},
      'GET'
    )
  }
}

export default new ScannedDataProvider(new APIClient())

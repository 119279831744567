<template>
  <div>
    <label> Regex </label>
    <div style="gap: 10px" class="d-flex align-center justify-center">
      <span style="font-size: 25px"> / </span>

      <v-col class="pa-0 ma-0">
        <v-text-field
          v-model="regex_.pattern"
          label="Expressão *"
          solo
          dense
          hide-details
        />
      </v-col>

      <span style="font-size: 25px"> / </span>
      <v-col class="pa-0 ma-0" cols="3">
        <v-autocomplete
          v-model="regex_.flags"
          label="Flags"
          :items="flags"
          dense
          solo
          multiple
          hide-details
        >
          <template v-slot:selection="data">
            {{ data.item.value }}
          </template>
        </v-autocomplete>
      </v-col>
    </div>
    <span v-for="error in errorMessages" :key="error" class="red--text">
      {{ error }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'RegexInput',

  props: {
    regex: {
      type: Object,
      default: () => {}
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },

  mounted() {
    this.regex_ = { ...this.regex }
  },

  methods: {
    handleChange() {
      this.$emit('change', this.regex_)
    }
  },

  watch: {
    regex(value) {
      this.regex_ = value
    },

    regex_: {
      handler() {
        this.$emit('change', this.regex_)
      },
      deep: true
    }
  },

  data() {
    return {
      regex_: {
        pattern: '',
        flags: []
      },
      flags: [
        { text: 'Não diferencia maiúsculas de minúsculas', value: 'i' },
        { text: 'Modo multilinha', value: 'm' },
        { text: 'Modo "dotall"', value: 's' },
        { text: 'Suporte total a Unicode', value: 'u' },
        { text: 'Modo “Sticky”', value: 'y' }
      ]
    }
  }
}
</script>

export const SET_SCANNED_DATA = 'SET_SCANNED_DATA'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_LOADING_INFO = 'SET_LOADING_INFO'
export const SET_SCANS = 'SET_SCANS'
export const SET_DETECTORS = 'SET_DETECTORS'
export const SET_CATEGORIES = 'SET_CATEGORIES'

export default {
  [SET_SCANS](state, scans) {
    state.scans = scans
  },
  [SET_DETECTORS](state, detectors) {
    state.detectors = detectors
  },
  [SET_CATEGORIES](state, categories) {
    state.categories = categories
  },
  [SET_SCANNED_DATA](state, scannedData) {
    state.scannedData = scannedData
  },
  [SET_LOADING_INFO](state, loadingInfo) {
    state.loadingInfo = loadingInfo
  },
  [SET_PAGINATION](state, pagination) {
    state.pagination = { ...state.pagination, ...pagination }
  },
  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },
  [SET_FILTER_VALUES](state, values) {
    state.filters.values = values
  }
}

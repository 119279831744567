import store from '@/store'

export default {
  pagination(state) {
    return state.pagination
  },

  scansHistories(state) {
    return state.scansHistories
  },

  table(state) {
    return state.table
  },

  filters(state) {
    const { values, fields } = state.filters

    const [hasInput] = state.filters.fields.filter(
      (f) => f.name == 'resourceType'
    )

    if (!hasInput) {
      store.commit('homeModule/APPEND_RESOURCE_TYPE', values['type'])
    }

    return { values, fields }
  }
}

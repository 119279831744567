import LogProvider from '@/core/api/providers/log'
// import axios from "axios";

export default {
  async download() {
    const response = await LogProvider.download()
    const date = new Date().toISOString()
    const fileName = `logs_${date}.zip`

    let fileURL = window.URL.createObjectURL(new Blob([response]))
    let fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', fileName)
    document.body.appendChild(fileLink)
    fileLink.click()
  }
}

export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.pagination
  },

  scans(state) {
    return state.scans
  },
  scan(state) {
    return state.form.scan
  },
  errors(state) {
    return state.form.errors
  },
  filters(state) {
    return state.filters
  }
}

<template>
  <v-skeleton-loader
    :max-width="maxWidth"
    :max-height="maxHeight"
    :type="type"
  ></v-skeleton-loader>
</template>

<script>
export default {
  name: 'BeSkeleton',
  props: {
    maxWidth: {
      type: String,
      default: '100%'
    },
    maxHeight: {
      type: String,
      default: '60px'
    },
    type: {
      type: String,
      default: 'image'
    }
  }
}
</script>

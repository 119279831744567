export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.pagination
  },

  agents(state) {
    return state.agents
  },
  agent(state) {
    return state.form.agent
  },
  loading(state) {
    return state.form.loading
  },
  saving(state) {
    return state.form.saving
  },
  errors(state) {
    return state.form.errors
  },
  readyToUpdate(state) {
    return state.form.agentId != null
  },
  filters(state) {
    return state.filters
  }
}

<template>
  <BeListCardItem :isProcessing="isGeneratingReport()" leftDivColor="success">
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="7" class="ma-0 pa-0">
            <BeEllipsis :data="report.filename" />
            <strong>
              <BeEllipsis :data="isGeneratingReport() ? 'O relatório está sendo gerado...' : report.path" />
            </strong>
          </v-col>
          <v-col cols="12" md="5" class="ma-0 pa-0">
            <BeTooltip :tooltip="`Criado em ${report.createdAt}`">
              <v-icon size="20"> mdi-calendar-blank</v-icon>
              <span class="font-weight-bold">
                {{ report.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Atualizado em ${report.updatedAt}`">
              <v-icon size="20"> mdi-calendar-text</v-icon>
              <span class="font-weight-bold">
                {{ report.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <div class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10">
          <v-btn icon color="primary" :disabled="isGeneratingReport()" @click="$emit('onDownload', report)">
            <BeIcon icon="mdi-download" tooltip="Download" iconColor="primary" iconSize="25" />
          </v-btn>

          <v-btn icon color="primary" :disabled="isGeneratingReport()" @click="$emit('onDelete', report)">
            <BeIcon icon="mdi-delete" tooltip="Deletar" iconColor="primary" iconSize="25" />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'
import { mapGetters } from 'vuex'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('reportModule', ['pending'])
  },
  methods: {
    isGeneratingReport() {
      return this.pending?.some((report) => report.id == this.report.id) == true
    },
  }
}
</script>

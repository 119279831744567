<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" width="100" small :to="{ name: 'CreateScan' }">
          Adicionar
        </v-btn>
      </template>
    </Header>
    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <ScanCardHeader class="mb-3" :refreshing="table.refreshing" />
            <DisplaySkeleton v-if="table.loading" :quantity="4" />

            <ScanCardList
              v-else
              class="mb-3"
              v-for="(scan, i) in scans"
              :key="i"
              :scan="{ ...scan }"
              @onDelete="confirmDelete"
              @onEdit="redirectToEdit"
              @onStart="confirmStart"
              @onConnectionTest="() => attemptTestConnection(scan, i)"
              @onForceFinish="() => attemptForceFinish(scan, i)"
              @onResultsCheck="handleRedirect"
            />

            <EmptyList
              :on-click="redirectToCreate"
              v-if="!table.loading && scans && scans.length == 0"
            />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { isEqual } from 'lodash'

import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_LIST_ITEMS
} from '@/store/modules/scans/mutations'

import ScanCardHeader from './components/ScanCardHeader.vue'
import DisplaySkeleton from '@/components/DisplaySkeleton'
import Paginator from '@/components/Paginator'
import EmptyList from '@/components/EmptyList'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'

import ScanCardList from './components/ScanCardList.vue'
import { timeout } from '@/core/utils/timeout'

export default {
  name: 'ScanIndex',
  components: {
    ScanCardHeader,
    ScanCardList,
    Paginator,
    BeFilter,
    Header,
    DisplaySkeleton,
    EmptyList
  },
  data() {
    return {
      page: 1,
      panel: true,
      currentScan: null,
      stopInterval: false // Every 10 seconds refresh screen data based on filters
    }
  },

  async mounted() {
    await this.refreshScans()
  },

  beforeDestroy() {
    this.stopInterval = true
  },

  computed: {
    ...mapGetters('scanModule', ['scans', 'filters', 'table', 'pagination'])
  },

  methods: {
    ...mapActions('scanModule', [
      'filter',
      'delete',
      'start',
      'testConnection',
      'forceFinish'
    ]),
    ...mapActions('snackbarModule', ['showSnackbar']),
    ...mapMutations('scanModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_LIST_ITEMS
    ]),
    ...mapActions('dialogModule', ['displayDialog']),

    async refreshScans() {
      await timeout(60 * 1000)

      if (this.stopInterval) return

      await this.filter({ isRefresh: true })

      await this.refreshScans()
    },

    redirectToCreate() {
      this.$router.push({ name: 'CreateScan' })
    },

    async loadScans() {
      await this.filter()
    },

    redirectToEdit(scan) {
      this.$router.push({ name: 'EditScan', params: { id: scan.id } })
    },

    confirmDelete(scan) {
      this.currentScan = scan
      this.displayDialog({
        color: 'danger',
        title: 'Deletar Scan',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o scan <strong>${scan.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Scan deletado com sucesso!'
      }

      await this.delete(this.currentScan).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o scan, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentScan = null

      await this.filter({})
    },

    async confirmStart(scan) {
      this.currentScan = scan
      this.displayDialog({
        color: 'primary',
        title: 'Iniciar Scan',
        type: 'confirmation',
        text: `Voce deseja iniciar o scan <strong>${scan.name}</strong>?`,
        callback: this.attemptStart
      })
    },

    async attemptStart() {
      await this.start(this.currentScan)
      this.currentScan = null
      await this.filter({})

      await this.showSnackbar({
        type: 'success',
        message: 'Scan iniciado com sucesso!'
      })
    },

    async attemptTestConnection(scan, i) {
      this.scans[i].isTestingConnection = true
      this[SET_LIST_ITEMS]([...this.scans])

      try {
        await this.testConnection(scan.id)
      } catch {
        await this.showSnackbar({
          type: 'error',
          message: 'Erro ao testar conexão com o recurso'
        })
      }

      this.scans[i].isTestingConnection = false
      this[SET_LIST_ITEMS]([...this.scans])
    },

    async attemptForceFinish(scan, i) {
      this.displayDialog({
        color: 'danger',
        title: 'Forçar finalização',
        type: 'confirmation',
        text: `Você tem certeza que deseja forçar a finalização do scan <strong>${scan.name}</strong>?`,
        callback: () => this.forceScanFinish(scan, i)
      })
    },

    async forceScanFinish(scan, i) {
      this.scans[i].isForcingFinish = true
      this[SET_LIST_ITEMS]([...this.scans])

      try {
        await this.forceFinish(scan.id)

        await this.showSnackbar({
          type: 'success',
          message: 'Scan finalizado forçado com sucesso'
        })
      } catch {
        await this.showSnackbar({
          type: 'error',
          message: 'Erro ao tentar finalizar forçado'
        })
      }

      this.scans[i].isForcingFinish = false
      this[SET_LIST_ITEMS]([...this.scans])
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ page: this.page })
      if (!isEqual(values, this.$route.query))
        this.$router.push({ query: { ...values } })

      this.loadScans()
    },

    handleRedirect(scan) {
      this.$router.push({
        name: 'ScannedData',
        params: { scanId: scan.id, scanHistoryId: scan.lastScanHistory.id }
      })
    },

    getTotals({ lastScanHistory }) {
      if (!lastScanHistory) {
        return `${0} / ${0} / ${0} / ${0}`
      }

      const { aggregateScanHistoryResults } = lastScanHistory

      if (!aggregateScanHistoryResults) {
        return `${0} / ${0} / ${0} / ${0}`
      }

      const { totalDiscovered, totalErrored, totalProcessed, totalSensitive } =
        aggregateScanHistoryResults

      return `${totalDiscovered} / ${totalProcessed} / ${totalSensitive} / ${totalErrored}`
    },

    getChipColor(status) {
      if (status === 'Finalizado') return `success`
      if (status === 'Iniciado' || status === 'Processando') return `warning`
      if (status === 'Erro') return `danger`
      if (status === 'Criado') return `secondary`

      return 'info'
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({ currentPage: value })
      this.filter()
    }
  }
}
</script>

<template>
  <v-chip small :color="color" :dark="dark">
    <v-icon left small>{{ icon }}</v-icon>
    <BeEllipsis :data="subNum" :tooltip="tooltip + ': ' + value"></BeEllipsis>
  </v-chip>
</template>
<script>
import BeEllipsis from '@/components/Ellipsis'
export default {
  name: 'ChipCount',
  components: {
    BeEllipsis
  },
  props: {
    maxValue: {
      default: () => 999
    },
    tooltip: {
      default: () => 'Sobre'
    },
    icon: {
      default: () => 'mdi-percent-outline'
    },
    value: {
      default: () => 0
    },
    color: {
      default: ''
    },
    dark: {
      default: false
    }
  },
  computed: {
    subNum() {
      if (!this.maxValue) return this.value

      if (this.value >= this.maxValue) return `${this.maxValue}+`

      return this.value
    }
  }
}
</script>

<template>
  <redoc-wrapper
    class="wrapper"
    :spec-or-spec-url="openAPI"
    :options="redocOptions"
  />
</template>

<script>
import RedocWrapper from '@hnluu8/vue-redoc-wrapper'

import openAPI from './openapi.json'

export default {
  name: 'app',
  components: {
    RedocWrapper
  },
  data() {
    openAPI.info.version = this.$store.getters.appVersion
    return {
      openAPI,
      redocOptions: {
        scrollYOffset: 10,
        hideDownloadButton: false
      }
    }
  }
}
</script>
<style scoped>
.wrapper {
  background-color: white;
}
</style>

export const SET_MODEL = 'SET_MODEL'
export const SET_CURRENT_MODEL = 'SET_CURRENT_MODEL'
export const LOADING_MODEL = 'LOADING_MODEL'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_LIST_ITEMS = 'SET_LIST_ITEMS'
export const SET_CURRENT_ERRORS = 'SET_CURRENT_ERRORS'

export default {
  [SET_LIST_ITEMS](state, periodicities) {
    state.periodicities = periodicities
  },

  [SET_PAGINATION](state, data) {
    state.pagination = data
    state.filters.values = {
      ...state.filters.values,
      page: data.currentPage || 1
    }
  },

  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },

  [LOADING_MODEL](state, value = true) {
    state.form.loading = value
  },

  [SET_MODEL](state, payload) {
    state.form.periodicity = payload
  },

  [SET_CURRENT_MODEL](state, id) {
    state.form.periodicityId = id
  },

  [SET_CURRENT_ERRORS](state, payload) {
    state.form.errors = payload
  },

  [SET_FILTER_VALUES](state, values) {
    state.filters.values = values
  }
}

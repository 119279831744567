import APIClient from '@/core/http/apiClient'

export class UserProvider {
  constructor(httpClient) {
    this.basePath = '/private/users'
    this.httpClient = httpClient
  }

  async create(data) {
    return await this.httpClient.call(`${this.basePath}`, data, 'post')
  }

  async update(id, data) {
    return await this.httpClient.call(`${this.basePath}/${id}`, data, 'put')
  }

  async find(id) {
    return await this.httpClient.call(`${this.basePath}/${id}`, {}, 'get')
  }

  async filter(params) {
    return await this.httpClient.call(`${this.basePath}`, params, 'get')
  }

  async delete(params) {
    return await this.httpClient.call(
      `${this.basePath}/${params.id}`,
      params,
      'delete'
    )
  }
}

export default new UserProvider(new APIClient())

import APIClient from '@/core/http/apiClient'

export class DashboardProvider {
  constructor(httpClient) {
    this.basePath = '/private/dashboard'
    this.httpClient = httpClient
  }

  async getCounters() {
    return await this.httpClient.call(this.basePath)
  }

  async getSystemInfo() {
    return await this.httpClient.call(`${this.basePath}/sys`)
  }

  async getDiscoveredCategories(scanHistoryId) {
    return await this.httpClient.call(
      `${this.basePath}/discoveredCategories`,
      { scanHistoryId },
      'GET'
    )
  }
}

export default new DashboardProvider(new APIClient())

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" small @click="showDialog"> Adicionar</v-btn>
      </template>
    </Header>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters" />
          </v-card-title>
          <v-card-text class="pb-0">
            <APITokenCardHeader class="mb-3" />

            <DisplaySkeleton v-if="table.loading" :quantity="4" />
            <APITokenCardList
              v-else
              class="mb-3"
              v-for="(apiToken, i) in apiTokens"
              :key="i"
              :apiToken="apiToken"
              @onDelete="confirmDelete"
              @onEdit="showDialog(apiToken)"
              @onTokenCopy="showCredentials"
              @onRevoke="revokeTokenAct(apiToken)"
            />

            <EmptyList
              :on-click="showDialog"
              v-if="!table.loading && apiTokens && apiTokens.length == 0"
            />

            <Paginator :pagination="pagination" v-model="page" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <APITokenDialog v-model="dialogOptions" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES
} from '@/store/modules/apiToken/mutations'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'
import APITokenCardHeader from './components/APITokenCardHeader.vue'
import APITokenCardList from './components/APITokenCardList.vue'
import EmptyList from '@/components/EmptyList'
import { isEqual } from 'lodash'
import APITokenDialog from './components/APITokenDialog.vue'

export default {
  name: 'APITokenIndex',
  components: {
    APITokenCardList,
    DisplaySkeleton,
    APITokenCardHeader,
    Paginator,
    BeFilter,
    Header,
    EmptyList,
    APITokenDialog
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        detectorId: null
      },
      page: 1,
      panel: true
    }
  },

  computed: {
    ...mapGetters('apiTokenModule', [
      'apiTokens',
      'filters',
      'table',
      'pagination'
    ])
  },

  methods: {
    ...mapActions('apiTokenModule', ['filter', 'delete', 'start']),
    ...mapMutations('apiTokenModule', [SET_PAGINATION, SET_FILTER_VALUES]),
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar',
      displayDialog: 'dialogModule/displayDialog',
      revokeToken: 'apiTokenModule/revokeToken'
    }),

    showDialog({ id: apiTokenId }, type = 'form') {
      this.dialogOptions = {
        show: true,
        apiTokenId,
        type
      }
    },

    showCredentials(apiToken) {
      if (apiToken.token) {
        this.displayDialog({
          color: 'info',
          title: 'Operação não Permitida',
          type: 'info',
          text: `O token já foi copiado uma vez, não é possível novamente.`,
          callback: this.attemptDelete
        })
      } else {
        this.showDialog(apiToken, 'token')
      }
    },

    async loadAPITokens() {
      await this.filter()
    },

    confirmDelete(apiToken) {
      this.currentAPIToken = apiToken
      this.displayDialog({
        color: 'danger',
        title: 'Deletar API Token',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o token <strong>${apiToken.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'API Token deletado com sucesso!'
      }

      await this.delete(this.currentAPIToken).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o API Token, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentAPIToken = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ currentPage: this.page })
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({ query: { ...values } })
      }
      this.loadAPITokens()
    },

    async revokeTokenAct(token) {
      try {
        await this.revokeToken(token.id)
        this.showSnackbar({
          type: 'success',
          message: 'Token revogado com sucesso.'
        })
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Falha ao revogar token.'
        })
      }
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({ page: value })
      this.filter()
    }
  }
}
</script>

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn
          color="primary"
          width="100"
          small
          @click="attemptCreate"
          :loading="loading"
          >Salvar</v-btn
        >
      </template>
    </Header>
    <CreateForm ref="scanForm" />
  </div>
</template>
<script>
import Header from '@/components/Header'
import CreateForm from './Form'

export default {
  name: 'CreateScan',
  components: {
    Header,
    CreateForm
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    async attemptCreate() {
      this.loading = true
      try {
        await this.$refs.scanForm.attemptSave()
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

import session from '@/core/utils/session'
import { SET_NOTIFICATIONS } from '@/store/modules/notification/mutations'

export default {
  notify({ commit }, notification) {
    let notifications = session.get('notifications')

    if (Array.isArray(notifications)) {
      notifications.unshift(notification)
    } else {
      notifications = []
      notifications.push(notification)
    }

    session.set('notifications', notifications)

    commit(SET_NOTIFICATIONS, notifications)
  },

  clear({ commit }) {
    session.set('notifications', [])
    commit(SET_NOTIFICATIONS, [])
  }
}

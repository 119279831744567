<template src="./Centralized.html"></template>
<script>
export default {
  name: 'CentralizedView'
}
</script>
<style scoped>
.vh-100 {
  height: 100vh;
}
</style>

export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.pagination
  },
  loading(state) {
    return state.form.loading
  },
  detectors(state) {
    return state.detectors
  },
  detector(state) {
    return state.form.detector
  },
  readyToUpdate(state) {
    return state.form.detectorId != null
  },
  errors(state) {
    return state.form.errors
  },
  filters(state) {
    return state.filters
  }
}

<template>
  <v-card class="card-custom">
    <v-card-text class="pa-0 wrap-content">
      <div v-if="leftDiv" :class="`wrap-status ${leftDivColor}`">
        <slot name="leftColoredDiv" />
      </div>
      <div
        :style="`width: calc(100% - ${coloredDivSize});position: relative`"
        class="card-content d-flex align-self-stretch flex-grow-1 flex-shrink-0 be-card-main-content-row ma-0 pa-0 py-2 ps-3 d-flex align-md-center"
      >
        <slot name="content"></slot>
        <v-progress-linear
          v-if="isProcessing"
          style="position: absolute; bottom: 0; left: -4px"
          indeterminate
          :color="leftDivColor"
        />
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'BeListCardItem',

  props: {
    leftDiv: {
      type: Boolean,
      default: true
    },

    leftDivColor: {
      type: String,
      default: 'transparent'
    },

    coloredDivSize: {
      type: String,
      default: '40px'
    },

    isProcessing: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="css">
.card-custom {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.wrap-status {
  padding: 20px;
  width: 30px;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap-content {
  min-height: 50px;
  display: flex;
}
</style>

import detectorProvider from '@/core/api/providers/detectors'
import {
  SET_CURRENT_ERRORS,
  SET_PAGINATION,
  SET_TABLE_LOADING,
  LOADING_MODEL,
  SET_LIST_ITEMS,
  SET_MODEL
} from '@/store/modules/detectors/mutations'
export default {
  async filter({ commit, state }) {
    commit(SET_TABLE_LOADING, true)

    const data = await detectorProvider.filter({
      ...state.filters.values
    })

    if (data) {
      commit(SET_LIST_ITEMS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async delete({ commit }, detector) {
    commit(SET_TABLE_LOADING, true)

    const res = await detectorProvider.delete(detector)

    if (res) commit(SET_TABLE_LOADING, false)
  },

  async find({ commit }, id) {
    commit(LOADING_MODEL, true)
    const data = await detectorProvider.find(id)

    if (data) {
      commit(SET_MODEL, { ...data })
      commit(LOADING_MODEL, false)
      return data
    }
  },

  async findByOption(_, name) {
    const data = await detectorProvider.findByOption({ name })
    if (data) {
      return data
    }
  },
  async getDefaults() {
    const data = await detectorProvider.getDefaults()
    if (data) {
      return data
    }
  },

  async update({ state, commit, dispatch }) {
    try {
      commit(LOADING_MODEL, true)

      const res = await detectorProvider.update(
        state.form.detector.id,
        state.form.detector
      )
      commit(LOADING_MODEL, false)

      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Detector atualizada com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')

      return res
    } catch (error) {
      commit(LOADING_MODEL, false)
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  },

  async create({ state, dispatch, commit }) {
    try {
      commit(LOADING_MODEL, true)
      const res = await detectorProvider.create(state.form.detector)
      commit(SET_MODEL, { ...res })
      commit(LOADING_MODEL, false)

      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Detector criado com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')

      return res
    } catch (error) {
      commit(LOADING_MODEL, false)
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  }
}

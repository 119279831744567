<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip :small="small" v-bind="attrs" v-on="on" :color="color">
          {{ content }}
        </v-chip>
      </template>
      <span>{{ tooltip ? tooltip : content }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'BeIcon',
  props: {
    content: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    tooltip: {
      type: String
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

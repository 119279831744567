import { render, staticRenderFns } from "./ScanHistoryListCardItem.vue?vue&type=template&id=4537e968&scoped=true&"
import script from "./ScanHistoryListCardItem.vue?vue&type=script&lang=js&"
export * from "./ScanHistoryListCardItem.vue?vue&type=script&lang=js&"
import style0 from "./ScanHistoryListCardItem.vue?vue&type=style&index=0&id=4537e968&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4537e968",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VIcon,VRow})

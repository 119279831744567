<template>
  <BeListCardItem leftDivColor="success">
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="6" class="ma-0 pa-0">
            <BeEllipsis :data="user.name" />
            <strong>
              <BeEllipsis :data="user.username" />
            </strong>
          </v-col>
          <v-col cols="12" md="6" class="ma-0 pa-0">
            <BeTooltip :tooltip="`Criado em ${formatDate(user.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank </v-icon>
              <span class="font-weight-bold">
                {{ user.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip :tooltip="`Atualizado em ${formatDate(user.updatedAt)}`">
              <v-icon size="20"> mdi-calendar-text </v-icon>
              <span class="font-weight-bold">
                {{ user.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn icon color="primary" @click="$emit('onEdit', user)">
            <BeIcon
              icon="mdi-pencil"
              tooltip="Editar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>

          <v-btn icon color="primary" @click="$emit('onDelete', user)">
            <BeIcon
              icon="mdi-delete"
              tooltip="Deletar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

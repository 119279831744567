<template>
  <div>
    <v-form ref="APITokenForm" @submit.prevent="attemptSave">
      <v-row dense>
        <v-col cols="12" md="12" sm="12">
          <BeTextField
            type="text"
            v-model="apiToken.name"
            outlined
            filled
            :error-messages="errors.name"
            :rules="rules.name"
            label="Nome *"
            :loading="loading"
          />
        </v-col>

        <v-col cols="12" md="12" sm="12">
          <BeTextField
            type="text"
            v-model="apiToken.description"
            outlined
            filled
            :rules="rules.description"
            :error-messages="errors.description"
            label="Descrição"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Vue from 'vue'
import BeTextField from '@/components/TextField'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import {
  SET_MODEL,
  SET_CURRENT_MODEL,
  SET_CURRENT_ERRORS
} from '@/store/modules/apiToken/mutations'
import validations from '@/core/utils/validations'

export default {
  name: 'APITokenForm',
  components: {
    BeTextField
  },

  data() {
    return {
      resourceOrigin: 0,
      resourceTarget: 0,
      apiTokenId: null,
      loading: false,
      apiToken: {
        name: null,
        description: null
      }
    }
  },

  computed: {
    ...mapGetters({
      errors: 'apiTokenModule/errors',
      readyToUpdate: 'apiTokenModule/readyToUpdate'
    }),
    rules() {
      return {
        name: [(v) => validations.required(v)],
        description: []
      }
    }
  },

  async created() {
    Vue.nextTick(async () => {
      this.$refs.APITokenForm.reset()
      const { id: apiTokenId } = this.$route.params
      if (apiTokenId) {
        this.apiTokenId = apiTokenId
        this[SET_CURRENT_MODEL](apiTokenId)
        this[SET_CURRENT_ERRORS]([])

        this.loading = true

        try {
          const apiToken = await this.find(apiTokenId)

          if (apiToken) this.apiToken = { ...apiToken }
        } catch {
          //
        } finally {
          this.loading = false
        }
      }
    })
  },

  methods: {
    ...mapMutations('apiTokenModule', {
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    }),
    ...mapActions({
      create: 'apiTokenModule/create',
      update: 'apiTokenModule/update',
      find: 'apiTokenModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    prepareToCreate() {
      this.$refs.APITokenForm.reset()
      this.apiToken = {
        name: null,
        description: null,
      }
      this[SET_CURRENT_MODEL](null)
      this[SET_CURRENT_ERRORS]([])
    },

    async findToken(token) {
      try {
        const { apiTokenId } = token
        this.apiToken = await this.find(apiTokenId)
        this[SET_CURRENT_MODEL](apiTokenId)
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar o token.'
        })
      }
    },

    async attemptSave() {
      this.loading = true
      this.$emit('loading', true)
      if (this.$refs.APITokenForm.validate()) {
        try {
          this[SET_MODEL](this.apiToken)
          if (this.readyToUpdate) await this.update()
          else await this.create()

          return true
        } catch (error) {
          this.showSnackbar({
            type: 'danger',
            message: 'Erro ao criar API Token.'
          })
        } finally {
          this.loading = false
          this.$emit('loading', false)
        }
      }

      this.loading = false
      this.$emit('loading', false)
      return false
    }
  },

  watch: {}
}
</script>

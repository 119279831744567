<template>
  <v-form ref="agentForm">
    <v-row>
      <v-col cols="12">
        <BeTextField
          label="Nome *"
          v-model="agent.name"
          filled
          outlined
          :rules="rules.name"
          :error-messages="errors.name"
          :disabled="loading"
        />
      </v-col>

      <v-col cols="12" class="pt-0">
        <BeCheckbox
          label="Esta ativo?"
          v-model="agent.isActive"
          :error-messages="errors.isActive"
          :loading="loading"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BeTextField from '@/components/TextField'
import BeCheckbox from '@/components/Checkbox'

import validations from '@/core/utils/validations'
import { INIT_AGENT_FORM } from '@/store/modules/agents/state'

export default {
  name: 'PeriodicityForm',

  components: {
    BeTextField,
    BeCheckbox
  },
  data() {
    return {
      agent: { ...INIT_AGENT_FORM },
      items: [
        {
          text: 'Esperando Comando',
          value: 0
        },
        {
          text: 'Enviar Arquivos',
          value: 1
        }
      ]
    }
  },

  computed: {
    ...mapGetters('agentModule', [
      'errors',
      'readyToUpdate',
      'loading',
      'saving'
    ]),

    rules() {
      return {
        name: [(v) => validations.required(v)]
      }
    }
  },

  methods: {
    ...mapActions({
      find: 'agentModule/find',
      update: 'agentModule/update',
      create: 'agentModule/create',
      setCurrentModelId: 'agentModule/setCurrentModelId',
      setCurrentErrors: 'agentModule/setCurrentErrors',
      setCurrentModel: 'agentModule/setCurrentModel',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),

    prepareToCreate() {
      // this.$refs.agentForm.reset();

      this.agent = { ...INIT_AGENT_FORM }

      this.setCurrentErrors([])
      this.setCurrentModelId(null)
    },

    async attemptSave() {
      if (this.$refs.agentForm.validate()) {
        this.setCurrentModel(this.agent)

        if (this.readyToUpdate) await this.update()
        else await this.create()
      } else {
        throw Error()
      }

      this.prepareToCreate()
    },

    async findAgent(agent) {
      try {
        const { agentId } = agent

        this.setCurrentModelId(agentId)

        const data = await this.find(agentId)
        this.agent = { ...data }
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar o agente.'
        })
      }
    }
  }
}
</script>

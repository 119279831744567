export default {
  loadingCategoryCounts(state) {
    return state.loadingCategoryCounts
  },

  categoryCounts(state) {
    return state.categoryCounts
  },

  systemInfo(state) {
    return state.systemInfo
  },

  loadingSystemInfo(state) {
    return state.loadingSystemInfo
  },

  counters(state) {
    return state.counters
  },
  isLoadingCounters(state) {
    return state.isLoadingCounters
  },
  drawer(state) {
    return state.drawer
  },
  categories(state) {
    return state.categories.reduce((items, current) => {
      const { name, total, slug } = current

      const totals = current.detectors
        .filter((detector) => detector.total > 0)
        .map((detector) => detector.total)

      const labels = current.detectors
        .filter((detector) => detector.total > 0)
        .map((detector, index) => `${detector.name} (${totals[index]})`)

      items.push({
        slug,
        name,
        total,
        chartData: {
          labels,
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
              data: totals
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right',
              align: 'middle'
            }
          }
        }
      })

      return items
    }, [])
  },
  report(state) {
    const labels = state.categories
      .filter((category) => category.total > 0)
      .map((category) => `${category.name} (${category.total})`)

    const slugs = state.categories
      .filter((category) => category.total > 0)
      .map((category) => category.slug)

    const backgroundColor = state.categories
      .filter((category) => category.total > 0)
      .map((category) => {
        switch (category.slug) {
          case 'sensitive':
            return '#f44336'
          case 'registration':
            return '#03A9F4'
          case 'financial':
            return '#FF5722'
          case 'behavioral':
            return '#4527A0'
          default:
            return '#17a2b8'
        }
      })

    const totals = state.categories
      .filter((category) => category.total > 0)
      .map((category) => category.total)

    const header = {
      labels,
      chartData: {
        labels,
        slugs,
        datasets: [
          {
            backgroundColor,
            data: totals
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }

    const categories = state.categories
      .reduce((items, current) => {
        const { name, total, slug } = current

        const totals = current.detectors
          .filter((detector) => detector.total > 0)
          .map((detector) => detector.total)

        const labels = current.detectors
          .filter((detector) => detector.total > 0)
          .map((detector, index) => `${detector.name} (${totals[index]})`)

        const slugs = current.detectors
          .filter((detector) => detector.total > 0)
          .map((detector) => detector.slug)

        items.push({
          slug,
          name,
          total,
          chartData: {
            labels,
            slugs,
            datasets: [
              {
                backgroundColor: [
                  '#41B883',
                  '#E46651',
                  '#00D8FF',
                  '#DD1B16',
                  '#FFEE58',
                  '#00897B',
                  '#8E24AA'
                ],
                data: totals
              }
            ]
          },
          chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              }
            }
          }
        })

        return items
      }, [])
      .filter((item) => item.total > 0)

    return { header, categories }
  }
}

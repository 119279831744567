import UserIndex from '@/views/users/Index'
import CreateUser from '@/views/users/Create'
import EditUser from '@/views/users/Edit'

export default [
  {
    name: 'UserIndex',
    path: '/users',
    component: UserIndex,
    meta: {
      title: 'Usuários',
      breadCrumb: [
        {
          to: { name: 'UserIndex' },
          text: 'Usuários'
        }
      ]
    }
  },
  {
    name: 'CreateUser',
    path: '/users/create',
    component: CreateUser,
    meta: {
      title: 'Novo Usuário',
      breadCrumb: [
        {
          to: { name: 'UserIndex' },
          text: 'Usuários'
        },
        {
          to: { name: 'CreateUser' },
          text: 'Novo Usuário'
        }
      ]
    }
  },
  {
    name: 'EditUser',
    path: '/users/:id',
    component: EditUser,
    meta: {
      title: 'Editar Usuário',
      breadCrumb: [
        {
          to: { name: 'UserIndex' },
          text: 'Usuários'
        },
        {
          to: { name: 'EditUser' },
          text: 'Editar Usuário'
        }
      ]
    }
  }
]

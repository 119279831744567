export default {
  scannedData: [],
  scans: [],
  detectors: [],
  categories: [],
  table: {
    loading: false
  },
  loadingInfo: false,
  pagination: {
    currentPage: 1,
    perPage: 50,
    total: 0
  }
}

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/lib/locale/pt'

import icons from '../assets/icons'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  icons,
  theme: {
    themes: {
      light: {
        primary: '#17a2b8',
        secondary: '#b0bec5',
        danger: '#f44336',
        warning: '#ff9800',
        anchor: '#8c9eff',
        appbar: '#34495e',
        sidebar: '#34495e'
      },
      dark: {
        primary: '#17a2b8',
        secondary: '#b0bec5',
        danger: '#f44336',
        warning: '#ff9800',
        anchor: '#8c9eff'
      }
    }
  }
})

export default vuetify

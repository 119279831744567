import APITokensIndex from '@/views/apiTokens/Index'

export default [
  {
    name: 'APITokensIndex',
    path: '/apiTokens',
    component: APITokensIndex,
    meta: {
      title: 'API Tokens',
      breadCrumb: [
        {
          to: { name: 'APITokensIndex' },
          text: 'API Tokens'
        }
      ]
    }
  },
]

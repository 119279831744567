<template>
  <v-dialog width="1000" v-model="model">
    <v-card>
      <v-toolbar color="primary" dark elevation="0">
        <v-toolbar-title class="text--white">{{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="model = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="panel pt-5">
        <PeriodicityForm ref="periodicityForm" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="flex">
        <v-spacer></v-spacer>
        <v-btn width="100" @click="model = false">Fechar</v-btn>

        <v-btn
          color="primary"
          :disabled="loading"
          :loading="loading"
          width="100"
          @click="attemptSave"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PeriodicityForm from '@/components/PeriodicityForm'
import Vue from 'vue'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_CURRENT_MODEL
} from '@/store/modules/periodicities/mutations'
import validations from '@/core/utils/validations'

export default {
  name: 'PeriodicityDialog',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    PeriodicityForm
  },

  computed: {
    ...mapGetters('periodicityModule', [
      'scans',
      'filters',
      'table',
      'pagination',
      'loading',
      'readyToUpdate',
      'errors',
      'periodicity'
    ]),

    rules() {
      return {
        name: [(v) => validations.required(v)]
      }
    },

    model: {
      get() {
        const { show } = this.value

        if (show) return show
        return false
      },
      set(value) {
        const options = { ...this.value }
        options.show = value
        this.$emit('input', options)
      }
    },

    dialogTitle() {
      if (this.readyToUpdate) {
        return 'Editar Periodicidade'
      }

      return 'Nova Periodicidade'
    }
  },

  methods: {
    ...mapActions({
      create: 'periodicityModule/create',
      update: 'periodicityModule/update',
      find: 'periodicityModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('periodicityModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL
    ]),

    prepareToCreate() {
      this[SET_CURRENT_MODEL](null)
    },

    async attemptSave() {
      await this.$refs.periodicityForm.attemptSave()
      if (!this.errors.length > 0) {
        this.$emit('setPeriodicy', this.periodicity)
      }
      this.model = false
    }
  },

  watch: {
    value: {
      handler(value) {
        Vue.nextTick(() => {
          const { periodicityId, show } = value

          if (!show) return

          if (periodicityId) {
            this.$refs.periodicityForm.findPeriodicity(value)
          } else {
            this.$refs.periodicityForm.prepareToCreate()
          }
        })
      },
      deep: true
    }
  }
}
</script>

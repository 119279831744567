import APITokenProvider from '@/core/api/providers/apiToken'
import { SET_CURRENT_ERRORS } from '@/store/modules/apiToken/mutations'
import {
  LOADING_APITOKEN,
  SET_PAGINATION,
  SET_TABLE_LOADING,
  SET_MODEL,
  SET_APITOKENS
} from '@/store/modules/apiToken/mutations'

export default {
  async filter({ commit, state }) {
    commit('SET_TABLE_LOADING', true)

    const { page = 1 } = state.table.pagination

    const data = await APITokenProvider.filter({
      ...state.filters.values,
      page
    })

    if (data) {
      commit(SET_APITOKENS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async delete({ commit }, apiToken) {
    commit(SET_TABLE_LOADING, true)

    const res = await APITokenProvider.delete(apiToken)
    if (res) {
      commit(SET_TABLE_LOADING, false)
    }
  },

  async find({ commit }, id) {
    commit(LOADING_APITOKEN, true)
    const data = await APITokenProvider.find(id)

    if (data) {
      commit(SET_MODEL, data)
      commit(LOADING_APITOKEN, false)
      return data
    }
  },

  async update({ state, dispatch, commit }) {

    try {
      const res = await APITokenProvider.update(state.form.apiTokenId, state.form.apiToken)
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Registro atualizado com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  },

  async create({ state, commit, dispatch }) {
    try {
      const res = await APITokenProvider.create(state.form.apiToken)

      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'API Token criado com sucesso.'
        },
        { root: true }
      )
      dispatch('filter')
      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  },

  async generateToken({ commit, dispatch }, id) {
    commit(LOADING_APITOKEN, true)
    const res = await APITokenProvider.generateToken(id)

    dispatch('filter')

    if (res) {
      const data = res
      commit(SET_MODEL, data)
      commit(LOADING_APITOKEN, false)
      return data
    }
  },

  async revokeToken({ commit, dispatch }, id) {
    commit(LOADING_APITOKEN, true)
    const res = await APITokenProvider.revokeToken(id)

    dispatch('filter')

    if (res) {
      const data = res
      commit(SET_MODEL, data)
      commit(LOADING_APITOKEN, false)
      return data
    }
  }
}

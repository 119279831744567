export default {
  reports: [],
  downloading: false,
  generating: false,
  pending: [],
  loadingPending: false,
  filters: {
    fields: [
      {
        name: 'filename',
        label: 'Nome do Arquivo',
        type: 'text'
      },
      {
        name: 'path',
        label: 'Caminho',
        type: 'text'
      }
    ],
    values: {}
  },
  table: {
    loading: false,
    pagination: {}
  }
}

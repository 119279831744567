<template>
  <v-app-bar id="app-bar" absolute app color="transparent" dark flat height="60">
    <div class="w-100 d-flex align-center justify-space-between">
      <v-btn class="mr-3" fab small text @click="toggleDrawer">
        <v-icon> mdi-menu</v-icon>
      </v-btn>

      <GlobalSearch />

      <div class="d-flex">
        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
              <v-badge color="red" overlap>
                <template v-slot:badge>
                  <span>{{ notifications.length }}</span>
                </template>

                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-card width="250" max-height="500">
            <v-card-actions>
              <div class="text-md pa-2">Notificações</div>
            </v-card-actions>

            <v-divider></v-divider>

            <v-card-text class="pa-0">
              <template v-if="notifications.length > 0">
                <v-list :tile="false" nav>
                  <template v-for="(notification, i) in notifications">
                    <template v-if="notification.type == NotificationTypes.REPORT_NOTIFICATION">
                      <v-list-item :key="`item-${i}`" link>
                        <v-list-item-content>
                          <v-list-item-title class="d-flex align-center">
                            <BeEllipsis :data="notification.title" />

                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <BeEllipsis :data="notification.description" />
                          </v-list-item-subtitle>

                          <v-list-item-subtitle>
                            <small>{{ notification.date }}</small>
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-btn class="ml-auto fixed float-left" color="primary" icon
                          @click.stop="handleNotification(notification)">
                          <v-icon>mdi-download-outline</v-icon>
                        </v-btn>
                      </v-list-item>
                    </template>
                  </template>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn text block color="primary" class="mr-1" @click.stop="clearNotications">
                    Limpar todas <v-icon>mdi-close-octagon-outline</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-else>
                <v-list :tile="false" nav>
                  <div>
                    <v-list-item disabled>
                      <v-list-item-content>
                        <v-list-item-title class="d-flex justify-center align-center">
                          <BeEllipsis data="Nenhuma notificação ativa." />
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list>
              </template>
            </v-card-text>



          </v-card>
        </v-menu>


        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
          <template v-slot:activator="{ on }">
            <v-btn class="ml-2" min-width="0" text v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" nav width="200">
            <v-list-item @click="attemptLogout">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TOGGLE_DRAWER } from '@/store/modules/dashboard/mutations'
import GlobalSearch from '@/components/GlobalSearch'
import BeEllipsis from '@/components/Ellipsis'
import store from '@/store'
import { NotificationTypes } from '@/store/modules/notification/index'

export default {
  name: 'AppBar',
  data() {
    return {
      NotificationTypes
    }
  },
  components: { GlobalSearch, BeEllipsis },
  computed: {
    ...mapGetters({
      notifications: 'notificationModule/notifications'
    })
  },
  methods: {
    ...mapActions({
      logout: 'authModule/logout',
      showSnackbar: 'snackbarModule/showSnackbar',
      clearNotications: 'notificationModule/clear'
    }),
    ...mapMutations('dashboardModule', { TOGGLE_DRAWER }),

    handleNotification(notification) {
      const { data } = notification

      switch (notification.type) {
        case NotificationTypes.REPORT_NOTIFICATION:
          store.dispatch('reportModule/download', { id: data?.id, fileName: data?.fileName })
          break
      }
    },

    toggleDrawer() {
      this[TOGGLE_DRAWER]()
    },
    async attemptLogout() {
      try {
        await this.logout()

        this.showSnackbar({
          message: 'Logout efetuado com sucesso.'
        })
      } catch (error) {
        this.showSnackbar({
          message: 'Ocorreu um erro inesperado.'
        })
      } finally {
        this.$router.push({ name: 'Login' })
      }
    }
  }
}
</script>

<template>
  <v-navigation-drawer
    class="py-0"
    app
    v-model="show"
    width="260"
    color="sidebar"
    dark
  >
    <BeLogo />

    <v-list class="px-5 py-0" nav expand dense>
      <v-divider></v-divider>
    </v-list>

    <v-list class="px-4 py-2" nav expand dense>
      <template v-for="item in items">
        <template v-if="item.items">
          <v-list-group
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              link
              :to="child.route"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            link
            :href="item.href"
            :to="item.route"
            :key="item.title"
            :target="item.target"
          >
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-list>

    <div
      style="
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: fixed;
        bottom: 10px;
      "
    >
      <small class="text-center">Versão {{ appVersion() }}</small>
      <v-btn color="primary" @click="downloadLogs">
        <v-icon> mdi-download</v-icon>
        Download Logs
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>
<script>
import BeLogo from '@/components/BeLogo'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { TOGGLE_DRAWER } from '@/store/modules/dashboard/mutations'

export default {
  name: 'SideBar',
  components: {
    BeLogo
  },

  data() {
    return {
      items: [
        {
          action: 'mdi-home',
          title: 'Home',
          route: { name: 'Home' },
          disabled: true
        },
        {
          action: 'mdi-cached',
          route: { name: 'ScanIndex' },
          title: 'Scans'
        },
        {
          action: 'mdi-file-search-outline',
          route: { name: 'DetectorIndex' },
          title: 'Detectores'
        },
        {
          action: 'mdi-calendar-clock',
          route: { name: 'PeriodicityIndex' },
          title: 'Periodicidades'
        },
        {
          action: 'mdi-face-agent',
          route: { name: 'AgentIndex' },
          title: 'Agentes'
        },
        {
          action: 'mdi-account-multiple',
          route: { name: 'UserIndex' },
          title: 'Usuários'
        },
        {
          action: 'mdi-file-chart-outline',
          route: { name: 'ReportIndex' },
          title: 'Relatórios'
        },
        {
          action: 'mdi-api',
          route: { name: 'APITokensIndex' },
          title: 'API Tokens'
        },
        {
          action: 'mdi-cog',
          route: { name: 'SettingsIndex' },
          title: 'Configurações'
        },
        {
          action: 'mdi-file-document',
          href: '/documentation/',
          target: '__blank',
          title: 'Documentação'
        }
      ]
    }
  },

  methods: {
    ...mapActions({
      downloadLogs: 'logModule/download'
    }),
    ...mapMutations('dashboardModule', {
      TOGGLE_DRAWER
    }),
    appVersion() {
      return this.$store.getters.appVersion
    }
  },

  computed: {
    ...mapGetters({
      drawer: 'dashboardModule/drawer'
    }),
    show: {
      get() {
        return this.drawer
      },
      set(value) {
        this[TOGGLE_DRAWER](value)
      }
    }
  }
}
</script>

import Centralized from '@/views/layout/Centralized'
import Login from '@/views/auth/Login'
import PasswordRequest from '@/views/auth/password/Request'
import PasswordReset from '@/views/auth/password/Reset'

export default {
  name: 'Auth',
  path: '/',
  redirect: '/login',
  component: Centralized,
  meta: {
    requiresAuth: false
  },
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Login'
      }
    },
    {
      name: 'ForgotPassword',
      path: '/password/forgot',
      component: PasswordRequest,
      meta: {
        title: 'Resetar senha'
      }
    },
    {
      name: 'ResetPassword',
      path: '/password/reset',
      component: PasswordReset,
      meta: {
        title: 'Redefinir Senha'
      }
    }
  ]
}

<template>
  <div>
    <Header>
      <template #actions>
        <v-btn color="primary" small @click="showDialog"> Adicionar</v-btn>
      </template>
    </Header>

    <v-row>
      <v-col>
        <v-card class="panel">
          <v-card-title>
            <BeFilter :fields="filters.fields" @search="setFilters"/>
          </v-card-title>
          <v-card-text class="pb-0">
            <UserCardHeader class="mb-3"/>

            <DisplaySkeleton v-if="table.loading" :quantity="4"/>
            <UserCardListVue
                v-else
                class="mb-3"
                v-for="(user, i) in users"
                :key="i"
                :user="user"
                @onDelete="confirmDelete"
                @onEdit="showDialog(user)"
            />

            <EmptyList
                :on-click="showDialog"
                v-if="!table.loading && users && users.length == 0"
            />

            <Paginator :pagination="pagination" v-model="page"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <UserDialog v-model="dialogOptions"/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
  SET_PAGINATION,
  SET_FILTER_VALUES
} from '@/store/modules/user/mutations'

import DisplaySkeleton from '@/components/DisplaySkeleton'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import Header from '@/components/Header'
import UserCardHeader from './components/UserCardHeader.vue'
import UserCardListVue from './components/UserCardList.vue'
import EmptyList from '@/components/EmptyList'
import {isEqual} from 'lodash'
import UserDialog from './components/UserDialog.vue'

export default {
  name: 'UserIndex',
  components: {
    UserCardListVue,
    DisplaySkeleton,
    UserCardHeader,
    Paginator,
    BeFilter,
    Header,
    EmptyList,
    UserDialog
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        detectorId: null
      },
      page: 1,
      panel: true
    }
  },

  computed: {
    ...mapGetters('userModule', ['users', 'filters', 'table', 'pagination'])
  },

  methods: {
    ...mapActions('userModule', ['filter', 'delete', 'start']),
    ...mapMutations('userModule', [SET_PAGINATION, SET_FILTER_VALUES]),
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar',
      displayDialog: 'dialogModule/displayDialog'
    }),

    showDialog({id: userId}) {
      this.dialogOptions = {
        show: true,
        userId
      }
    },

    redirectToCreate() {
      this.$router.push({name: 'CreateUser'})
    },

    redirectToEdit(item) {
      this.$router.push({name: 'EditUser', params: {id: item.id}})
    },

    async loadUsers() {
      await this.filter()
    },

    confirmDelete(user) {
      this.currentUser = user
      this.displayDialog({
        color: 'danger',
        title: 'Deletar usuário',
        type: 'confirmation',
        text: `Você tem certeza que deseja deletar o usuário <strong>${user.name}</strong>?`,
        callback: this.attemptDelete
      })
    },

    async attemptDelete() {
      let message = {
        type: 'success',
        message: 'Usuario deletado com sucesso!'
      }

      await this.delete(this.currentUser).catch(() => {
        message = {
          type: 'error',
          message: 'Erro ao deletar o usuario, tente novamente mais tarde!'
        }
      })

      this.showSnackbar(message)

      this.currentUser = null

      await this.filter({})
    },

    setFilters(values) {
      this.page = +values.page || 1
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({currentPage: this.page})
      if (!isEqual(values, this.$route.query)) {
        this.$router.push({query: {...values}})
      }
      this.loadUsers()
    }
  },

  watch: {
    page(value) {
      this[SET_PAGINATION]({page: value})
      this.filter()
    }
  }
}
</script>

import DetectorIndex from '@/views/detectors/Index'

export default [
  {
    name: 'DetectorIndex',
    path: '/detectors',
    component: DetectorIndex,
    meta: {
      title: 'Detectores',
      breadCrumb: [
        {
          to: { name: 'DetectorIndex' },
          text: 'Detectores'
        }
      ]
    }
  }
]

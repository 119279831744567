import Home from '@/views/home'

export default {
  name: 'Home',
  path: '/home',
  component: Home,
  meta: {
    title: 'Home',
    breadCrumb: [
      {
        to: { name: 'Home' },
        text: 'Home'
      }
    ]
  }
}

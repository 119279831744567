<template>
  <BeListCardItem :leftDivColor="agent.isActive ? 'success' : 'grey darken-1'">
    <template v-slot:leftColoredDiv>
      <v-btn
        v-if="!agent.hasCopiedToken"
        icon
        @click="$emit('onTokenCopy', agent)"
      >
        <BeIcon
          icon="mdi-eye"
          tooltip="Copiar token"
          iconColor="white"
          iconSize="25"
        />
      </v-btn>
      <v-btn v-else icon>
        <BeIcon
          icon="mdi-eye-off"
          tooltip="Token já copiado"
          iconColor="white"
          iconSize="25"
        />
      </v-btn>
    </template>
    <template v-slot:content>
      <v-col cols="10" class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeEllipsis :data="agent.name" />
          </v-col>
          <v-col cols="12" md="3" class="d-flex align-center">
            <BeTooltip
              v-if="agent.lastPingAt"
              :tooltip="`Última comunicação em ${formatDate(agent.lastPingAt)}`"
            >
              <span class="font-weight-bold">
                {{ agent.lastPingAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip
              v-else
              :tooltip="`Nenhuma comunicação realizada com o agente`"
            >
              <span class="font-weight-bold"> Aguardando validação </span>
            </BeTooltip>
          </v-col>

          <v-col cols="12" md="3">
            <BeEllipsis :data="agent.statusDescription" />
          </v-col>

          <v-col cols="12" md="3" class="ma-0 pa-0">
            <BeTooltip :tooltip="`Criado em ${formatDate(agent.createdAt)}`">
              <v-icon size="20"> mdi-calendar-blank </v-icon>
              <span class="font-weight-bold">
                {{ agent.createdAt | formatDate }}
              </span>
            </BeTooltip>

            <BeTooltip
              :tooltip="`Atualizado em ${formatDate(agent.updatedAt)}`"
            >
              <v-icon size="20"> mdi-calendar-text </v-icon>
              <span class="font-weight-bold">
                {{ agent.updatedAt | formatDate }}
              </span>
            </BeTooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" class="ma-0 pa-0">
        <div
          class="d-flex flex-column flex-md-row justify-start mr-1 mr-sm-2 align-md-center pe-lg-10"
        >
          <v-btn icon color="primary" @click="$emit('onEdit', agent)">
            <BeIcon
              icon="mdi-pencil"
              tooltip="Editar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>

          <v-btn icon color="primary" @click="$emit('onDelete', agent)">
            <BeIcon
              icon="mdi-delete"
              tooltip="Deletar"
              iconColor="primary"
              iconSize="25"
            />
          </v-btn>
        </div>
      </v-col>
    </template>
  </BeListCardItem>
</template>
<script>
import BeListCardItem from '@/components/BeListCardItem'
import BeEllipsis from '@/components/Ellipsis'
import BeTooltip from '@/components/BeTooltip'
import BeIcon from '@/components/BeIcon'

export default {
  components: {
    BeListCardItem,
    BeEllipsis,
    BeTooltip,
    BeIcon
  },

  props: {
    agent: {
      type: Object,
      required: true
    }
  }
}
</script>

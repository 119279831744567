import agentProvider from '@/core/api/providers/agent'

import {
  SET_CURRENT_ERRORS,
  SET_TABLE_LOADING,
  SET_CURRENT_MODEL,
  SET_FILTER_VALUES,
  SET_LIST_ITEMS,
  SET_PAGINATION,
  LOADING_MODEL,
  SAVING_MODEL,
  SET_MODEL
} from '../agents/mutations'

export default {
  async filter({ commit, state }) {
    commit(SET_TABLE_LOADING, true)

    const { page = 1 } = state.pagination

    const data = await agentProvider.filter({
      ...state.filters.values,
      page
    })

    if (data) {
      commit(SET_LIST_ITEMS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async delete({ commit }, scan) {
    commit(SET_TABLE_LOADING, true)

    const res = await agentProvider.delete(scan)

    if (res) {
      commit(SET_TABLE_LOADING, false)
    }
  },

  async find({ commit }, id) {
    commit(LOADING_MODEL, true)
    const res = await agentProvider.find(id)

    if (res) {
      const data = res
      commit(SET_MODEL, data)
      commit(LOADING_MODEL, false)
      return data
    }
  },

  async findByOption(_, name) {
    const data = await agentProvider.findByOption({ name })
    if (data) {
      return data
    }
  },

  async loadToken({ commit, dispatch }, id) {
    commit(LOADING_MODEL, true)
    const res = await agentProvider.loadToken(id)

    dispatch('filter')

    if (res) {
      const data = res
      commit(SET_MODEL, data)
      commit(LOADING_MODEL, false)
      return data
    }
  },

  async update({ state, commit, dispatch }) {
    commit(SAVING_MODEL, true)

    try {
      const res = await agentProvider.update(
        state.form.agentId,
        state.form.agent
      )
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Agente editado com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')

      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    } finally {
      commit(SAVING_MODEL, false)
    }
  },

  async create({ state, dispatch, commit }) {
    commit(SAVING_MODEL, true)
    try {
      const res = await agentProvider.create(state.form.agent)
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Agente criado com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')

      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    } finally {
      commit(SAVING_MODEL, false)
    }
  },

  setCurrentModelId({ commit }, modelId) {
    commit(SET_CURRENT_MODEL, modelId)
  },

  setCurrentModel({ commit }, model) {
    commit(SET_MODEL, model)
  },

  setCurrentErrors({ commit }, errors) {
    commit(SET_CURRENT_ERRORS, errors)
  },

  setPagination({ commit }, pagination) {
    commit(SET_PAGINATION, pagination)
  },

  setFilterValues({ commit }, filterValues) {
    commit(SET_FILTER_VALUES, filterValues)
  }
}

export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.pagination
  },
  loading(state) {
    return state.form.loading
  },
  periodicities(state) {
    return state.periodicities
  },
  periodicity(state) {
    return state.form.periodicity
  },
  readyToUpdate(state) {
    return state.form.periodicityId != null
  },
  errors(state) {
    return state.form.errors
  },
  filters(state) {
    return state.filters
  }
}

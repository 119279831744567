import ScanIndex from '@/views/scans/Index'
import CreateScan from '@/views/scans/Create'
import EditScan from '@/views/scans/Edit'
import ScannedData from '@/views/scannedData'
import GlobalScannedData from '@/views/scannedData/Global.vue'

export default [
  {
    name: 'GlobalScannedData',
    path: '/scans/histories',
    component: GlobalScannedData,
    meta: {
      title: 'Dados Encontrados',

      breadCrumb() {
        return [
          {
            text: 'Scans'
          },
          {
            text: 'Históricos'
          },

          {
            text: 'Dados Encontrados'
          }
        ]
      }
    }
  },
  {
    name: 'ScanIndex',
    path: '/scans',
    component: ScanIndex,
    meta: {
      title: 'Scans',
      breadCrumb: [
        {
          to: { name: 'ScanIndex' },
          text: 'Scans'
        }
      ]
    }
  },
  {
    name: 'CreateScan',
    path: '/scans/create',
    component: CreateScan,
    meta: {
      title: 'Novo Scan',
      breadCrumb: [
        {
          to: { name: 'ScanIndex' },
          text: 'Scans'
        },
        {
          to: { name: 'CreateScan' },
          text: 'Novo Scan'
        }
      ]
    }
  },
  {
    name: 'EditScan',
    path: '/scans/:id',
    component: EditScan,
    meta: {
      title: 'Editar Scan',
      breadCrumb: [
        {
          to: { name: 'ScanIndex' },
          text: 'Scans'
        },
        {
          to: { name: 'EditScan' },
          text: 'Editar'
        }
      ]
    }
  },

  {
    name: 'ScannedData',
    path: '/scans/:scanId/histories/:scanHistoryId/scannedData',
    component: ScannedData,
    meta: {
      title: 'Dados Encontrados',

      breadCrumb(route) {
        const scanId = route.params.scanId
        const scanHistoryId = route.params.scanHistoryId

        return [
          {
            to: { name: 'ScanIndex' },
            text: 'Scans'
          },
          {
            text: scanId,
            to: {
              name: 'ScanId',
              params: {
                scanId: scanId
              }
            }
          },
          {
            text: 'Históricos'
          },
          {
            text: scanHistoryId
          },
          {
            to: { name: 'ScannedData' },
            text: 'Dados Encontrados'
          }
        ]
      }
    }
  }
]

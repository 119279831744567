<template>
  <div>
    <v-card class="p-0" height="80" :color="color" dark>
      <v-card-text class="d-flex align-center" style="padding: 8px 16px">
        <div class="mr-auto">
          <v-list-item-title style="height: 30px" class="headline">
            <v-skeleton-loader v-if="loading" class="mx-auto" type="button" />

            <span v-else>
              {{ count }}
            </span>
          </v-list-item-title>

          <v-list-item-subtitle class="font-weight-bold">
            {{ text }}
          </v-list-item-subtitle>
        </div>

        <v-avatar rounded size="60">
          <v-icon size="50" color="#d5d5d5">{{ icon }}</v-icon>
        </v-avatar>
      </v-card-text>
      <v-progress-linear
        :color="color"
        :indeterminate="loading"
        rounded
        height="10"
      ></v-progress-linear>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CardCount',
  props: {
    color: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    count: {
      default: 0
    }
  }
}
</script>

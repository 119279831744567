<template>
  <v-expansion-panels v-model="model">
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate>
        Pesquisar

        <template v-slot:actions>
          <v-icon>mdi-magnify</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form ref="searchForm">
          <v-row class="pb-5" dense>
            <v-col
                v-for="(field, index) in fields"
                :key="index"
                cols="12"
                md="3"
                sm="3"
                style="margin-top: -16px"
            >
              <template>
                <GenericField
                    :find-method="field.findMethod"
                    :itemText="field.itemText"
                    :itemValue="field.itemValue"
                    :items="field.items"
                    :label="field.label"
                    :on-change="field.onChange"
                    :search-method="field.searchMethod"
                    :type="field.type"
                    :value="filters[field.name]"
                    filled
                    outlined
                    @input="(value) => updateValue(field.name, value)"
                ></GenericField>
              </template>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <div class="d-flex pt-5">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" width="100" @click="clear">Limpar</v-btn>

            <v-btn color="primary" width="100" @click="apply()">Aplicar</v-btn>
          </div>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import GenericField from '@/components/GenericField'
import {mapGetters, mapMutations} from 'vuex'
import {CLEAR_FILTERS, SET_FILTERS} from '@/store/modules/filter/mutations'

import omitBy from 'lodash/omitBy'

// replace loadash default function to check empty values
const isEmpty = (value) => {
  return (
      value === undefined ||
      value === null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
  )
}

export default {
  name: 'BeFilter',

  components: {
    GenericField
  },

  props: {
    fields: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      tempFilter: {},
      filters: {},
      model: null
    }
  },

  beforeMount() {
    const searchParams = new URLSearchParams(document.location.search)
    let hasFoundSearch = false

    searchParams.forEach((value, field) => {
      this.filters[field] = value
      hasFoundSearch = true
    })

    if (hasFoundSearch) this.tempFilter = {...this.filters}

    this.$emit('search', this.cleanFilters)
  },

  computed: {
    ...mapGetters({
      globalFilters: 'filterModule/filters'
    }),
    cleanFilters() {
      return omitBy(this.filters, isEmpty)
    }
  },

  // #todo: Limpar a store pelo lado de fora quando emitir um clear()
  methods: {
    ...mapMutations('filterModule', [SET_FILTERS, CLEAR_FILTERS]),
    clear() {
      this.model = false
      this.$refs.searchForm.reset()
      this.filters = {}
      this[CLEAR_FILTERS]()
      this.redirectToBaseURL(false)
    },
    redirectToBaseURL(clearSearch, resetCurrentPage = false) {
      const url = new URL(document.location)

      if (clearSearch) {
        url.search = ''
      } else {
        Object.keys(this.cleanFilters).map((filter) => {
          url.searchParams.set(filter, this.cleanFilters[filter])
        })
      }
      this.filters.page = resetCurrentPage ? this.filters.page : 1
      this.$router.push({query: {...this.cleanFilters}})
      this[SET_FILTERS](this.cleanFilters)
      this.$emit('search', this.cleanFilters)
    },
    apply() {
      this.redirectToBaseURL(false, true)
    },
    updateValue(fieldName, value) {
      this.tempFilter[fieldName] = value
      this.filters = JSON.parse(JSON.stringify(this.tempFilter))
    }
  },
  watch: {
    globalFilters: {
      handler(val) {
        this.filters = {...val}
      },
      deep: true
    },
    '$route': {
      handler(to) {
        this.filters = {...to.query}
      },
      deep: true
    }
  }
}
</script>

export const SET_LOADING_CATEGORY_COUNTERS = 'SET_LOADING_CATEGORY_COUNTERS'
export const SET_DETECTED_CATEGORIES = 'SET_DETECTED_CATEGORIES'
export const SET_LOADING_SYSTEM_INFO = 'SET_LOADING_SYSTEM_INFO'
export const SET_LOADING_CATEGORIES = 'SET_LOADING_CATEGORIES'
export const SET_CATEGORY_COUNTERS = 'SET_CATEGORY_COUNTERS'
export const SET_LOADING_COUNTERS = 'SET_LOADING_COUNTERS'
export const SET_SYSTEM_INFO = 'SET_SYSTEM_INFO'
export const SET_BAR_IMAGE = 'SET_BAR_IMAGE'
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const SET_COUNTERS = 'SET_COUNTERS'

export default {
  [SET_LOADING_CATEGORY_COUNTERS](state, loading) {
    state.loadingCategoryCounts = loading
  },
  [SET_CATEGORY_COUNTERS](state, payload) {
    state.categoryCounts = payload
  },

  [SET_BAR_IMAGE](state, payload) {
    state.barImage = payload
  },
  [SET_LOADING_SYSTEM_INFO](state, loadingSystemInfo) {
    state.loadingSystemInfo = loadingSystemInfo
  },
  [SET_SYSTEM_INFO](state, systemInfo) {
    state.systemInfo = systemInfo
  },
  [TOGGLE_DRAWER](state, payload) {
    if (typeof payload == 'boolean') {
      state.drawer = payload
    } else {
      state.drawer = !state.drawer
    }
  },

  [SET_COUNTERS](state, counters) {
    state.counters = counters
  },
  [SET_DETECTED_CATEGORIES](state, categories) {
    state.categories = categories
  },

  [SET_LOADING_CATEGORIES](state, isLoadingCategories) {
    state.loadingCategories = isLoadingCategories
  },

  [SET_LOADING_COUNTERS](state, isLoadingCounters) {
    state.isLoadingCounters = isLoadingCounters
  }
}

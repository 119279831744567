export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.table.pagination
  },

  users(state) {
    return state.users
  },
  user(state) {
    return state.form.user
  },

  errors(state) {
    return state.form.errors
  },

  filters(state) {
    return state.filters
  },
  readyToUpdate(state) {
    return state.form.userId != null
  },
  loading(state) {
    return state.form.loading
  }
}

export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pending(state) {
    return state.pending
  },

  pagination(state) {
    return state.table.pagination
  },

  reports(state) {
    return state.reports
  },

  errors(state) {
    return state.form.errors
  },

  filters(state) {
    return state.filters
  },
  loading(state) {
    return state.form.loading
  }
}

export default {
  resources(state) {
    return state.resources
  },

  table(state) {
    return state.table
  },

  pagination(state) {
    return state.table.pagination
  },

  apiTokens(state) {
    return state.apiTokens
  },
  
  apiToken(state) {
    return state.form.apiToken
  },

  errors(state) {
    return state.form.errors
  },

  filters(state) {
    return state.filters
  },
  readyToUpdate(state) {
    return state.form.apiTokenId != null
  },
  loading(state) {
    return state.form.loading
  }
}

import PeriodicityIndex from '@/views/periodicities/Index'

export default [
  {
    name: 'PeriodicityIndex',
    path: '/periodicities',
    component: PeriodicityIndex,
    meta: {
      title: 'Periodicidades',
      breadCrumb: [
        {
          to: { name: 'PeriodicityIndex' },
          text: 'Periodicidades'
        }
      ]
    }
  }
]

<template>
  <div class="d-flex align-center">
    <v-breadcrumbs divider="/" :items="crumbs" style="color: #fff" />
    <v-spacer />
    <slot name="actions"></slot>
  </div>
</template>
<script>
export default {
  name: 'TopHeader',

  computed: {
    crumbs() {
      if (typeof this.$route.meta.breadCrumb === 'function')
        return this.$route.meta.breadCrumb.call(this, this.$route)

      return this.$route.meta.breadCrumb
    }
  }
}
</script>

import periodicityProvider from '@/core/api/providers/periodicity'
import {
  SET_CURRENT_ERRORS,
  SET_PAGINATION,
  SET_TABLE_LOADING,
  LOADING_MODEL,
  SET_LIST_ITEMS,
  SET_MODEL
} from '@/store/modules/periodicities/mutations'
export default {
  async filter({ commit, state }) {
    commit(SET_TABLE_LOADING, true)
    const data = await periodicityProvider.filter({
      ...state.filters.values
    })

    if (data) {
      commit(SET_LIST_ITEMS, data.data)
      commit(SET_PAGINATION, data.meta)
      commit(SET_TABLE_LOADING, false)
    }
  },

  async delete({ commit }, periodicity) {
    commit(SET_TABLE_LOADING, true)

    const res = await periodicityProvider.delete(periodicity)

    if (res) commit(SET_TABLE_LOADING, false)
  },

  async find({ commit }, id) {
    commit(LOADING_MODEL, true)
    const data = await periodicityProvider.find(id)

    if (data) {
      commit(SET_MODEL, { ...data })
      commit(LOADING_MODEL, false)
      return data
    }
  },

  async findByOption(_, name) {
    const data = await periodicityProvider.findByOption({ name })
    if (data) {
      return data
    }
  },

  async update({ state, commit, dispatch }) {
    try {
      commit(LOADING_MODEL, true)
      const res = await periodicityProvider.update(
        state.form.periodicityId,
        state.form.periodicity
      )
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Periodicidade atualizada com sucesso.'
        },
        { root: true }
      )
      commit(LOADING_MODEL, false)
      dispatch('filter')

      return res
    } catch (error) {
      commit(SET_CURRENT_ERRORS, error.data)
      commit(LOADING_MODEL, false)
      throw error
    }
  },

  async create({ state, dispatch, commit }) {
    try {
      commit(LOADING_MODEL, true)
      const res = await periodicityProvider.create(state.form.periodicity)
      commit(SET_MODEL, { ...res })
      dispatch(
        'snackbarModule/showSnackbar',
        {
          type: 'success',
          message: 'Periodicidade criada com sucesso.'
        },
        { root: true }
      )

      dispatch('filter')
      commit(LOADING_MODEL, false)

      return res
    } catch (error) {
      commit(LOADING_MODEL, false)
      commit(SET_CURRENT_ERRORS, error.data)
      throw error
    }
  }
}

<template>
  <div>
    <v-card
      height="80"
      :style="`
        overflow: hidden;
        position: relative;
        z-index: 1;
        background: linear-gradient(45deg, ${colors[0]}, ${colors[1]});`"
    >
      <v-row class="ma-0 pa-0 white--text">
        <v-col style="display: flex; flex-direction: column">
          <span style="font-size: 16px">{{ label }}</span>
          <h2>{{ count }}</h2>
        </v-col>

        <div style="position: absolute; right: -35px; bottom: -35px">
          <v-icon style="opacity: 0.5" color="white" size="120">
            {{ icon }}
          </v-icon>
        </div>
      </v-row>
    </v-card>
    <v-progress-linear
      :color="colors[0]"
      :indeterminate="loading"
      rounded
      height="5"
    />
  </div>
</template>
<script>
export default {
  name: 'CategoryCardCount',
  props: {
    colors: {
      type: Array,
      default: () => ['primary', 'primary']
    },
    icon: {
      type: String,
      default: 'mdi-account'
    },
    label: {
      type: String,
      default: 'label'
    },
    loading: {
      type: Boolean,
      default: false
    },

    count: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>

import AgentIndex from '@/views/agents/Index'

export default [
  {
    name: 'AgentIndex',
    path: '/agents',
    component: AgentIndex,
    meta: {
      title: 'Agentes',
      breadCrumb: [
        {
          to: { name: 'AgentIndex' },
          text: 'Agentes'
        }
      ]
    }
  }
]

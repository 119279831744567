<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :size="iconSize" v-bind="attrs" v-on="on" :color="iconColor">
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'BeIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      default: '25'
    },
    iconColor: {
      type: String,
      default: '#707070'
    },
    tooltip: {
      type: String
    }
  }
}
</script>

export default {
  scans: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },
      {
        name: 'type',
        label: 'Tipo',
        type: 'select',
        items: [
          {
            text: 'Banco de Dados',
            value: 'database'
          },
          {
            text: 'Agente Remoto',
            value: 'agent'
          },
          {
            text: 'Armazenamento na Nuvem',
            value: 'cloud_storage'
          }
        ]
      },
      {
        name: 'status',
        label: 'Status',
        type: 'select',
        items: [
          {
            text: 'Criado',
            value: '0'
          },
          {
            text: 'Conexão realizada',
            value: '1'
          },
          {
            text: 'Iniciado',
            value: '10'
          },
          {
            text: 'Processando',
            value: '20'
          },
          {
            text: 'Erro',
            value: '30'
          },
          {
            text: 'Erro ao testar conexão',
            value: '31'
          },
          {
            text: 'Finalizado',
            value: '40'
          },
          {
            text: 'Finalizado Forçado',
            value: '41'
          }
        ]
      }
    ],
    values: {}
  },
  // # TODO ALterar estrutura
  form: {
    saving: true,
    scanId: null,
    scan: {
      type: null,
      resourceType: null,
      name: null,
      config: {},
      settings: {
        extensions: [],
        maxFileSizeMB: 0
      }
    },
    errors: {
      config: {
        client: [],
        connection: {
          port: [],
          schema: [],
          database: [],
          user: [],
          password: []
        },
        settings: {
          extensions: [],
          maxFileSizeMB: [],
        }
      }
    }
  },
  table: {
    loading: false,
    refreshing: false,
    pagination: {}
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 0,
    hasNext: false
  },
  resources: [
    {
      name: 'Banco de Dados',
      key: 'database',
      types: [
        {
          hidden: false,
          key: 'mysql',
          name: 'MySQL'
        },
        {
          hidden: false,
          key: 'postgres',
          name: 'Postgres'
        },
        {
          hidden: false,
          key: 'oracle',
          name: 'Oracle'
        },
        {
          hidden: false,
          key: 'mariadb',
          name: 'MariaDB'
        },
        {
          hidden: false,
          key: 'mongodb',
          name: 'MongoDB'
        },
        {
          hidden: false,
          key: 'mssql',
          name: 'MSSQL'
        }
      ]
    },
    {
      name: 'Agente remoto',
      key: 'agent',
      types: [
        {
          hidden: true,
          key: 'agent',
          name: 'Agent'
        }
      ]
    },
    {
      name: 'Armazenamento na Nuvem',
      key: 'cloud_storage',
      types: [
        {
          hidden: false,
          key: 'google_drive',
          name: 'Google Drive'
        },
        {
          hidden: false,
          key: 'one_drive',
          name: 'OneDrive'
        },
        {
          hidden: false,
          key: 'azure_blob_storage',
          name: 'Azure Blob Storage'
        },
        {
          hidden: false,
          key: 's3',
          name: 'Amazon S3'
        },
        {
          hidden: false,
          key: 'digital_ocean_spaces',
          name: 'Digital Ocean Spaces'
        },
        {
          hidden: false,
          key: 'ibm_cloud_object_storage',
          name: 'IBM Cloud Object Storage'
        },
        {
          hidden: false,
          key: 'cloudflare_r2',
          name: 'Cloudflare R2'
        },
        {
          hidden: false,
          key: 'alibaba_object_storage_service',
          name: 'Alibaba Object Storage Service'
        },
        {
          hidden: false,
          key: 'linode_object_storage',
          name: 'Linode Object Storage'
        },
        {
          hidden: false,
          key: 'vultr_object_storage',
          name: 'Vultr Object Storage'
        },
        {
          hidden: false,
          key: 'wasabi_object_storage',
          name: 'Wasabi Object Storage'
        }
      ]
    }
  ]
}

export default {
  apiTokens: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },
      {
        name: 'description',
        label: 'Descrição',
        type: 'text'
      }
    ],
    values: {}
  },
  form: {
    saving: true,
    apiTokenId: null,
    apiToken: {
      id: null,
      name: null,
      description: null,
    },
    errors: {}
  },
  table: {
    loading: false,
    pagination: {}
  }
}

<template>
  <v-card small :color="color" :dark="dark" class="wrap">
    <v-icon color="white" small>{{ icon }}</v-icon>
    <BeEllipsis :data="subNum" :tooltip="tooltip + ': ' + value"></BeEllipsis>
  </v-card>
</template>
<script>
import BeEllipsis from '@/components/Ellipsis'
export default {
  name: 'ChipCount',
  components: {
    BeEllipsis
  },
  props: {
    maxValue: {
      default: () => 999
    },
    tooltip: {
      default: () => 'Sobre'
    },
    icon: {
      default: () => 'mdi-percent-outline'
    },
    value: {
      default: () => 0
    },
    color: {
      default: 'success'
    },
    dark: {
      default: false
    }
  },
  computed: {
    subNum() {
      if (!this.maxValue) return this.value

      if (this.value >= this.maxValue) return `${this.maxValue}+`

      return this.value
    }
  }
}
</script>

<style scoped>
.wrap {
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin-right: 10px;
}
</style>

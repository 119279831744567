<template>
  <v-form ref="periodicityForm">
    <v-row>
      <v-col cols="9">
        <BeTextField
          label="Nome *"
          v-model="periodicity.name"
          filled
          :disabled="loading"
          outlined
          :rules="[rules.required]"
          :error-messages="errors.name"
        />
      </v-col>

      <v-col cols="3" class="d-flex align-center">
        <BeCheckbox
          :disabled="loading"
          label="Esta ativo?"
          v-model="periodicity.isActive"
          :error-messages="errors.isActive"
          :loading="loading"
        />
      </v-col>

      <v-col cols="12">
        <template v-if="errors.cronPattern && errors.cronPattern.length > 0">
          <v-alert type="error">{{ errors.cronPattern[0] }}</v-alert>
        </template>
        <VueCronEditorBuefy
          v-model="periodicity.cronPattern"
          locale="pt"
          :loading="loading"
          preserveStateOnSwitchToAdvanced
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VueCronEditorBuefy from 'vue-cron-editor-buefy'
import BeTextField from '@/components/TextField'
import BeCheckbox from '@/components/Checkbox'

import {
  SET_PAGINATION,
  SET_FILTER_VALUES,
  SET_MODEL,
  SET_CURRENT_MODEL,
  SET_CURRENT_ERRORS
} from '@/store/modules/periodicities/mutations'

import { INIT_PERIODICITY_FORM } from '@/store/modules/periodicities/state'

import validations from '@/core/utils/validations'

export default {
  name: 'PeriodicityForm',

  components: {
    VueCronEditorBuefy,
    BeTextField,
    BeCheckbox
  },
  data() {
    return {
      periodicity: { ...INIT_PERIODICITY_FORM }
    }
  },

  computed: {
    ...mapGetters('periodicityModule', [
      'scans',
      'filters',
      'table',
      'loading',
      'pagination',
      'readyToUpdate',
      'errors'
    ]),

    rules() {
      return validations
    },

    dialogTitle() {
      if (this.readyToUpdate) return 'Editar Periodicidade'

      return 'Nova Periodicidade'
    }
  },

  methods: {
    ...mapActions({
      create: 'periodicityModule/create',
      update: 'periodicityModule/update',
      find: 'periodicityModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('periodicityModule', [
      SET_PAGINATION,
      SET_FILTER_VALUES,
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    ]),

    async prepareToCreate() {
      await this.$refs.periodicityForm.reset()
      this.periodicity = {
        name: '',
        cronPattern: '0 9 */1 * *',
        isActive: true
      }

      this[SET_CURRENT_ERRORS]([])
      this[SET_CURRENT_MODEL](null)
    },

    async attemptSave() {
      if (this.$refs.periodicityForm.validate()) {
        this[SET_MODEL](this.periodicity)
        if (this.readyToUpdate) {
          await this.update()
        } else {
          await this.create()
        }
      } else {
        throw Error()
      }
    },

    async findPeriodicity(periodicity) {
      try {
        const { periodicityId } = periodicity
        this[SET_CURRENT_MODEL](periodicityId)
        this.periodicity = await this.find(periodicityId)
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar a periodicidade.'
        })
      }
    }
  }
}
</script>

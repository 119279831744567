<template>
  <div>
    <label class="text-body-2">{{ label }}</label>
    <v-combobox
      v-model="model"
      chips
      dense
      solo
      :append-icon="appendIcon"
      :append-outer-icon="appendOuterIcon"
      :clearable="clearable"
      :counter="counter"
      :disabled="disabled"
      :error-messages="errorMessages"
      :hide-details="hideDetails"
      :hint="hint"
      :loading="loading"
      :prefix="prefix"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :rules="rules"
      :suffix="suffix"
      :type="type"
      :value="value"
      @click:append="() => $emit('click:append')"
      :taggable="taggable"
      :multiple="multiple"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          small
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
          color="primary"
        >
          {{ item }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>
<script>
export default {
  name: 'BeComboBox',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    filled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      default: 'auto'
    },
    counter: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: null
    },
    prependInnerIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    appendOuterIcon: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    suffix: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      default: undefined
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit(
          'input',
          value.map((e) => e.toUpperCase())
        )
      }
    }
  },

  methods: {
    remove(item) {
      this.model = this.model.filter((e) => e != item)
    }
  }
}
</script>

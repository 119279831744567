import sensitiveCategoryProvider from '@/core/api/providers/sensitiveCategory'

export default {
  async getAll() {
    return await sensitiveCategoryProvider.getAll()
  },

  async find(_, id) {
    // commit("LOADING_SCAN", true);
    const res = await sensitiveCategoryProvider.find(id)

    if (res) {
      const data = res
      // commit("LOADING_SCAN", false);
      return data
    }
  },

  async findByOption(_, name) {
    const data = await sensitiveCategoryProvider.findByOption({ name })
    if (data) {
      return data
    }
  }
}

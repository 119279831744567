import APIClient from '@/core/http/apiClient'

export class SensitiveCategoryProvider {
  constructor(httpClient) {
    this.httpClient = httpClient
    this.basePath = '/private/sensitiveCategories'
  }

  async find(id) {
    return await this.httpClient.call(`${this.basePath}/${id}`, {}, 'get')
  }

  async findByOption(data) {
    return await this.httpClient.call(`${this.basePath}/options`, data, 'get')
  }

  async getAll() {
    return await this.httpClient.call(`${this.basePath}/getAll`, {}, 'get')
  }
}

export default new SensitiveCategoryProvider(new APIClient())

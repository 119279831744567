export const INIT_DETECTOR_FORM = Object.freeze({
  name: '',
  type: '',
  isRegex: true,
  isReadOnly: true,
  isActive: true,
  regexPattern: '',
  categoryId: null,
  regexFlags: []
})

export default {
  detectors: [],
  filters: {
    fields: [
      {
        name: 'name',
        label: 'Nome',
        type: 'text'
      },

      {
        name: 'isActive',
        label: 'Esta ativo?',
        type: 'select',
        items: [
          {
            text: 'Sim',
            value: '1'
          },
          {
            text: 'Não',
            value: '0'
          }
        ]
      }
    ],
    values: {}
  },
  // # TODO ALterar estrutura
  form: {
    loading: false,
    detectorId: null,
    detector: INIT_DETECTOR_FORM,
    errors: {
      name: [],
      type: [],
      isActive: [],
      regexPattern: []
    }
  },
  table: {
    loading: false,
    pagination: {}
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    itemCount: 0,
    totalItems: 0,
    totalPages: 0,
    hasNext: false
  }
}

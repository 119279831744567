<template>
  <v-form ref="scanForm" @submit.prevent="attemptSave">
    <v-expansion-panels class="mb-5" :value="0">
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Informações</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <BeTextField
                type="text"
                :disabled="loading"
                v-model="scan.name"
                :value="scan.name"
                :rules="rules.name"
                :error-messages="errors.name"
                outlined
                filled
                required
                label="Nome *"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mb-5" :value="0">
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Tipo do Recurso</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-chip-group
                mandatory
                v-model="resourceOrigin"
                active-class="primary"
                column
                @change="
                  scan.config = {}
                  resourceTarget = 0
                "
              >
                <v-chip v-for="(resource, idx) in resources" :key="idx">
                  {{ resource.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels
      v-if="!resources[resourceOrigin].types[resourceTarget].hidden"
      class="mb-5"
      :value="0"
    >
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Recurso Alvo</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-chip-group
                v-model="resourceTarget"
                active-class="primary"
                column
                mandatory
                @change="scan.config = {}"
              >
                <v-chip
                  v-for="(resource, idx) in resources[resourceOrigin].types"
                  :key="idx"
                >
                  {{ resource.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ScanConfig
      :resourceOrigin="resourceOrigin"
      :resourceTarget="resourceTarget"
      :config="scan.config"
      :errors="errors.config"
      class="mb-5"
      @change="(value) => (scan.config = value)"
    ></ScanConfig>

    <v-expansion-panels class="mb-5" :value="0">
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Periodicidade</span>
          <v-btn
            color="primary"
            max-width="100"
            class="mr-3"
            small
            @click.stop="showDialog"
          >
            Adicionar
          </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader v-if="loading" type="list-item" />
              <BeTag
                v-else
                :disabled="loading"
                v-model="scan.scanPeriodicityId"
                :find-method="findPeriodicity"
                :search-method="findPeriodicityByOption"
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mb-5" :value="0">
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Detectores</span>
          <v-btn
            color="primary"
            max-width="100"
            class="mr-3"
            small
            @click.stop="showDetectorDialog"
          >
            Adicionar
          </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader v-if="loadingDetectors" type="list-item" />

              <BeTag
                v-else
                v-model="scan.scanDetectorsIds"
                :multiple="true"
                :search-method="findDetectorByOption"
                :find-method="findDetector"
                item-text="name"
                item-value="id"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expansion-panels class="mb-5" :value="0" v-if="isScanTypeStorage()">
      <v-expansion-panel class="panel">
        <v-expansion-panel-header>
          <span class="text-body-1 font-weight-bold">Configurações</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <BeComboBox
                    v-model="scan.settings.extensions"
                    multiple
                    required
                    :disabled="loading"
                    :rules="rules.settings.extensions"
                    label="Extensões de arquivo permitidas"
                  />

                  <BeTextField
                    v-model="scan.settings.maxFileSizeMB"
                    type="text"
                    :disabled="loading"
                    :rules="rules.settings.maxFileSizeMB"
                    :error-messages="errors.config.settings.maxFileSizeMB"
                    outlined
                    filled
                    required
                    label="Tamanho máximo em MB"
                  ></BeTextField>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <PeriodicityDialog
      @setPeriodicity="setPeriodicity"
      v-model="dialogOptions"
    />
    <DetectorDialog @setDetector="setDetector" v-model="detectorDialog" />
  </v-form>
</template>

<script>
import BeTextField from '@/components/TextField'
import BeComboBox from '@/components/BeComboBox'
import ScanConfig from '@/views/scans/ScanConfig'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { SET_SCAN, SET_CURRENT_SCAN } from '@/store/modules/scans/mutations'
import BeTag from '@/components/BeTag'
import PeriodicityDialog from '@/components/PeriodicityDialog'
import DetectorDialog from '@/components/DetectorDialog'
import validations from '@/core/utils/validations'
import scanTypes from '@/core/api/enums/scanTypes'

export default {
  name: 'ScanForm',
  components: {
    BeTextField,
    BeComboBox,
    ScanConfig,
    PeriodicityDialog,
    BeTag,
    DetectorDialog
  },

  props: {
    formId: {
      type: Number
    }
  },

  data() {
    return {
      resourceTarget: 0,
      resourceOrigin: 0,
      loadingDetectors: true,
      loading: true,
      dialogOptions: {
        show: false,
        periodicityId: null
      },
      detectorDialog: {
        show: false,
        scanDetectorsIds: null
      },
      scan: {
        name: null,
        type: null,
        resourceType: null,
        config: {},
        scanPeriodicityId: null,
        scanDetectorsIds: [],
        settings: {
          extensions: [
            'PDF',
            'TXT',
            'XLS',
            'XLSX',
            'SQL',
            'CSV',
            'LOG',
            'DOC',
            'DOCX'
          ],
          maxFileSizeMB: 5
        }
      },
      items: [],
      detectors: []
    }
  },

  computed: {
    ...mapGetters({
      resources: 'scanModule/resources',
      // currentScan: "scanModule/scan",
      errors: 'scanModule/errors'
    }),

    selectedResource() {
      const resource = this.resources[this.resourceOrigin]
      if (resource) {
        return resource
      } else {
        return null
      }
    },
    selectedType() {
      if (this.selectedResource) {
        const type = this.selectedResource.types[this.resourceTarget]
        if (type) return type
      }

      return null
    },
    rules() {
      return {
        name: [(v) => validations.required(v)],
        settings: {
          extensions: [(v) => validations.required(v)],
          maxFileSizeMB: [(v) => validations.required(v)]
        }
      }
    }
  },

  async created() {
    this.loadingDetectors = false
    const { id } = this.$route.params
    if (id) {
      this.loading = true
      this.scanId = id

      this[SET_CURRENT_SCAN](id)
      const scan = await this.find(id)
      Object.assign(this.scan, scan)

      if (Number.isInteger(parseInt(this.scan.resourceType))) {
        this.resourceOrigin = 1
      } else {
        for (let [idx, resource] of this.resources.entries()) {
          for (let [index, resourceType] of resource.types.entries()) {
            if (resourceType.key == this.scan.resourceType) {
              this.resourceOrigin = idx
              this.resourceTarget = index
            }
          }
        }
      }
    } else {
      await this.loadDefaultDetectors()
    }
    this.loading = false
  },

  methods: {
    ...mapMutations('scanModule', {
      SET_SCAN,
      SET_CURRENT_SCAN
    }),

    ...mapActions({
      create: 'scanModule/create',
      update: 'scanModule/update',
      find: 'scanModule/find',
      showSnackbar: 'snackbarModule/showSnackbar',
      findPeriodicityByOption: 'periodicityModule/findByOption',
      findDetectorByOption: 'detectorModule/findByOption',
      findDetector: 'detectorModule/find',
      getDefatultsDetectors: 'detectorModule/getDefaults',
      findPeriodicity: 'periodicityModule/find'
    }),
    async loadDefaultDetectors() {
      let detectors = await this.getDefatultsDetectors()
      let detectorsIds = detectors.map((e) => {
        return e.id
      })
      this.scan.scanDetectorsIds =
        this.scan.scanDetectorsIds.concat(detectorsIds)
    },
    showDialog() {
      this.dialogOptions = {
        show: true,
        periodicityId: null
      }
    },

    setPeriodicity(periodicity) {
      this.scan.scanPeriodicityId = periodicity.id
    },

    setDetector(detector) {
      this.scan.scanDetectorsIds.push(detector.id)
    },

    showDetectorDialog() {
      this.detectorDialog = {
        show: true,
        scanDetectorsIds: null
      }
    },
    async attemptSave() {
      if (this.$refs.scanForm.validate()) {
        this.loading = true
        this.$emit('loading', true)

        if (!this.isScanTypeStorage()) this.scan.settings = {}

        try {
          Object.assign(this.scan, {
            type: this.selectedResource.key,
            resourceType:
              this.selectedType.key === 'agent'
                ? this.scan.config.agentId
                : this.selectedType.key
          })

          this[SET_SCAN](this.scan)
          if (this.scan.id) await this.update()
          else await this.create()

          this.$router.push({ name: 'ScanIndex' })
        } finally {
          this.loading = false
          this.$emit('loading', false)
        }
        this.loading = false
        this.$emit('loading', false)
      }
    },

    getScanType() {
      return this.resources[this.resourceOrigin]?.key
    },

    isScanTypeStorage() {
      const storageTypes = [scanTypes.AGENT, scanTypes.CLOUD_STORAGE]
      return storageTypes.includes(this.getScanType())
    }
  }
}
</script>

<template>
  <BeListCardHeader>
    <template v-slot:content>
      <v-col class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0" align="center">
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Valor" />
            </h2>
            <BeEllipsis data="Contexto" />
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Detector" />
            </h2>
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Scan" />
            </h2>
          </v-col>
          <v-col cols="12" md="3" class="ma-0 pa-0">
            <h2>
              <BeEllipsis data="Origem" />
            </h2>
          </v-col>
        </v-row>
      </v-col>
    </template>
  </BeListCardHeader>
</template>
<script>
import BeListCardHeader from '@/components/BeListCardHeader'
import BeEllipsis from '@/components/Ellipsis'

export default {
  components: {
    BeListCardHeader,
    BeEllipsis
  }
}
</script>

import APIClient from '@/core/http/apiClient'

export class ReportProvider {
  constructor(httpClient) {
    this.basePath = '/private/reports'
    this.httpClient = httpClient
  }

  async generateScanHistoryReport(id) {
    return await this.httpClient.call(
      `${this.basePath}/scanHistoryReport/${id}`,
      'get'
    )
  }

  async download(id) {
    return await this.httpClient.call(`${this.basePath}/${id}/download`, 'get')
  }

  async find(id) {
    return await this.httpClient.call(`${this.basePath}/${id}`, {}, 'get')
  }

  async filter(params) {
    return await this.httpClient.call(`${this.basePath}`, params, 'get')
  }

  async delete(reportId) {
    return await this.httpClient.call(
      `${this.basePath}/${reportId}`,
      {},
      'delete'
    )
  }

  async getPending() {
    return await this.httpClient.call(`${this.basePath}/getPending`, {}, 'post')
  }
}

export default new ReportProvider(new APIClient())

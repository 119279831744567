<template>
  <v-dialog width="600" v-model="model">
    <v-card>
      <v-toolbar color="primary" dark elevation="0">
        <v-toolbar-title class="text--white">{{ dialogTitle }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="model = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="panel pt-5">
        <AgentForm v-if="type == 'form'" ref="agentForm" />
        <AgentToken v-else ref="agentToken" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="flex">
        <v-spacer></v-spacer>
        <v-btn width="100" @click="model = false">Fechar</v-btn>

        <v-btn
          color="primary"
          v-if="type == 'form'"
          :disabled="loading || saving"
          :loading="loading || saving"
          width="100"
          @click="attemptSave"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AgentForm from '@/components/AgentForm'
import AgentToken from '@/components/AgentToken'
import Vue from 'vue'
import { SET_MODEL } from '@/store/modules/agents/mutations'

export default {
  name: 'PeriodicityDialog',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AgentForm,
    AgentToken
  },

  computed: {
    ...mapGetters('agentModule', [
      'readyToUpdate',
      'errors',
      'loading',
      'saving'
    ]),

    model: {
      get() {
        const { show } = this.value

        if (show) return show
        return false
      },
      set(value) {
        const options = { ...this.value }
        options.show = value
        this.$emit('input', options)
      }
    },

    type() {
      return this.value.type
    },

    dialogTitle() {
      if (this.type == 'form') {
        if (this.readyToUpdate) return 'Editar Agente'

        return 'Novo Agente'
      } else return 'Visualizar Token'
    }
  },

  methods: {
    ...mapActions({
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    ...mapMutations('agentModule', [SET_MODEL]),

    prepareToCreate() {
      this[SET_MODEL](null)
    },

    async attemptSave() {
      await this.$refs.agentForm.attemptSave()
      if (!this.errors.length > 0) {
        this.$emit('setAgent', this.agent)
      }
      this.model = false
    }
  },

  watch: {
    value: {
      handler(value) {
        if (this.model) {
          Vue.nextTick(() => {
            const { agentId, show } = value

            if (!show) {
              return
            }
            if (this.type == 'form') {
              if (agentId) {
                this.$refs.agentForm.findAgent(value)
              } else {
                this.$refs.agentForm.prepareToCreate()
              }
            } else {
              if (agentId) {
                this.$refs.agentToken.findAgent(value)
              } else {
                this.$refs.agentToken.prepareToCreate()
              }
            }
          })
        }
      },
      deep: true
    }
  }
}
</script>

import APIClient from '@/core/http/apiClient'

export class SettingsProvider {
  constructor(httpClient) {
    this.basePath = '/private/system'
    this.httpClient = httpClient
  }

  async getEnv() {
    return await this.httpClient.call(`${this.basePath}/env`, {}, 'get')
  }

  async postEnv(data) {
    return await this.httpClient.call(`${this.basePath}/env`, data, 'post')
  }

  async restartServices() {
    return await this.httpClient.call(`${this.basePath}/restartServices`, {}, 'get')
  }

  async getTZ() {
    return await this.httpClient.call(`${this.basePath}/TZ`, {}, 'get')
  }

}

export default new SettingsProvider(new APIClient())

export default {
  table(state) {
    return state.table
  },

  pagination(state) {
    return state.pagination
  },

  scannedData(state) {
    return state.scannedData
  },

  scans(state) {
    return state.scans
  },

  detectors(state) {
    return state.detectors
  },

  categories(state) {
    return state.categories
  }
}

export const LOADING_REPORT = 'LOADING_REPORT'
export const SET_FILTER_VALUES = 'SET_FILTER_VALUES'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_REPORTS = 'SET_REPORTS'
export const SET_LOADING_PENDING = 'LOADING_PENDING'

export const SET_GENERATING = 'SET_GENERATING'

export const SET_DOWNLOADING = 'SET_DOWNLOADING'
export const SET_PENDING = 'SET_PENDING'

export default {
  [SET_REPORTS](state, reports) {
    state.reports = reports
  },

  [SET_PAGINATION](state, pagination) {
    state.table.pagination = pagination
  },

  [SET_TABLE_LOADING](state, loading = false) {
    state.table.loading = loading
  },

  [SET_LOADING_PENDING](state, loading = false) {
    state.loadingPending = loading
  },

  [SET_PENDING](state, pending = []) {
    state.pending = pending
  },

  [LOADING_REPORT](state, value = true) {
    state.form.loading = value
  },

  [SET_FILTER_VALUES](state, values) {
    state.filters.values = values
  },

  [SET_GENERATING](state, generating) {
    state.generating = generating
  },

  [SET_DOWNLOADING](state, downloading) {
    state.generating = downloading
  }
}

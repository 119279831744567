<template>
  <div>
    <BeSkeleton
      class="mb-3"
      v-for="(_, i) in quantity"
      :key="i"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
      :type="type"
    />
  </div>
</template>

<script>
import BeSkeleton from '@/components/BeSkeleton'

export default {
  name: 'DisplaySkeleton',

  props: {
    quantity: {
      type: Number,
      required: true
    },

    maxWidth: {
      type: String,
      default: '100%'
    },
    maxHeight: {
      type: String,
      default: '60px'
    },
    type: {
      type: String,
      default: 'image'
    }
  },

  components: {
    BeSkeleton
  }
}
</script>

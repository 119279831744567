<template>
  <div>
    <v-form ref="userForm" @submit.prevent="attemptSave">
      <v-row dense>
        <v-col cols="12" md="12" sm="12">
          <BeTextField
            type="text"
            v-model="user.name"
            outlined
            filled
            :error-messages="errors.name"
            :rules="rules.name"
            label="Nome *"
            :loading="loading"
          />
        </v-col>

        <v-col cols="12" md="12" sm="12">
          <BeTextField
            type="text"
            v-model="user.username"
            outlined
            filled
            :rules="rules.username"
            :error-messages="errors.username"
            label="Username *"
            :loading="loading"
          />
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <BeTextField
            type="password"
            v-model="user.password"
            outlined
            filled
            :rules="!user.id ? rules.password : []"
            :error-messages="errors.password"
            :label="readyToUpdate ? 'Senha' : 'Senha *'"
            :loading="loading"
          />
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <BeTextField
            type="password"
            v-model="user.passwordConfirmation"
            outlined
            filled
            :rules="!user.id ? rules.passwordConfirmation : []"
            :error-messages="errors.passwordConfirmation"
            :label="readyToUpdate ? 'Confirmar senha' : 'Confirmar senha *'"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import Vue from 'vue'
import BeTextField from '@/components/TextField'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import {
  SET_MODEL,
  SET_CURRENT_MODEL,
  SET_CURRENT_ERRORS
} from '@/store/modules/user/mutations'
import validations from '@/core/utils/validations'

export default {
  name: 'UserForm',
  components: {
    BeTextField
  },

  data() {
    return {
      resourceOrigin: 0,
      resourceTarget: 0,
      userId: null,
      loading: false,
      user: {
        name: null,
        username: null,
        password: null,
        passwordConfirmation: null,
        roles: ['admin']
      }
    }
  },

  computed: {
    ...mapGetters({
      errors: 'userModule/errors',
      readyToUpdate: 'userModule/readyToUpdate'
    }),
    rules() {
      return {
        name: [(v) => validations.required(v)],
        username: [(v) => validations.required(v)],
        password: [(v) => validations.required(v)],
        passwordConfirmation: [(v) => validations.required(v)]
      }
    }
  },

  async created() {
    Vue.nextTick(async () => {
      this.$refs.userForm.reset()
      const { id: userId } = this.$route.params
      if (userId) {
        this.userId = userId
        this[SET_CURRENT_MODEL](userId)
        this[SET_CURRENT_ERRORS]([])

        this.loading = true

        try {
          const user = await this.find(userId)

          if (user) this.user = { ...user }
        } catch {
          //
        } finally {
          this.loading = false
        }
      }
    })
  },

  methods: {
    ...mapMutations('userModule', {
      SET_MODEL,
      SET_CURRENT_MODEL,
      SET_CURRENT_ERRORS
    }),
    ...mapActions({
      create: 'userModule/create',
      update: 'userModule/update',
      find: 'userModule/find',
      showSnackbar: 'snackbarModule/showSnackbar'
    }),
    prepareToCreate() {
      this.$refs.userForm.reset()
      this.user = {
        name: null,
        username: null,
        password: null,
        passwordConfirmation: null
      }

      this[SET_CURRENT_MODEL](null)
      this[SET_CURRENT_ERRORS]([])
    },
    async attemptSave() {
      this.loading = true
      this.$emit('loading', true)
      if (this.$refs.userForm.validate()) {
        try {
          this[SET_MODEL](this.user)
          if (this.readyToUpdate) await this.update()
          else await this.create()

          return true
        } catch (error) {
          this.showSnackbar({
            type: 'danger',

            message: 'Erro ao criar usuário.'
          })
        } finally {
          this.loading = false
          this.$emit('loading', false)
        }
      }

      this.loading = false
      this.$emit('loading', false)
      return false
    },

    async findUser(user) {
      try {
        const { userId } = user
        this.user = await this.find(userId)
        this[SET_CURRENT_MODEL](userId)
      } catch {
        this.showSnackbar({
          type: 'danger',
          message: 'Não foi possivel encontrar o usuário.'
        })
      }
    }
  },

  watch: {}
}
</script>

<template>
  <BeChip :content="statusDescription" :color="color" />
</template>
<script>
import BeChip from '@/components/BeChip'

export default {
  components: {
    BeChip
  },

  props: {
    status: {
      type: String,
      required: true
    },
    statusDescription: {
      type: String,
      required: true
    }
  },

  computed: {
    color() {
      // TODO - PASSAR PARA UM ENUM
      if (+this.status === 40) return `success`
      if (+this.status === 10 || this.status === 20) return `warning`
      if (+this.status === 30) return `danger`
      if (+this.status === 0) return `secondary`

      return 'info'
    }
  }
}
</script>

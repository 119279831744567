<template src="./Dialog.html"></template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'DialogsView',

  data() {
    return {}
  },

  methods: {
    ...mapActions('dialogModule', ['displayDialog']),

    showPrimaryDialog() {
      this.displayDialog({
        color: 'primary',
        title: 'Primary',
        type: 'confirmation',
        text: 'Integer sapien est, ultrices vel rutrum quis, dapibus sed odio.',
        callback: this.primaryShow
      })
    },

    showWarningDialog() {
      this.displayDialog({
        color: 'warning',
        title: 'Warning',
        type: 'confirmation',
        text: 'Curabitur posuere tellus in viverra ullamcorper. Vivamus tristique arcu vel maximus mollis?'
      })
    },

    showDangerDialog() {
      this.displayDialog({
        color: 'danger',
        title: 'Danger',
        type: 'confirmation',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      })
    },

    showPrimaryAlert() {
      this.displayDialog({
        color: 'primary',
        title: 'Primary',
        type: 'alert',
        text: 'Integer sapien est, ultrices vel rutrum quis, dapibus sed odio.',
        callback: this.primaryShow
      })
    },

    showWarningAlert() {
      this.displayDialog({
        color: 'warning',
        title: 'Warning',
        type: 'alert',
        text: 'Curabitur posuere tellus in viverra ullamcorper. Vivamus tristique arcu vel maximus mollis?'
      })
    },

    showDangerAlert() {
      this.displayDialog({
        color: 'danger',
        title: 'Danger',
        type: 'alert',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      })
    },

    primaryShow() {
      alert('You pressed primary dialog')
    }
  }
}
</script>

<template>
  <div class="d-flex flex-column">
    <Header>
      <template #actions>
        <v-btn :loading="generatingReport" color="primary" small width="100" @click="attemptToGenerate">
          Relatório
        </v-btn>
      </template>
    </Header>

    <v-row>
      <v-col v-for="category in chartCategories" :key="category.name" cols="12" md="3">
        <v-expansion-panels @click.stop="">
          <v-expansion-panel>
            <v-expansion-panel-header :color="getCategoryColor(category.slug)" :disabled="!category.total"
              class="white--text" disable-icon-rotate>
              {{ category.name }}
              <template v-slot:actions>
                <strong>
                  {{ category.total || 0 }}
                </strong>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="![0, undefined].includes(category.total)">
              <Doughnut :chart-data="category.chartData" :chart-options="category.chartOptions" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="panel" style="margin-bottom: 60px">
          <v-card-title>
            <BeFilter :fields="filters" @search="search" />
          </v-card-title>
          <v-card-text>
            <ScannedDataListHeader class="mb-3" />

            <DisplaySkeleton v-if="table.loading && scannedData.length === 0" :quantity="4" />

            <ScannedDataListCard v-else v-for="sd in scannedData" :key="sd.id" :scannedData="sd" class="mb-3" />

            <EmptyList v-if="!table.loading && scannedData && scannedData.length == 0" hide-actions />

            <v-card class="ma-0 pa-0 align-self-center" style="position: sticky; bottom: 0; background-color: #17a2b8">
              <Paginator v-model="page" :pagination="pagination" />
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Header from '@/components/Header'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Paginator from '@/components/Paginator'
import BeFilter from '@/components/Filter'
import {
  SET_FILTER_VALUES,
  SET_PAGINATION
} from '@/store/modules/scannedData/mutations'
import Doughnut from '@/components/Charts/Doughnut'
import DisplaySkeleton from '@/components/DisplaySkeleton'

import ScannedDataListHeader from './components/ScannedDataListHeader.vue'
import ScannedDataListCard from './components/ScannedDataListCard.vue'
import EmptyList from '@/components/EmptyList'
import { SET_FILTERS } from '@/store/modules/filter/mutations'

export default {
  name: 'ScannedData',
  components: {
    ScannedDataListHeader,
    ScannedDataListCard,
    Paginator,
    BeFilter,
    Doughnut,
    DisplaySkeleton,
    Header,
    EmptyList
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false
        }
      },
      chartData: {
        labels: ['skill1'],
        datasets: [
          {
            backgroundColor: ['red'],
            data: [1]
          }
        ]
      },
      isLoadingCounters: true,
      counters: {},
      scanId: this.$route.params.id,
      page: 1,
      panel: true,
      currentData: null,
      queryParams: {},
      filters: [
        {
          name: 'value',
          label: 'Valor',
          type: 'text'
        },

        {
          name: 'type',
          label: 'Detector',
          type: 'autocomplete',
          itemText: 'name',
          itemValue: 'id',
          loading: false,
          searchMethod: async (search) => {
            return await this.findByOption(search)
          },
          findMethod: async (id) => {
            return await this.findDetectorBySlug(id)
          }
        },
        {
          name: 'scanId',
          label: 'Scan',
          type: 'autocomplete',
          itemText: 'name',
          itemValue: 'id',
          loading: false,
          searchMethod: async (search) => {
            return await this.findScanByOption(search)
          },
          findMethod: async (id) => {
            return await this.findScanById(id)
          }
        },
        {
          name: 'categoryId',
          label: 'Categoria',
          type: 'autocomplete',
          itemText: 'name',
          itemValue: 'id',
          loading: false,
          searchMethod: async (search) => {
            return await this.findCategoryByOption(search)
          },
          findMethod: async (id) => {
            return await this.findCategoryById(id)
          }
        },
        {
          name: 'origin',
          label: 'Origem',
          type: 'text'
        }
      ]
    }
  },

  async created() {
    const { scanHistoryId } = this.$route.params
    await Promise.all([
      this.getDetectedCategories(scanHistoryId),
      this.loadInfo(scanHistoryId)
    ])
  },

  computed: {
    ...mapGetters({
      scannedData: 'scannedDataModule/scannedData',
      table: 'scannedDataModule/table',
      pagination: 'scannedDataModule/pagination',
      chartCategories: 'dashboardModule/categories',
      reportCategories: 'dashboardModule/report',
      pendingReports: 'reportModule/pending'
    }),
    generatingReport() {
      return (

        this.pendingReports?.some(
          (report) => report.scanHistoryId == 'global'
        ) == true
      )
    }
  },

  methods: {
    ...mapActions({
      filter: 'scannedDataModule/filter',
      loadInfo: 'scannedDataModule/loadInfo',
      delete: 'scannedDataModule/delete',
      findByOption: 'detectorModule/findByOption',
      findCategoryByOption: 'sensitiveCategoryModule/findByOption',
      findScanByOption: 'scanModule/findByOption',
      findDetectorBySlug: 'detectorModule/find',
      findScanById: 'scanModule/find',
      findCategoryById: 'sensitiveCategoryModule/find',
      getDetectedCategories: 'dashboardModule/getDetectedCategories',
      generateReport: 'reportModule/generateScanHistoryReport',
    }),
    ...mapMutations('scannedDataModule', [SET_PAGINATION, SET_FILTER_VALUES]),
    ...mapMutations('filterModule', [SET_FILTERS]),

    attemptToGenerate() {
      this.generateReport(null)
    },

    async onIntersect() {
      if (this.table.loading) return

      if (!this.intersectedFlag) {
        this.intersectedFlag = true
        return
      }

      this.loadingIntersect = true

      await this.filter({
        ...this.queryParams,
        scanHistoryId: this.$route.params.scanHistoryId,
        page: this.pagination.page + 1,
        append: true
      })

      this[SET_FILTERS]({
        ...this.queryParams,
        scanHistoryId: this.$route.params.scanHistoryId,
        page: this.pagination.page + 1
      })

      this.loadingIntersect = false
    },

    getCategoryColor(index) {
      const categoryName = index

      if (!categoryName) return '#17a2b8'

      switch (categoryName) {
        case 'sensitive':
          return '#f44336'
        case 'registration':
          return '#03A9F4'
        case 'financial':
          return '#FF5722'
        case 'behavioral':
          return '#4527A0'
        default:
          return '#17a2b8'
      }
    },

    categoryColor(index) {
      const backgroundColor = [
        '#41B883',
        '#E46651',
        '#00D8FF',
        '#DD1B16',
        '#FFEE58',
        '#00897B',
        '#8E24AA'
      ]

      return backgroundColor[index]
    },

    async search(data) {
      this.queryParams = data
      await this.filter({
        ...data,
        scanHistoryId: this.$route.params.scanHistoryId
      })

      this[SET_FILTERS]({
        ...data,
        scanHistoryId: this.$route.params.scanHistoryId,
        page: 1
      })

      this.page = 1
    },

    setFilters(values) {
      this[SET_FILTER_VALUES](values)
      this[SET_PAGINATION]({ page: 1 })
      this.loadScans()
    }
  },

  watch: {
    page(value) {
      this.filter({
        ...this.queryParams,
        scanHistoryId: this.$route.params.scanHistoryId,
        page: value
      })

      this[SET_FILTERS]({
        ...this.queryParams,
        scanHistoryId: this.$route.params.scanHistoryId,
        page: value
      })
    }
  }
}
</script>
<style>
.print-section {
  font-family: sans-serif;
  padding: 64px;
}

.chart-item {
  border-radius: 4px;
}

.header-item {
  background-color: transparent;
  border-radius: 4px;
}

.chart-item-header {
  display: flex;
  justify-content: flex-start;
  padding: 16px;
  border-bottom: 1px solid #000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.chart-item-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.header-item-body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.doughnut-chart {
  height: 150px;
  margin-right: -100px;
}

.display-items {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 200px;
  border-radius: 4px;
  align-items: flex-start;
  flex-direction: column;
}

.flex-display {
  display: flex;
  align-items: center;
}

.square-identifier {
  width: 20px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid #000;
}

hr {
  border: unset;
  border-bottom: 1px solid #b0b0b0;
}

mb-4 {
  margin-bottom: 16px;
}
</style>

<template>
  <div>
    <label class="text-body-2">{{ label }}</label>
    <v-autocomplete
        v-model="model"
        :items="arrayItems"
        :disabled="disabled"
        dense
        solo
        chips
        color="blue-grey lighten-2"
        :item-text="itemText"
        :item-value="itemValue"
        :multiple="multiple"
        :loading="loading || searching"
        :search-input.sync="search"
    >
      <template v-slot:selection="data">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            color="primary"
            small
            @click="data.select"
            @click:close="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle
                v-html="data.item.group"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: 'BeTag',
  data() {
    return {
      searching: false,
      searchItems: this.items,
      search: '',
      item: null
    }
  },

  props: {
    value: {
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      default: 'text'
    },
    itemValue: {
      default: 'value'
    },
    searchMethod: {
      default: null
    },
    findMethod: {
      default: null
    }
  },

  created() {
    if (this.model && this.findMethod) {
      this.findCurrent(this.model)
    }
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    arrayItems() {
      if (this.searchMethod != undefined || this.findMethod != undefined) {
        return this.searchItems
      } else {
        return this.items
      }
    }
  },
  methods: {
    async findItems(val) {
      this.searching = true
      let result = []
      if (![null, undefined].includes(val)) {
        result = await this.searchMethod(val)
      } else {
        result = await this.searchMethod('')
      }
      this.searchItems = this.searchItems.concat(result)
      this.searching = false
    },
    async findCurrent() {
      this.searching = true

      if (this.model.length && this.searchItems.length === 0) {
        await Promise.all(
            this.model.map(async (item) => {
              if (item) {
                const result = await this.findMethod(item)
                this.searchItems.push(result)
              }
            })
        )

        this.$emit('input', this.model)
      } else if (!this.model.length) {
        const result = await this.searchMethod('')
        this.searchItems = result
      }

      this.searching = false
    },
    async remove(data) {
      if (this.multiple) {
        const items = this.arrayItems.filter(
            (ai) =>
                ai[this.itemValue] != data[this.itemValue] &&
                this.model.includes(ai[this.itemValue])
        )

        const input = [...new Set(items.map((item) => item[this.itemValue]))]

        this.$emit('input', input)
      } else {
        this.$emit('input', null)
      }
    }
  },
  watch: {
    async search(val) {
      if (!this.loading && !this.searching) {
        if (this.searchMethod) {
          await this.findItems(val)
        } else {
          this.$emit('search', '')
        }
      }
    },

    model(value) {
      if (value && this.findMethod && !this.loading) {
        this.findCurrent(value)
      }
    }
  }
}
</script>

<style></style>
